import React from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
const Container = styled.div`
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
    background: #232b2b;
    color: #fff;
    padding: 1rem 1rem 2rem 2rem;
    .heading {
        margin-top: -1rem;  
        font-family: "Montez";
        font-size: 3rem;
        color: #fccb13;
    
        @media (max-width: 768px) {
          padding: 0;
          font-size: 2.5rem;
        }
      }
      .notice{
        padding: 1rem;
      }
`

const CloseIcon = styled.div`
    text-align: right;
    font-size: 1.5rem;
    cursor: pointer;
`;

const RestaurantClosedMessage = ({ handlefix }) =>{
    
    return(
        <Container>
              <CloseIcon>
                  <AiOutlineClose onClick={() => handlefix()} />
                </CloseIcon>
            <h3 className="heading">"Ravintola Mount Nepal"</h3>
            <h5 className="notice">We are closed today. Sorry for the inconvenience.</h5>
        </Container>
    )
}
export default RestaurantClosedMessage;