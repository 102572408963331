import NoPicture from "../../../assets/FoodImg/Picture1.png";
import SagPaneer from "../../../assets/FoodImg/SagPaneer.png";
import PaneerChilly from "../../../assets/FoodImg/PaneerChilly.png";
import SahiPaneer from "../../../assets/FoodImg/SahiPaneer.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


const VegetableDishes =
[
  {
    image: NoPicture,
    dishname: "1. SAG TOFU (G, L) (VEGAN)",   
    reference:"AlacarteVegetable",
    id:"1",
      price: "13,50€",
  },
  {
    image: NoPicture,
    dishname: ["2. TOFU CHILLI (G, L) (VEGAN) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"2",
      price: "14,00€",
  },
  {
    image: NoPicture,
    dishname: ["3. TOFU MASALA(G, L) (VEGAN)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>,],
    reference:"AlacarteVegetable",
    id:"3",
      price: "14,00€",
  },
  {
    image: NoPicture,
    dishname: "4. SAG ALOO (G,L) (VEGAN) ",
    reference:"AlacarteVegetable",
    id:"4",
    price: "14,00€",
  },
  {
    image: NoPicture,
    dishname: [" 5. ALU CHANA (G,L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"5",
  price: "14,00€",
  },
  {
    image: NoPicture,
    dishname: ["6. ALU GOVI (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"6",
      price: "14,00€",
  },
  {
    image: NoPicture,
    dishname: ["7. MIX VEGETABLES (G,L) (VEGAN) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"7",
      price: "14,80€",
  },
  {
    image: NoPicture,
    dishname: ["8. BHINDI MASALA (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"8",
    price: "14,50€",
  },
 
  {
    image: NoPicture,
    dishname: ["9. BAIGAN MASALA (G,L) (VEGAN)  ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"9",
      price: "14,50€",
  },
  {
    image: NoPicture,
    dishname: [" 10. MUSHROOM MASALA (G,L) (VEGAN)",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"10",
      price: "14,20€",
  },
  {
    image: NoPicture,
    dishname: "11. MALAI KOFTA (G) ",
    reference:"AlacarteVegetable",
    id:"11",
      price: "15,50€",
  },
  {
    image: NoPicture,
    dishname: "12. VEGETABLE KOFTA (G) ",
    reference:"AlacarteVegetable",
    id:"12",
      price: "15,80€",
  },
  

  {
    image: NoPicture,
    dishname: "13. SAG KOFTA (G,L)	  ",
    reference:"AlacarteVegetable",
    id:"13",
      price: "15,50€",
  },
  {
    image: NoPicture,
    dishname: "14. CHEF SPECIAL KOFTA  (G)		  ",
    reference:"AlacarteVegetable",
    id:"14",
      price: "16,80€",
    
  },
  {
    image: NoPicture,
    dishname: ["15. PANEER BUTTER MASALA (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"15",
      price: "17,80€",
  },
  {
    image: SahiPaneer,
    dishname: "16. SHAHI PANEER (G)  ",
    reference:"AlacarteVegetable",
    id:"16",
      price: "16,00€",
  },
  {
    image: NoPicture,
    dishname: ["17. KARAI PANEER (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"17",
      price: "15,50€",
  },
  {
    image:NoPicture,
    dishname: [" 18. PANEER TIKKA MASALA (G) ",,<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"18",
    price: "16,40€",
  },

  {
    image: PaneerChilly,
    dishname: ["19. PANEER CHILLI (G) ",<img src={Chilly3} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"19",
      price: "16,00€",
  },

  {
    image: NoPicture,
    dishname: "20.HARIYALI PANEER (G)	  ",
    reference:"AlacarteVegetable",
    id:"20",
      price: "15,90€",
  },
  {
    image: SagPaneer,
    dishname: "21. SAG PANEER (G)  ",
    reference:"AlacarteVegetable",
    id:"21",
      price: "16,20€",
  },
  {
    image: NoPicture,
    dishname: "22. MOUNT SPECIAL PANEER (G)   ",
    reference:"AlacarteVegetable",
    id:"22",
      price: "17,00€",
    },

    {
      image: NoPicture,
      dishname: ["23. VEGETABLES KARAI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"23",
        price: "16,80€",
    },


  {
    image: NoPicture,
    dishname: "24. DAL MAKHANI (G) ",
    reference:"AlacarteVegetable",
    id:"24",
      price: "14,50€",
  },
  {
    image: NoPicture,
    dishname: ["25. JHANEKO DAL (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    reference:"AlacarteVegetable",
    id:"25",
      price: "14,00€",
      dummy:[
        "Dishes number 1-10 are suitable for vegans.",
      <p></p>,
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild", <img src={Chilly1} alt="mild" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="spicy" height="30" width="33"></img>,    ],
    
  },
];
export default VegetableDishes;
