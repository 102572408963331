import NoPicture from "../assets/FoodImg/Picture1.png";
import SagPaneer from "../assets/FoodImg/SagPaneer.png";
import PaneerChilly from "../assets/FoodImg/PaneerChilly.png";
import SahiPaneer from "../assets/FoodImg/SahiPaneer.png";
import ButterChicken from "../assets/FoodImg/ButterChicken.png";
import ChickenTikkaMasala from "../assets/FoodImg/ChickenTikkaMasala.png";
import PrawnMasala from "../assets/FoodImg/PrawnMasala.png";
import Chilly1 from "../assets/FoodImg/C1.png"
import Chilly2 from "../assets/FoodImg/C2.png"
import Chilly3 from "../assets/FoodImg/C3.png"


const AlacarteMain =
[
  {
      image: NoPicture,
      dishname: "1. SAG TOFU (G, L) (VEGAN)",   
      reference:"AlacarteVegetable",
      id:"1",
        price: "13,50€",
    },
    {
      image: NoPicture,
      dishname: ["2. TOFU CHILLI (G, L) (VEGAN) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"2",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["3. TOFU MASALA(G, L) (VEGAN)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>,],
      reference:"AlacarteVegetable",
      id:"3",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: "4. SAG ALOO (G,L) (VEGAN) ",
      reference:"AlacarteVegetable",
      id:"4",
      price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: [" 5. ALU CHANA (G,L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"5",
    price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["6. ALU GOVI (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"6",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["7. MIX VEGETABLES (G,L) (VEGAN) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"7",
        price: "14,80€",
    },
    {
      image: NoPicture,
      dishname: ["8. BHINDI MASALA (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"8",
      price: "14,50€",
    },
   
    {
      image: NoPicture,
      dishname: ["9. BAIGAN MASALA (G,L) (VEGAN)  ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"9",
        price: "14,50€",
    },
    {
      image: NoPicture,
      dishname: [" 10. MUSHROOM MASALA (G,L) (VEGAN)",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"10",
        price: "14,20€",
    },
    {
      image: NoPicture,
      dishname: "11. MALAI KOFTA (G) ",
      reference:"AlacarteVegetable",
      id:"11",
        price: "15,50€",
    },
    {
      image: NoPicture,
      dishname: "12. VEGETABLE KOFTA (G) ",
      reference:"AlacarteVegetable",
      id:"12",
        price: "15,80€",
    },
    
  
    {
      image: NoPicture,
      dishname: "13. SAG KOFTA (G,L)	  ",
      reference:"AlacarteVegetable",
      id:"13",
        price: "15,50€",
    },
    {
      image: NoPicture,
      dishname: "14. CHEF SPECIAL KOFTA  (G)		  ",
      reference:"AlacarteVegetable",
      id:"14",
        price: "16,80€",
      
    },
    {
      image: NoPicture,
      dishname: ["15. PANEER BUTTER MASALA (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"15",
        price: "17,80€",
    },
    {
      image: SahiPaneer,
      dishname: "16. SHAHI PANEER (G)  ",
      reference:"AlacarteVegetable",
      id:"16",
        price: "16,00€",
    },
    {
      image: NoPicture,
      dishname: ["17. KARAI PANEER (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"17",
        price: "15,50€",
    },
    {
      image:NoPicture,
      dishname: [" 18. PANEER TIKKA MASALA (G) ",,<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"18",
      price: "16,40€",
    },
  
    {
      image: PaneerChilly,
      dishname: ["19. PANEER CHILLI (G) ",<img src={Chilly3} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"19",
        price: "16,00€",
    },
  
    {
      image: NoPicture,
      dishname: "20.HARIYALI PANEER (G)	  ",
      reference:"AlacarteVegetable",
      id:"20",
        price: "15,90€",
    },
    {
      image: SagPaneer,
      dishname: "21. SAG PANEER (G)  ",
      reference:"AlacarteVegetable",
      id:"21",
        price: "16,20€",
    },
    {
      image: NoPicture,
      dishname: "22. MOUNT SPECIAL PANEER (G)   ",
      reference:"AlacarteVegetable",
      id:"22",
        price: "17,00€",
      },
  
      {
        image: NoPicture,
        dishname: ["23. VEGETABLES KARAI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
        reference:"AlacarteVegetable",
        id:"23",
          price: "16,80€",
      },
  
  
    {
      image: NoPicture,
      dishname: "24. DAL MAKHANI (G) ",
      reference:"AlacarteVegetable",
      id:"24",
        price: "14,50€",
    },
    {
      image: NoPicture,
      dishname: ["25. JHANEKO DAL (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteVegetable",
      id:"25",
        price: "14,00€"
      },
      {
          image: NoPicture,
          dishname: "26. CHICKEN CURRY (G, L) ",  
          reference:"AlacarteChicken",
          id:"1",
            price: "14,80€",
        },
        {
          image: NoPicture,
          dishname: ["27. GARLIC CHICKEN KARAHI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
            reference:"AlacarteChicken",
          id:"2",
            price: "15,90€",
        },
        {
          image: NoPicture,
          dishname: ["28 . CHICKEN KADAI(G,L)  ",<img src={Chilly2} height="30" width="33"></img>],
            reference:"AlacarteChicken",
          id:"3",
            price: "16,90€",
        },
        {
          image: NoPicture,
          dishname: ["29. CHICKEN TAMA (G, L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
            reference:"AlacarteChicken",
          id:"4",
            price: "16,00€",
        },
        {
          image: NoPicture,
          dishname: ["30. CHICKEN MASALA(G,L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
            reference:"AlacarteChicken",
          id:"5",
            price: "15,90€",
        },
        {
          image: NoPicture,
          dishname: ["31.CHICKEN ROGAN JOSH(G) ", <img src={Chilly2} height="30" width="33"></img>],
            reference:"AlacarteChicken",
          id:"6",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: ["32. CHICKEN VINDALOO (G, L) ", <img src={Chilly3} height="30" width="33"></img>],
            reference:"AlacarteChicken",
          id:"7",
            price: "15,90€",
        },
        {
          image: NoPicture,
          dishname: "33.CHICKEN KORMA (G) ",
          reference:"AlacarteChicken",
          id:"8",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: "34. CHICKEN PANEER (G)  ",
            reference:"AlacarteChicken",
          id:"9",
            price: "16,30€",
        },
        {
          image: NoPicture,
          dishname: "35. MANGO CHICKEN (L, G) ",
            reference:"AlacarteChicken",
          id:"10",
            price: "16,50€",
         },
         {
          image: NoPicture,
          dishname: "36. CHEF SPECIAL CHICKEN(G)",
            reference:"AlacarteChicken",
          id:"11",
            price: "17,50€",
        },
    
        {
          image: NoPicture,
          dishname: "37. Spinach chicken (G.L) ",
            reference:"AlacarteChicken",
          id:"12",
            price: "15,90€"
        },
        {
          image: NoPicture,
          dishname: "38. CHICKEN SAAG KARAHI (G)",
          reference:"AlacarteTandooriChicken",
          id:"1",
          price: "17,50€",
        },
        {
          image: NoPicture,
          dishname: ["39. ​​CHICKEN CHILLI (G, L)", <img src={Chilly2} height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"2",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: [" 40. KUKHURA CHHOILA (G, L)" , <img src={Chilly2} height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"3",
            price: "17,00€",
        },
        {
          image: NoPicture,
          dishname: "41. CHICKEN TIKKA (G)  ",
          reference:"AlacarteTandooriChicken",
          id:"4",
            price: "16,80€",
        },
        {
          image: NoPicture,
          dishname: ["42. CHICKEN SASLIK (G) ",<img src={Chilly1} alt = "medium" height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"5",
            price: "17,80€",
        },
        {
          image: ChickenTikkaMasala,
          dishname: ["43. CHICKEN TIKKA MASALA (G) ", <img src={Chilly2} alt = "medium" height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"6",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: ["44. CHICKEN BUTTER MASALA (G) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"7",
            price: "19,00€",
        },
        {
          image: NoPicture,
          dishname: [" 45. BHALE KARAI SPECIAL (G)", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteTandooriChicken",
          id:"8",
            price: "18,50€",
        },
        {
          image: ButterChicken,
          dishname: " 46. BUTTER CHICKEN (G)",
          reference:"AlacarteTandooriChicken",
          id:"9",
            price: "16,80€",
        },
        {
          image: NoPicture,
          dishname: "47. MOUNT SPECIAL CHICKEN (G) ",
          reference:"AlacarteTandooriChicken",
          id:"10",
            price: "19,50€",
          },
          {
            image: NoPicture,
            dishname: "48. BUTTER GARLIC TIKKA (G) ",
            reference:"AlacarteTandooriChicken",
            id:"11",
              price: "19,00€",
          },
        {
          image: NoPicture,
          dishname: "49. BHALE MALAI TIKKA (G)",
          reference:"AlacarteTandooriChicken",
          id:"12",
            price: "18,90€",
        },
        {
          image: NoPicture,
          dishname: ["50.CHICKEN SHIRAZ (G)"],
          reference:"AlacarteTandooriChicken",
          id:"13",
            price: "18,90€",
        },
        {
          image: NoPicture,
          dishname: "51. LAMB CURRY (G, L)", 
          reference:"AlacarteLamb",
          id:"1",
            price: "15,80€",
        },
        {
          image: NoPicture,
          dishname: ["52. LAMB MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteLamb",
          id:"2",
            price: "17,90€",
        },
        {
          image: NoPicture,
          dishname: ["53. LAMB Rogan josh (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteLamb",
          id:"3",
            price: "18,90€",
        },
        {
          image: NoPicture,
          dishname: ["54. LAMB VINDALOO (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
          reference:"AlacarteLamb",
          id:"4",
            price: "16,90€",
          },
          {
            image: NoPicture,
            dishname: [" 55. PIRO BHEDO (G, L)" ,<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
            reference:"AlacarteLamb",
            id:"5",
              price: "16,50€",
          },
          {
            image: NoPicture,
            dishname: ["56. GINGER LAMB (G, L) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
            reference:"AlacarteLamb",
            id:"6",
              price: "16,50€",
          },
          {
            image: NoPicture,
            dishname: ["57. GARLIC KHASI KARAHI (G)  ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
            reference:"AlacarteLamb",
            id:"7",
              price: "16,50€",
          },
          {
            image: NoPicture,
            dishname: ["58. LAMB KOFTA MASALA(G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
            reference:"AlacarteLamb",
            id:"8",
              price: "15,80€",
          },
          {
            image: NoPicture,
            dishname: "59. Lamb Kofta Cream (G)",
            reference:"AlacarteLamb",
            id:"9",
              price: "16,50€",
          },
          {
            image: NoPicture,
            dishname: "60. BUTTER LAMB (G) ",
            reference:"AlacarteLamb",
            id:"10",
              price: "17,50€",
          },
          {
            image: NoPicture,
            dishname: "61. LAMB KORMA (G) ",
            reference:"AlacarteLamb",
            id:"11",
              price: "16,80€",
          },
        {
          image: NoPicture,
          dishname: "62. LAMB SAAG (G, L) ",
          reference:"AlacarteLamb",
          id:"12",
          price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: [" 63. SPECIAL MIX KARAHI (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteLamb",
          id:"13",
            price: "18,50€",
        },
      
        { 
          image: NoPicture,
          dishname: [" 64. LAMB BUTTER MASALA (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteLamb",
          id:"14",
            price: "18,50€",
        },
        {
          image: NoPicture,
          dishname: " 65.Chef special lamb(G)",
          reference:"AlacarteLamb",
          id:"15",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: ["66. LAMB SEKUWA (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteTandooriLamb",
          id:"1",
            price: "20,00€",
        },
        {
          image: NoPicture,
          dishname: ["67. LAMB TIKKA MASALA (G)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteTandooriLamb",
          id:"2",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: "68. KHASI MASALA KARAHI (G) ",
          reference:"AlacarteTandooriLamb",
          id:"3",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: ["69. SHEIKH KABAB MASALA ",<img src={Chilly2} height="30" width="33"></img>],
          reference:"AlacarteTandooriLamb",
          id:"4",
            price: "19,00€",
        },
        {
          image: NoPicture,
          dishname: "70. KHASI KOSELI (G) ",
          reference:"AlacarteTandooriLamb",
          id:"5",
          price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: ["71. CHEF'S TANDOOR MIX SIZZLER (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteTandooriLamb",
          id:"6",
            price: "24,50€",
        },
        {
          image: NoPicture,
          dishname: [" 72. PRAWN TANDOOR (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"1",
            price: "22,50€",
        },
      
        {
          image: NoPicture,
          dishname: ["73. PRAWN SIZZLER (G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"2",
            price: "22,00€",
        },
        {
          image: NoPicture,
          dishname: ["74. PRAWN CHILLI (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"3",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: "75. GARLIC PRAWN KARAHI (G, L)  ",
          reference:"AlacarteSeaFood",
          id:"4",
            price: "19,50€",
        },
        {
          image: PrawnMasala,
          dishname: ["76. PRAWN MASALA (G, L)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"5",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: "77. SHAHI NARIWAL PRAWN (G) ",
          reference:"AlacarteSeaFood",
          id:"6",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: "78. KING PRAWN SAG (G) ",
          reference:"AlacarteSeaFood",
          id:"7",
            price: "19,50€",
        },
        {
          image: NoPicture,
          dishname: "79. SPECIAL BUTTER GARLIC PRAWN (G) ",
          reference:"AlacarteSeaFood",
          id:"8",
            price: "21,00€",
        },
        {
          image: NoPicture,
          dishname: "80. PRAWN PANEER (G) ",
          reference:"AlacarteSeaFood",
          id:"9",
            price: "16,00€",
        },
        {
          image: NoPicture,
          dishname: "81. MACHHA SAG (G)",
          reference:"AlacarteSeaFood",
          id:"10",
            price: "17,00€",
        },
        {
          image: NoPicture,
          dishname: ["82. FISH CHILLI (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"11",
            price: "16,50€",
        },
      
        {
          image: NoPicture,
          dishname: "83. FISH KORMA (G)",
          reference:"AlacarteSeaFood",
          id:"12",
          price: "17,50€",
        },
        {
          image: NoPicture,
          dishname: ["84. FISH MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"AlacarteSeaFood",
          id:"13",
            price: "16,50€"
        }
];
export default AlacarteMain;
