import Offer1 from "../../../assets/FoodImg/ButterChicken.png";
import Offer2 from "../../../assets/FoodImg/ChickenTikkaMasala.png";
import Offer3 from "../../../assets/FoodImg/SahiPaneer.png";
import Offer4 from "../../../assets/FoodImg/Momo.png";
import offer5 from "../../../assets/FoodImg/PaneerChilly.png"
import offer6 from "../../../assets/FoodImg/SagPaneer.png"

export const CustomersFavouriteMenu = [
  {
    image: offer5,
    dishname: "TOFU MASALA(G, L) (VEGAN)  ",
    dishitems:
      ["Tofu and paprika tomato - onion - chilli and soy sauce. Tofu and Capsicum in tomato-onion and soy sauce.",],
    reference:"AlacarteVegetable",
    id:"3",
    price: "14,00€",
  },
  {
    image: offer6,
    dishname: "SAG PANEER (G)",
    dishitems:
      ["Spinach and cream cheese in cream-curry sauce.  Fresh spinach and cottage cheese with creamy curry sauce.",
  ],

    reference:"AlacarteVegetable",
    id:"21",
      price: "16,20€",
  },
  {
    image: Offer1,
    dishname: "BUTTER CHICKEN (G)",
    dishitems: [
      "Herb-yoghurt marinated and oven-grilled chicken breast fillets in coconut-tomato-butter-cream sauce Herb and yogurt marinated Tandoori roasted chicken fillet pieces in Coconut tomato- butter cream sauce.",
    ],
    reference:"AlacarteTandooriChicken",
    id:"9",
    price: "16,80€",
  },
  {
    image: Offer3,
    dishname: "SHAHI PANEER(G)",
    dishitems: [
      "Bread cheese in tomato-butter-cream sauce. Cottage cheese cooked in tomato-butter-cream sauce.",
    ],
    reference:"CustomersFav",
    id:"1",
    price: "16,00€",
  
  },
  {
    
    image: Offer2,
    dishname: "CHICKEN TIKKA MASALA (G)",
    dishitems: [
      "Herb-yogurt marinated and tandoori grilled chicken fillets in  tomato-onion-garlic-ginger-chili-masala sauce.        Tandoori-grilled herb-yoghurt marinated chicken fillet pieces in tomato-onion-garlic-ginger-chilli-masala sauce.",
    ],
    reference:"AlacarteTandooriChicken",
    id:"6",
    price: "15,80€",
  },
  {
    image: Offer4,
    dishname: ["LAMB MOMO (L)"],
    dishitems:
      ["Wheat flour-coated steamed minced lamb meatballs seasoned with cumin, coriander, Ginger, garlic, white pepper and soya sauce. Served with tomato sesame chutney.",<br></br>,"[preparation takes about 25 minutes]",],
    price: "15,00€",
    reference:"AlacarteMomo",
    id:"3"
  },
  
];
