import React, { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const CustomerFavouriteMenuContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  width: 90%;

  .dish-price {
    border-top: 1px solid #b48c80;
    border-bottom: 1px solid #b48c80;
    padding: 1rem;
    font-family: "Montez", sans-serif;
    font-size: 2rem;
    color: #423632;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 1440px) {
    width: 95%;
  }
`;

const DishImage = styled.img`
  width: 112px;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(55, 55, 55, 0.7);
`;

const DishContent = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1rem;

  .dish-name {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding-bottom: 0.3rem;
    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  .dish-items {
    margin: 0;
    font-weight: bolder;
    font-size: 14px;
    color: #000000;

    @media (max-width: 480px) {
      display: none;
    }
  }
`;

const CustomerFavouriteMenu = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 200 });
  }, []);
  return (
    <CustomerFavouriteMenuContainer key={props.id} data-aos="fade-left">
      {props.image && <DishImage src={props.image}></DishImage>}

      <DishContent>
        <p className="dish-name">{props.dishname}</p>
        <p className="dish-items">{t(`${props.reference}.${props.id}`)}</p>
        <p className="dish-items">{props.dishitems[1]}</p>
      </DishContent>
      <p className="dish-price">{props.price}</p>
    </CustomerFavouriteMenuContainer>
  );
};

export default CustomerFavouriteMenu;
