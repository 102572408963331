import React from "react";
import styled from "styled-components";

const MenuGalleryContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  @media (max-width: 480px) {
    padding-bottom: 1rem;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    background: rgba(0.1, 0, 0, 0);
  }
`;
const MenuGallery = (props) => {
  return (
    <MenuGalleryContainer key={props.id}>
      <GalleryImage src={props.img} alt={props.alt}></GalleryImage>
    </MenuGalleryContainer>
  );
};

export default MenuGallery;
