import React, { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import LeftImg from "../../assets/sliderImage/spices-left.png";
import RightImg from "../../assets/sliderImage/spices-right.png";
import BckImg1 from "../../assets/images/bkg-img1.jpg";
import BckImg2 from "../../assets/images/bkg-img2.jpg";
import Divider from "../ReusableComponents/Divider";
import { useTranslation } from 'react-i18next';

const StoryContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: url(${BckImg1}) center/cover no-repeat border-box;

  @media (max-width:1024px){
    height:auto;
    padding:0rem;
  }
  @media (max-width:768px){
    height:auto;
    padding:2rem 0.5rem;
  }
`;
const LeftImage = styled.img`
  max-width: 100%;
  height: 70vh;
  position: absolute;
  left: 5%;
  width: 18%;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 1024px) {
    height:40vh;
    width:15%;
  }
  @media (max-width: 768px) {
   display:none;
  }
`;
const StoryBoard = styled.div`
  box-sizing: border-box;
  padding: 4rem;
  width: 45%;
  height: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(${BckImg2}) center/cover no-repeat border-box;
  border: 15px solid #131822;
  box-shadow: 0 0 18px 7px rgba(0, 0, 0, 0.3);

  .story {
    font-family: "Montez", sans-serif;
    color: #b48c80;
    line-height: 0.5;
    font-size:3rem;
    margin-top:15px;
  }

  .story-title {
    font-family: "Suranna", sans-serif;
    font-size: 2.3rem;
    text-transform: uppercase;
  }

  .story-description {
    color: #000;
    text-align: center;
    line-height: 1.7;
    padding-top: 1rem;
    font-weight:600;
  }

  @media (max-width:768px){
      width:100%;
      height:auto;
      padding:1rem;
      vertical-align:baseline;

      .story-title{
          font-size:2rem;
      }
  }
`;

const RightImage = styled.img`
  max-width: 100%;
  height: 70vh;
  position: absolute;
  right: 5%;
  width: 18%;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 1024px) {
    height:40vh;
    width:15%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const OurStory = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1200, delay: 150 });
  }, []);
  return (
    <StoryContainer>
      <LeftImage src={LeftImg} data-aos="fade-right"></LeftImage>
      <StoryBoard data-aos="fade-up">
        <h1 className="story">{t('Home.our story')}</h1>
        <p className="story-title">{t('Home.love for food')}</p>
        <Divider />
        <p className="story-description">
          {t('SecondResturant.OurStoryDescription')}
        </p>
      </StoryBoard>
      <RightImage src={RightImg} data-aos="fade-left"></RightImage>
    </StoryContainer>
  );
};

export default OurStory;
