import Food4 from "../assets/FoodImg/HomeThali.jpg";
import Food2 from "../assets/FoodImg/UnnamedDish.jpg";
import Food1 from "../assets/FoodImg/GulabJamun.PNG";
import Food3 from "../assets/FoodImg/IndianFood.jpg";

const homeMenuGallery = [
  {
    id: 1,
    img: Food1,
    alt: "Gulab Jamun",
  },
  {
    id: 2,
    img: Food2,
    alt: "Dish",
  },
  {
    id: 3,
    img: Food3,
    alt: "Indian Food",
  },
  {
    id: 4,
    img: Food4,
    alt: "Home Thali",
  },
];
export default homeMenuGallery;
