import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"



const LambDishes
= [
    {
      image: NoPicture,
      dishname: "51. LAMB CURRY (G, L)", 
      reference:"AlacarteLamb",
      id:"1",
        price: "15,80€",
    },
    {
      image: NoPicture,
      dishname: ["52. LAMB MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"AlacarteLamb",
      id:"2",
        price: "17,90€",
    },
    {
      image: NoPicture,
      dishname: ["53. LAMB Rogan josh (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"AlacarteLamb",
      id:"3",
        price: "18,90€",
    },
    {
      image: NoPicture,
      dishname: ["54. LAMB VINDALOO (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
      reference:"AlacarteLamb",
      id:"4",
        price: "16,90€",
      },
      {
        image: NoPicture,
        dishname: [" 55. PIRO BHEDO (G, L)" ,<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
        reference:"AlacarteLamb",
        id:"5",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["56. GINGER LAMB (G, L) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
        reference:"AlacarteLamb",
        id:"6",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["57. GARLIC KHASI KARAHI (G)  ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
        reference:"AlacarteLamb",
        id:"7",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["58. LAMB KOFTA MASALA(G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
        reference:"AlacarteLamb",
        id:"8",
          price: "15,80€",
      },
      {
        image: NoPicture,
        dishname: "59. Lamb Kofta Cream (G)",
        reference:"AlacarteLamb",
        id:"9",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: "60. BUTTER LAMB (G) ",
        reference:"AlacarteLamb",
        id:"10",
          price: "17,50€",
      },
      {
        image: NoPicture,
        dishname: "61. LAMB KORMA (G) ",
        reference:"AlacarteLamb",
        id:"11",
          price: "16,80€",
      },
    {
      image: NoPicture,
      dishname: "62. LAMB SAAG (G, L) ",
      reference:"AlacarteLamb",
      id:"12",
      price: "16,50€",
    },
    {
      image: NoPicture,
      dishname: [" 63. SPECIAL MIX KARAHI (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"AlacarteLamb",
      id:"13",
        price: "18,50€",
    },
  
    { 
      image: NoPicture,
      dishname: [" 64. LAMB BUTTER MASALA (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"AlacarteLamb",
      id:"14",
        price: "18,50€",
    },
    {
      image: NoPicture,
      dishname: " 65.Chef special lamb(G)",
      reference:"AlacarteLamb",
      id:"15",
        price: "19,50€",
      dummy:[
        
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
    
    },
  ];

export default LambDishes;
