 import React from "react";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import styled from "styled-components";
import AlacarteBkg from "../assets/images/alabkg.jpg";
import MenuHeader from "../components/MenuHeader";
import AlacarteMenu from "../components/AlacarteMenu";
import FormPageFlat from "../components/ReservationPage/FlatReservePage";
import menuBackground from "../assets/images/menu-bkg.jpg"; 
import henIcon from "../assets/images/hen.png";
import goatIcon from "../assets/images/goat.png";
import fishIcon from "../assets/images/fish.png";
import Chilly1 from "../assets/FoodImg/C1.png";
import Chilly2 from "../assets/FoodImg/C2.png";
import Chilly3 from "../assets/FoodImg/C3.png";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const AlacarteContainer = styled.div`
  overflow: hidden;`;
const LargeScreenBackgroundContainer = styled.div``;
const AlacarteMenuBackground = styled.div`
  background-image: linear-gradient(
      to right bottom,
      rgba(188, 199, 186, 0.801),
      rgba(40, 180, 133, 0.801)
    ),
    url(${menuBackground});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  .origin-meat {
    margin-right: 126px;
    @media (max-width: 1024px) {
      margin-right: 42px;
    }
    @media (max-width: 480px) {
      margin-right: 0;
      padding: 0.5rem;
    }
    @media (max-width: 360px) {
      font-size: 23px;
    }
  }
  .origin-meat-container {
    display: flex;
    flex: column;
    max-width: 40%;
    margin: auto;
    overflow: hidden;
    @media (max-width: 768px) {
      max-width: 56%;
    }
    @media (max-width: 540px) {
      max-width: 80%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
      margin: 0;
      padding: 0.5rem;
    }
    @media (max-width: 375px) {
      margin: 27px;
    }
  }
  .goatContainer {
    margin-top: -22px;
  }
  .henContainer {
    margin-right: 26px;
  }
  .hen-caption {
    margin-top: 6px;
  }
  .goatImg {
    height: 157px;
    @media (max-width: 375px) {
      height: 103px;
    }
    @media (max-width: 280px) {
      height: 80px;
    }
  }
  .fishImg {
    width: 133px;
    @media (max-width: 375px) {
      width: 59px;
    }
    @media (max-width: 280px) {
      width: 58px;
    }
  }
  .henImg {
    width: 109px;
    @media (max-width: 375px) {
      width: 65px;
    }
    @media (max-width: 280px) {
      width: 46px;
    }
  }
`;
const Alacarte = () => {
  const { t } = useTranslation();
  return (
    <AlacarteContainer>
      <ExquisiteCuisine
        title="A la carte"
        head={t('Menu.sdwu')}
        image={AlacarteBkg}
      />
      <LargeScreenBackgroundContainer>
        <AlacarteMenuBackground>
          <Container maxWidth="xl">
            <MenuHeader
              title="For start"
              name={t('Menu.starters')}
              desc={t('Menu.chefDesc')}
            />

            <AlacarteMenu />
            <div className="origin-meat text-center">
              Mild / mild spicy",{" "}
              <img src={Chilly1} height="30" alt="mild spicy" width="33"></img>, " Medium /
              medium spicy",
              <img src={Chilly2} height="30" alt="medium spicy" width="33"></img>, " Real Strong /
              very spicy",<img src={Chilly3} alt="strong spicy" height="30" width="33"></img>,
            </div>

            <h4 className="origin-meat text-center">
              The Country Of Meat Origin
            </h4>
            <div className="origin-meat-container text-center">
              <div className="henContainer">
                <img src={henIcon} className="henImg" />
                <p className="hen-caption">Finland</p>
              </div>
              <div className="goatContainer">
                <img src={goatIcon} className="goatImg" />
                <p className="goat-caption">Thailand</p>
              </div>
              <div className="fishContainer">
                <img src={fishIcon} className="fishImg" />
                <p className="fish-caption">Brasil</p>
              </div>
            </div>
          </Container>
        </AlacarteMenuBackground>
      </LargeScreenBackgroundContainer>
      <FormPageFlat />
    </AlacarteContainer>
  );
};

export default Alacarte;

