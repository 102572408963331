import React from "react";
import ContactBkg from "../assets/images/home1.jpg";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import ContactUs from "../components/ContactUsForm/ContactUs";
import VisitUs from "../components/VisitUsPage/VisitUs";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ExquisiteCuisine
        title={t('Contact.contactUs')}
        head={t('Contact.sum')}
        image={ContactBkg}
      />
      <ContactUs />
      <VisitUs />
    </div>
  );
};

export default Contact;
