import NoPicture from "../../../assets/FoodImg/Picture1.png";
import SahiPaneer from "../../../assets/FoodImg/SahiPaneer.png";
import SagPaneer from "../../../assets/FoodImg/SagPaneer.png";
import ButterChicken from "../../../assets/FoodImg/ButterChicken.png";



const ChildrenMenu= [
  {
    image:NoPicture,
    dishname: "1. CHICKEN KORMA (G)",
    reference:"AlacarteChildren",
    id:"1",
      price: "9,00€",
  },
  {
    image:NoPicture,
    dishname: "2. CHICKEN TIKKA (G)",
    reference:"AlacarteChildren",
    id:"2",
    price: "9,00€",
  },
  {
    image: SahiPaneer,
    dishname: "3. SHAHI PANEER (G)",
    reference:"AlacarteChildren",
    id:"3",
      price: "9,00€",
  },
  {
    image: SagPaneer,
    dishname: "4. SAG PANEER (G)  ",
    reference:"AlacarteChildren",
    id:"4",
      price: "9,00€",
  },
  {
    image: ButterChicken,
    dishname: "5. BUTTER CHICKEN (G) ",
    reference:"AlacarteChildren",
    id:"5",
      price: "9,00€",
  },
  {
    image:NoPicture,
    dishname: " 6. SHAHI PRAWN (G) ",
    reference:"AlacarteChildren",
    id:"6",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "7. LAMB CURRY (G, L) ",
    reference:"AlacarteChildren",
    id:"7",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "8. CHICKEN FINGERS AND FRENCH FRIES (G, L)",
    reference:"AlacarteChildren",
    id:"8",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "9. CHICKEN FINGERS (L) ",
    reference:"AlacarteChildren",
    id:"9",
      price: "6,00€",
  },
  {
    image:NoPicture,
    dishname: " 10. FRENCH FRIES",
    
    reference:"AlacarteChildren",
    id:"10",
    price: "5,00€",
  },
  {
    image:NoPicture,
    dishname: " 11. FRIED RICE (G, L)",
    reference:"AlacarteChildren",
    id:"11",
      price: "9,00€",
      dummy:[
        "Served only to under 12 years old.",<p></p>,
        "The children’s menu includes rice or French fries.",<br></br>,
        "Childrens menu includes Basmati rice or french fries.",<p></p>,
      ],
  },
];
export default ChildrenMenu;
