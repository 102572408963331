import React from "react";
import styled from "styled-components";

const DividerContainer = styled.div`
  span {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    color: #b48c80;
    margin-bottom:1rem;

    &::before,
    &::after {
      content: "";
      display: block;
      height: 0.09em;
      min-width: 3vw;
    }

    &::before {
      background: #b48c80;
      margin-right: 0.5rem;
    }

    &::after {
      background: #b48c80;
      margin-left: 0.5rem;
    }
  }
`;

const Divider = () => {
  return (
    <DividerContainer>
      <span>
        <i className="fas fa-circle"></i>
      </span>
    </DividerContainer>
  );
};

export default Divider;
