import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


const SideDishes
 = [
  {
    dishname: "KASVIS RUOKIA - VEGETABLE DISHES",
    reference:"",
    id:"",
    dishitems:
      "",

   
  },
  {
    dishname: ["1. ALU GOBI PANEER (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"1",
    dishitems:
    "",
    price: "10,00€",
  },
  {
    dishname: "2. SAG TOFU (G, L, V)",
    reference:"AlacarteSideDishes",
    id:"2",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: ["3. ALU BHINDI MASALA (G, L, V)",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"3",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: "4. SAAG PANEER (G)",
    reference:"AlacarteSideDishes",
    id:"4",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: "5. SHAHI PANEER (G) ",
    reference:"AlacarteSideDishes",
    id:"5",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: "6. MALAI KOFTA (G)  ",
    reference:"AlacarteSideDishes",
    id:"6",
    dishitems:
      "",
    price: "12,00€",
  },
  {
    dishname: [" 7. PANEER TIKKA MASALA (G) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"7",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: [" 8. MIX VEGETABLE (G, L, V)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"8",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: ["9. DAL FRY (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"9",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: " KANA RUOKIA -CHICKEN DISHES",
    reference:"",
    id:"",

  },
  {
    dishname: "10. CHICKEN CURRY (G, L)  ",
    reference:"AlacarteSideDishes",
    id:"10",
    price: "10,00€",
  },
  {
    dishname: ["11. GARLIC CHICKEN (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"11",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: " 12. METHI CHICKEN (G, L)",
    reference:"AlacarteSideDishes",
    id:"12",
    price: "10,00€",
  },
  {
    dishname: " 13. CHICKEN KORMA (G)",
    reference:"AlacarteSideDishes",
    id:"13",
    price: "10,00€",
  },
  {
    dishname: ["14. CHILLI CHICKEN (G) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"14",
    dishitems:
      "",
    price: "10,00€",
  },
  {
    dishname: " TANDOORI CHICKEN DISHES",
    reference:"",
    id:"",

  },
  {
    dishname: " 15. CHICKEN SAG KARAHI (G, L)",
    reference:"AlacarteSideDishes",
    id:"15",
    price: "12,00€",
  },
  {
    dishname: " 16. CHICKEN TIKKA MASALA (G)",
    reference:"AlacarteSideDishes",
    id:"16",
    price: "11,00€",
  },
  {
    dishname: "17. CHICKEN TIKKA (G) ",
    reference:"AlacarteSideDishes",
    id:"17",
    price: "12,00€",
  },
  {
    dishname: " 18. BUTTER CHICKEN (G)",
    reference:"AlacarteSideDishes",
    id:"18",
    price: "12,00€",
  },
  {
    dishname: "LAMMAS RUOKIA - LAMB DISHES ",
    reference:"",
    id:"",
  },
  {
    dishname: "19. LAMB CURRY (G, L)  ",
    reference:"AlacarteSideDishes",
    id:"19",
    price: "10,00€",
  },
  {
    dishname: "20. LAMB KORMA (G) ",
    reference:"AlacarteSideDishes",
    id:"20",
    price: "12,00€",
  },
  {
    dishname: ["21. LAMB KOFTA (G, L)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"21",
    price: "10,00€",
  },
  {
    dishname: "22. LAMB SAG (G, L) ",
    reference:"AlacarteSideDishes",
    id:"22",
    price: "10,00€",
  },
  {
    dishname: ["23.LAMB CHILLI (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"23",
    price: "10,00€",
  },
  {
    dishname: "24. GARLIC LAMB KARAHI (G) ",
    reference:"AlacarteSideDishes",
    id:"24",
    price: "10,00€",
  },
  {
    dishname: " TANDOORI LAMB DISHES",
    reference:"",
    id:"",

  },
  {
    dishname: ["25. LAMB TIKKA MASALA (G) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"25",
    price: "14,00€",
  },
  {
    dishname: ["26. LAMB SEKUWA (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"26",
    price: "15,00€",
  },
  {
    dishname: "KALA, KATKARAPU - FISH, SHRIMPS ",
    reference:"",
    id:"",
  },
  {
    dishname: " 27. FISH CURRY (G, L)",
    reference:"AlacarteSideDishes",
    id:"27",
    price: "10,00€",
  },
  {
    dishname: " 28. FISH KORMA (G) ",
    reference:"AlacarteSideDishes",
    id:"28",
    price: "10,00€",
  },
  {
    dishname: "29.FISH SAG (G, L)  ",
    reference:"AlacarteSideDishes",
    id:"29",
    price: "10,00€",
  },
  {
    dishname: "30. PRAWN PANEER (G) ",
    reference:"AlacarteSideDishes",
    id:"30",
    price: "10,00€",
  },
  {
    dishname: " 31. SHAHI PRAWN (G, L) ",
    reference:"AlacarteSideDishes",
    id:"31",
    price: "10,00€",
  },
  {
    dishname: ["32. MACHA CHILLI (G, L)",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    reference:"AlacarteSideDishes",
    id:"32",
    price: "10,00€",
  },
  {
    dishname: "RICE- RIISI ",
    reference:"",
    id:"",
  },
  {
      dishname:"33. Basmati Rice",
      reference:"AlacarteSideDishes",
      id:"33",
      price:"3,00€"
  },
  {
    dishname:"34. Garlic Fried Rice",
    reference:"AlacarteSideDishes",
    id:"34",
    price:"4,00€"
},
{
    dishname:"35. Jeera Rice/Kumina Riisia",
    reference:"AlacarteSideDishes",
    id:"35",
    price:"4,00€"
},
  {
    dishname: "NAAN- LEIPA ",
    reference:"",
    id:"",

  },
  {
    dishname: "36. PLAIN NAAN ",
    reference:"AlacarteSideDishes",
    id:"36",
    price: "2,00€",
  },
  {
    dishname: "37. GARLIC NAAN ",
    reference:"AlacarteSideDishes",
    id:"37",
    price: "3,00€",
  },
  {
    dishname: "38. Tandoori Roti (L) Vegan ",
    reference:"AlacarteSideDishes",
    id:"38",
    price: "2,00€",
  },
  {
    dishname: "39. Papadum (G, L) Vegan ",
    reference:"AlacarteSideDishes",
    id:"39",
    price: "1,00€",
  },
    {

        dishname: "40. MINT SAUCE (G, L)  ",
        reference:"AlacarteSideDishes",
        id:"40",
        dishitems: "Fresh mint with tomato, Ginger, garlic and chilli sauce. ",
        price: "3,00€",
    },
    {
    dishname: "41. RAITA ",
    reference:"AlacarteSideDishes",
    id:"41",
    dishitems: "Makea ja suolainen jogurttikastike, jossa raastettua kurkkua japorkkanaa. Maustettu chat-masalalla.",

    price: "3,00€",
    dummy:["This does not include rice, bread, stripe, beans and salad ",<br></br>,
    "Mild / Mild", <img src={Chilly1} alt="mild" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="spicy" height="30" width="33"></img>,],
  },
  ];
export default SideDishes;
