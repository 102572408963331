const drinkItems = [
  {
    accordionid: "flush-headingOne",
    bodyid: "flush-collapseOne",
    hashbodyid: "#flush-collapseOne",
    category: "LASSI",
    items: [
      "Suola - Salt Lassi",
      "Makea – Sweet Lassi",
      "Mango Lassi",
      "Banaani – Banana Lassi ",
      "Mansikka- Strawberry Lassi ",
      "Mix- Lassi",
    ],
    price: ["4,00€", "4,00€", "4,00€", "4,00€", "4,00€", "5,00€"],
    detail: [
      "",
      "",
      "(Laktositon Jogurtti ja mango sekoitus)",
      "",
      "",
      "(Laktositon Jogurtti Banani ja Mansikka sekoitus)",
    ],
  },
  {
    accordionid: "flush-headingTwo",
    bodyid: "flush-collapseTwo",
    hashbodyid: "#flush-collapseTwo",
    category: "JUOMAT - BEVERAGES  0,33cl / 0,50cl",
    items: [
      "Coke, Zero-coke, Jaffa, 7-up",
      "Mehu- Juice",
      "Kahvi, Musta Tee, Vihreä Tee",
      "Nepalilainen Kahvi,Tee ",
      "Maito- Milk",
      "Vichy 0,50 L"
    ],
    price: ["2,90€/3,90€", "2,90€/3,90€",  "2,50€", "3,50€", "2,00€","3,50€"],
    detail: [
      "",
      "(Appelsiini-Orange, Omena-Apple)",
      "(Coffee, Black tea, Green tea)",
      "(Nepali Coffee, Tea)",
      "",
    ],
  },
  {
    accordionid: "flush-headingThree",
    bodyid: "flush-collapseThree",
    hashbodyid: "#flush-collapseThree",
    category: "SIIDERI - CIDER",
    items: [
      "Omean Siideri 33cl (4,7%) ",
      "Lonkero- Long Drink 33cl (5,5%)",
      "Bulmers Original 50cl (4,5%)",
      "Strongbow 25,7cl (5,3%)",
    ],
    price: ["5,90€", "5,50€", "7,00€", "5,50€"],
    detail: ["", "", "", ""],
  },
  {
    accordionid: "flush-headingFour",
    bodyid: "flush-collapseFour",
    hashbodyid: "#flush-collapseFour",
    category: "OLUT - BEERS",
    items: [
      "Alkoholiton olut (Buckler/Heineken) 33cl ",
      "III Olut Aura 33cl (4,5%)",
      "III Olut Aura 50cl (4,5%)",
      "III Karhu (4,6%) 50cl",
      "IV Karhu (5,2%) 50cl",
      "Karhu/Lapinkulta IV 50cl",
    ],
    price: ["4,00€", "5,00€", "6,00€","6,00€", "6,50€", "6,50€"],
    detail: ["", "", "", "", "", ""],
  },
  {
    accordionid: "flush-headingFive",
    bodyid: "flush-collapseFive",
    hashbodyid: "#flush-collapseFive",
    category: "ULKO OLUT - FOREIGN BEER Nepalilainen",
    items: [
      "Mt Everest Golden (5,0%) 33cl",
      "Khukuri Lager (4,7%) 33cl  ",
      "Kathmandu 50cl /bottle (4,8%) ",
      "Nepal Ice Lager (5,5%) 33 cl ",
"King Fisher (Indian) (4,8%) 33cl",
      "Sherpa Golden Ale 50cl (4,5%)",
    ],
    price: ["6,00€", "6,00€", "8,00€", "7,00€", "7,00€","8,00€"],
    detail: ["", "", "", "", ""],
  },
  {
    accordionid: "flush-headingSix",
    bodyid: "flush-collapseSix",
    hashbodyid: "#flush-collapseSix",
    category: "TUMMA OLUT- DARK BEER",
    items: ["Krusovice Tumma (3,8%) 50cl ",],
    price: ["7,50€"],
    detail: [""],
  },
  {
    accordionid: "flush-headingSix",
    bodyid: "flush-collapseSix",
    hashbodyid: "#flush-collapseSix",
    category: "MUU",
    items: ["Krusovice Imperial Lager (5,0%) 50cl"],
    price: ["7,50€"],
    detail: [""],
  },
];

export default drinkItems;
