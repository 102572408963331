import React from "react";
import Divider from "./Divider";
import styled from "styled-components";

const MenuHeaderContainer = styled.div`
  width: 100%;
  min-height: 40vh;
  height:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 10rem;
  text-align:center;

  .head-title {
    margin: 0;
    color: #5d433c;
    font-family: "Montez";
    font-size: 2.5rem;
    
  }

  .header-name {
    margin: 0;
    font-family: "Suranna";
    font-size: 2.8rem;
  }

  .menu-desc {
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
  }

  @media (max-width:480px){
    padding:1rem;
    min-height:50vh;
  }
`;

const MenuHeader = ({ title, name, desc }) => {
  return (
    <MenuHeaderContainer>
      <p className="head-title">{title}</p>
      <p className="header-name">{name}</p>
      <Divider />
      <p className="menu-desc">{desc}</p>
    </MenuHeaderContainer>
  );
};

export default MenuHeader;
