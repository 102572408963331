import React from "react";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import BKG2 from "../assets/images/Mountnepal1.jpg";
import BKG3 from "../assets/images/home4.jpg";
import VisitUs from "../components/VisitUsPage/VisitUs";
import FormPageFlat from "../components/ReservationPage/FlatReservePage";
import ExquisiteCuisineMin from "../components/ExquisiteCuisineMin";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ReservationConatiner = styled.div`
  overflow:hidden;
  `;
const Reservation = () => {
  const { t } = useTranslation();
  return (
    <ReservationConatiner>
      <ExquisiteCuisine title={t('Home.reservation')} head={t('Home.byt')} image={BKG2} />
      <VisitUs />
      <ExquisiteCuisineMin
        title="Enjoy & Comfortable"
        head={t('Reservation.desc')}
        image={BKG3}
        divider={true}
      />
      <FormPageFlat />
    </ReservationConatiner> 
  );
};

export default Reservation;


