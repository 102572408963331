import React from "react";
import styled from "styled-components";
import homeMenuGallery from "../data/HomeMenuGallery";
import lunchMenu from "../data/LunchMenu";
import CustomerFavouriteMenu from "./CustomerFavouriteMenu";
import Divider from "./Divider";
import MenuGallery from "./MenuGallery";
import { useTranslation } from 'react-i18next';

const HomeMenuContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: auto;
  padding: 2rem;
  display: flex;
  align-items: center;

  #customWrapper {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 968px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
const MenuGallerySection = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: auto auto;
  padding: 3.5rem;

  @media (max-width: 968px) {
    width: 100%;
    padding: 0.7rem;
    grid-template-columns: auto;
  }
`;
const MenuItems = styled.div`
  width: 50%;

  .head-title {
    font-family: "Montez", sans-serif;
    font-size: 3rem;
    color: #b48c80;
    margin-top: 40px;

    @media (max-width: 1024px) {
      font-size: 2rem;
      margin-bottom: 0;
    }
  }
  .customer-favourite {
    font-family: "Montez", sans-serif;
    font-size: 1.8rem;
    color: #b48c80;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  @media (max-width: 968px) {
    padding: 0.4rem;
    width: 100%;
    height: 100%;
  }
`;

const HomeMenu = () => {
  const { t } = useTranslation();
  return (
    <HomeMenuContainer>
      <div id="customWrapper">
        <MenuGallerySection>
          {homeMenuGallery.map((item) => {
            return (
              <MenuGallery
                id={item.id}
                key={item.id}
                img={item.img}
                alt={item.alt}
              />
            );
          })}
        </MenuGallerySection>
      </div>
      <MenuItems>
        <p className="head-title">{t('Home.ftm')}</p>
        <p className="customer-favourite"> {t('Home.cf')}</p>
        <Divider />
        {lunchMenu.map((item, index) => {
          return (
            <CustomerFavouriteMenu
              id={item.id}
              reference={item.reference}
              key={index}
              image={item.image}
              dishname={item.dishname}
              dishitems={item.dishitems}
              price={item.price}
            />
          );
        })}
      </MenuItems>
    </HomeMenuContainer>
  );
};

export default HomeMenu;
