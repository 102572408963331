import NoPicture from "../../../assets/FoodImg/Picture1.png";
import LihaThali from "../../../assets/FoodImg/LihaThali.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


const ThaliDishes
  = [
  
    {
      image: NoPicture,
      dishname:[<h3 style={{
        fontSize: '22px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    }}>1. Kasvis-Thali</h3>],
    price:"18,50€",
      id:"",
      reference:""
    },
    {

      dishname: ["Dal Fry (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Thali.KasvisThali",
      id:"1",
        price: "",
    },
    {
  
      dishname: ["Sag Aalu (G, L)"],
      reference:"SecondResturant.Alacarte.Thali.KasvisThali",
      id:"2",
        price: "",
    },
    {

    dishname: ["Shahi Paneer (G)"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"3",
      price: "",
  },
  {

    dishname: ["Raita"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"4",
  },
  {

    dishname: ["RIISI JA NAAN LEIPA"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"5",
      price: "",
  },

  {
    image: LihaThali,
    dishname:[<h3 style={{
      fontSize: '22px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
  }}>2. Liha-Thali</h3>],
  price:"20,50€",
    id:"",
    reference:""
  },
  {
    dishname: ["BHENDAKO CHILLY (G, L) ",<img src={Chilly3} alt="medium" height="30" width="33"></img>],
    reference:"SecondResturant.Alacarte.Thali.LihaThali",
    id:"1",
  },
  {

    dishname: ["Butter Chicken (G) "],
    reference:"SecondResturant.Alacarte.Thali.LihaThali",
    id:"2",
      price: "",
  },
  {
  dishname: ["DAL (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"3",
    price: "",
},
{
  dishname: ["Raita"],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"4",
},
{
  dishname: ["RIISI JA NAAN LEIPA"],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"5",
    price: "",
},

{
  image:NoPicture,
  dishname:[<h3 style={{
    fontSize: '22px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
}}>3. MIX Thali</h3>],
price:"21,90€",
  id:"",
  reference:""
},
{

  dishname: ["Dal Fry (G) ",<img src={Chilly1} alt="medium" height="30" width="33"></img>],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"1",
},
{

  dishname: ["KUKHURA TIKKA (G) "],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"2",
},
{
dishname: ["khasiko masu (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"3",
  price: "",
},
{
dishname: ["Sahi Paneer (G) "],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"4",
},
{
  dishname: ["Raita "],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"5",
  },
{
dishname: ["RIISI JA NAAN LEIPA"],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"6",
  price: "",
},
];
export default ThaliDishes;
