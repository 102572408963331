import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import LandingImage from "../assets/images/home5.jpg";
import Spoon from "../assets/images/spoon.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
// import UKFlag from "../assets/svg/uk.svg";
// import FinlandFlag from "../assets/svg/finland.svg";
import slide1 from "../assets/homeslide/mushroom.png";
import slide2 from "../assets/homeslide/bayleaf.png";
import slide3 from "../assets/homeslide/slicedonion.png";
import slide4 from "../assets/homeslide/leaf.png";
import slide5 from "../assets/homeslide/c.png";
import slide6 from "../assets/homeslide/star.png";
import slide7 from "../assets/homeslide/garlic.png";
import slide8 from "../assets/homeslide/onion.png";
import slide9 from "../assets/homeslide/nut.png";
import { useSpring, animated } from "react-spring";
import { useTranslation } from 'react-i18next';
// import i18next from 'i18next';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;
const trans5 = (x, y) => `translate3d(${x / 5.5}px,${y / 5.5}px,0)`;
const LandingPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 1100px;
  position: relative;
  background: url(${LandingImage}) center/cover no-repeat border-box;
  

  overflow: hidden;

  @media (max-width: 1024px) {
    height: 50vh;
  }
  @media (max-width: 768px) {
    background: url(${LandingImage}) center/cover no-repeat border-box;
    height: 100vh;
  }
`;
const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;
  position: relative;

  .discover {
    font-family: "Montez", sans-serif;
    font-size: 3rem;
    color: #fcdb9b;
  }

  .hotel-name {
    margin: 0;
    font-family: "Suranna", sans-serif;
    color: #fff;
    font-size: 3rem;
    line-height: 1.2;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  span {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: "";
      content: "";
      display: block;
      height: 0.05em;
      min-width: 6vw;
    }
    &::before {
      background: #fcdb9b;
      margin-right: 0.5rem;
    }
    &::after {
      background: #fcdb9b;
      margin-left: 0.5rem;
    }
  }
  .opening-hour {
    margin: 0.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  .opening-day {
    font-size: 1rem;
    margin: 0.2rem 0;
  }

  .containerHover {
    cursor: pointer;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .slide-img-1,
  .slide-img-2,
  .slide-img-3,
  .slide-img-4,
  .slide-img-5,
  .slide-img-6,
  .slide-img-7,
  .slide-img-8,
  .slide-img-9,
  .slide-img-10
   {
    position: absolute;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
  }
  .slide-img-1 {
    background-image: url(${slide1});
    top: 48%;
    left: 10%;
    width: 13%;
    height: 20%;

    @media (max-width: 1024px) {
      left: 11%;
      width: 13%;
      top: 47%;
      height: 15%;
    }
  }

  .slide-img-2 {
    background-image: url(${slide2});
    width: 10%;
    height: 23%;
    top: 52%;
    right: 22%;

    @media (max-width: 1024px) {
      width: 14%;
      height: 17%;
      top: 55%;
      right: 20%;
    }
  }
  .slide-img-3 {
    background-image: url(${slide3});
    top: 106%;
    left: 40%;
    width: 10%;
    height: 21%;

    @media (max-width: 1024px) {
      height: 14%;
    top: 98%;
    left: 39%;
    width: 10%;
    }
  }

  .slide-img-4 {
    background-image: url(${slide4});
    width: 9%;
    height: 33%;
    top: 7%;
    left: 22%;
    @media (max-width: 1024px) {
      width: 10%;
      height: 24%;
      top: 17%;
      left: 14%;
    }
  }

  .slide-img-5 {
    background-image: url(${slide5});
    top: 21%;
    right: 42%;
    height: 19%;
    width: 6%;
    @media (max-width: 1024px){
      top:23%;
      right:39%;
      width: 9%;
    }
  }
  .slide-img-6 {
    background-image: url(${slide6});
    top: 105%;
    right: 21%;
    height: 19%;
    width: 13%;

    @media (max-width: 1024px) {
      top: 102%;
      right: 22%;
      height: 16%;
      width: 15%;
    }
  }
  .slide-img-7 {
    background-image: url(${slide7});
    top: 68%;
    right: 40%;
    height: 38%;
    width: 17%;

    @media (max-width: 768px) {
      top: 72%;
      right: 39%;
      height: 29%;
      width: 22%;
    }
  }
  .slide-img-8{
    background-image: url(${slide8});
    top: 44%;
    right: 9%;
    height: 23%;
    width: 11%;

    @media (max-width: 1024px){
      right: 13%;
      height: 19%;
      width: 13%;
    }
  }
  .slide-img-9{
    background-image: url(${slide9});
    top: 50%;
    right: 32%;
    height: 17%;
    width: 7%;

    @media(max-width: 1024px){
      top: 52%;
      right: 29%;
      height: 16%;
      width: 9%;
    }
  }
  .slide-img-10{
    background-image: url(${slide9});
    top: 87%;
    left: 28%;
    height: 17%;
    width: 7%;

    @media (max-width:1024px){
      top: 86%;
    left: 24%;
    height: 16%;
    width: 9%;
    }
  }
`;


const ChangeLocationSection = styled.div`
  .change-location {
    height: 160px;
    width: 40px;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    background-color: #393939;
    z-index: 15;
    position: fixed;
    left: 0;
    top: 20%;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    transition: transform 250ms;

    &:hover {
      color: #fff;
      transform: translateY(-2px);
    }
  }
  .change-location p {
    margin: 0;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    opacity: 1;
    cursor: pointer;
  }

  .navinactive {
    display: none;
  }
`;

const LandingPage = () => {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();

  const hideContent = () => {
    if (window.scrollY <= 975) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", hideContent);
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  useEffect(() => {
    Aos.init({ duration: 2000, delay: 300 });
  }, []);

  // function handleClick(lang) {
  //   i18next.changeLanguage(lang);
  // }
  return (
    <Fragment>
      <LandingPageContainer>
        <BackgroundFilter>

          <ChangeLocationSection>
            <div
              className={visible ? "change-location" : "navinactive"}
            >
              <p onClick={() => window.location.reload(false)}>Change Location</p>
            </div>
          </ChangeLocationSection>
          <ContentWrapper data-aos="fade-left">
            <h2 className="discover">{t('landingpage.discover')}</h2>
            <h1 className="hotel-name">Ravintola Mount Nepal</h1>
            <span className="spoon">
              <img src={Spoon} alt="spoon svg" height="30px" width="30px"></img>
            </span>
            <p className="opening-hour">{t('landingpage.openingHours')}</p>
            <p className="opening-day">
              {" "}
              {t('landingpage.mTf')}:&nbsp;10:30  - 22:00 
            </p>
            <p className="opening-day">
              {t('landingpage.weekend')}:&nbsp;12:00  - 22:00 
            </p>
            <div
              className="containerHover"
              onMouseMove={({ clientX: x, clientY: y }) =>
                set({ xy: calc(x, y) })
              }
            >
              <animated.div
                class="slide-img-1"
                style={{ transform: props.xy.interpolate(trans1) }}
              />
              <animated.div
                class="slide-img-2"
                style={{ transform: props.xy.interpolate(trans2) }}
              />
              <animated.div
                class="slide-img-3"
                style={{ transform: props.xy.interpolate(trans3) }}
              />
              <animated.div
                class="slide-img-4"
                style={{ transform: props.xy.interpolate(trans4) }}
              />
              <animated.div class="slide-img-5" />
              <animated.div
                class="slide-img-6"
                style={{ transform: props.xy.interpolate(trans2) }}
              />
              <animated.div class="slide-img-7" />
              <animated.div
                class="slide-img-8"
                style={{ transform: props.xy.interpolate(trans5) }}
              />
                 <animated.div
                class="slide-img-9"
                style={{ transform: props.xy.interpolate(trans5) }}
              />
                <animated.div
                class="slide-img-10"
                style={{ transform: props.xy.interpolate(trans2) }}
              />
            </div>
          </ContentWrapper>
        </BackgroundFilter>
      </LandingPageContainer>
    </Fragment>
  );
};

export default LandingPage;
