const navbarItems = [
  {
    id: "1",
    path: "/",
    name: "Home",
    tag: "Home"
  },
  {
    id: "2",
    path: "/about",
    name: "About",
    tag: "About"

  },
  {
    id: "3",
    path: "/menu",
    name: "Menu",
    tag: "Menu"

  },
  {
    id: "5",
    path: "/alacarte",
    name: "A La Carte",
    tag: "A La Carte"

  },
  {
    id: "6",
    path: "/drinks",
    name: "Drinks",
    tag: "Drinks"

  },
  {
    id: "4",
    path: "/reservation",
    name: "Reservation",
    tag: "Reservation"

  },

  {
    id: "7",
    path: "/contact",
    name: "Contact",
    tag: "Contact"

  },
  
];

export default navbarItems;
