import React, { useEffect } from "react";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import DrinksImg from "../assets/images/drinks.jpg";
// import ContactBkg1 from "../assets/images/home2.jpg";
import WineAccordion from "../components/WineAccordion";
import drinkItems from "../data/drinkItems";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import { Container } from "@material-ui/core";
// import FormPageFlat from "../components/ReservationPage/FlatReservePage";
// import DrinksMenu from "../components/DrinksMenu";
import { useTranslation } from "react-i18next";

const LargeScreenBackground = styled.div`
  background: linear-gradient(270deg, #feac5e, #c779d0, #4bc0c8);
`;
const DrinkWrapper = styled.div`
  overflow: hidden;
  height: auto;
  min-height: 90vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;
const WineContainer = styled.div`
  width: 70%;
  height: auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const DrinkDetail = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;

  .the-menu {
    font-family: "Montez";
    font-size: 3.5rem;
    font-weight: 700;
    color: #630a69;
  }

  .welcome-rmn {
    font-family: "Suranna";
    font-size: 2rem;
  }

  .drinks-desc {
    font-size: 1.3rem;
    font-weight: 500;

    @media (max-width: 480px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const Drinks = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 100 });
  }, []);
  return (
    <div>
      <ExquisiteCuisine
        title={t('Drinks.dab')}
        head={t('Drinks.dbd')}
        image={DrinksImg}
      />
      <LargeScreenBackground>
        <Container maxWidth="xl">
          <DrinkWrapper>
            <DrinkDetail data-aos="fade-right">
              <p className="the-menu">{t('Drinks.drinksMenu')}</p>
              <p className="welcome-rmn">{t('Drinks.welcome')}</p>
              {/* <p className="drinks-desc">
                Ram Gurung, chef de cousine in Ravintola Mount Nepal has
                prepared some delicious starters for every taste. Try some of
                our home made soups made from fresh ingredients or some of our
                refreshing salads. Bon appetit!
              </p> */}
            </DrinkDetail>
            <WineContainer data-aos="fade-left">
              <WineAccordion drinkItems={drinkItems} />
            </WineContainer>
          </DrinkWrapper>
        </Container>
      </LargeScreenBackground>
    </div>
  );
};

export default Drinks;
/*
 <ExquisiteCuisine
        title="Wine List"
        head="FINEST WINERY"
        image={ContactBkg1}
        divider="true"
      />
      <DrinksMenu />
*/
