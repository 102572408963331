import React from "react";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import AboutBkg from "../assets/images/Mountnepal2.jpg";
import styled from "styled-components";
import About from "../components/sushant/About";
import { useTranslation } from 'react-i18next';

const AboutContainer = styled.div`
  overflow: hidden
  `;
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer>
      <ExquisiteCuisine
        title={t('About.ourstory')}
        head="ABOUT RAVINTOLA MOUNT NEPAL"
        image={AboutBkg}
      />
      <About />
    </AboutContainer>
  );
};

export default AboutUs;
