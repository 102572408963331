import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom"
import navbarItems from "../../data/navbarItems";
import Brand from "../../assets/images/Picture1.png";
import Menubar from "../../assets/svg/menubar1.svg";
import UKFlag from "../../assets/svg/uk.svg";
import FinlandFlag from "../../assets/svg/finland.svg";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const NavbarContainer = styled.div`
  width: 100%;
  height: 20vh;
  background: transparent;
  color: #fff;
  z-index: 100;
  position: fixed;

  .active {
    background: #000;
    opacity: 0.94;
    height: 15vh;
    transition: .3s ease-in;
  }
  .cusnav {
    height: 20vh;
  }

  @media (max-width: 1024px) {
    .cusnav {
      height: 15vh;
    }
    .active {
      height: 12vh;
    }
  }

  @media (max-width: 768px) {
    .active {
      display: none;
    }
  }
`;
const LangLocSection = styled.div`
  position: absolute;
  color: #fff;
  top: 1%;
  right: 7%;
  display: flex;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    right: 1%;
  }
`;

const LanguageSection = styled.div`
  margin-right: 1.5rem;
  z-index: 100;
 
  span {
    cursor: pointer;
  }
  .uk-flag{
    cursor:pointer;
  }
  img {
    width: 25px;
    height: 15px;
    margin-right: 0.5rem;
    @media (max-width: 375px) {
      width:19px;
      height:14px;

     }
  }

  @media (max-width: 768px) {
    margin-right: 0.5rem;
  }
 
`;
const AddressSection = styled.div`
  .address-section {
    display: flex;

    .faslogo {
      margin-right: 0.5rem;
    }

    .fas-content {
      margin-right: 1rem;
    }
    @media (max-width: 375px) {
     font-size:9px;
    }
  }
`;

// const LanguageSection = styled.div`
// z-index: 100;
// position: absolute;
// right: 33%;
// top: 4%;
 
//   span {
//     cursor: pointer;
//   }
//   .uk-flag{
//     cursor:pointer;
//   }
//   img {
//     width: 25px;
//     height: 15px;
//     margin-right: 0.5rem;
//   }

//   @media (max-width: 768px) {
//     margin-right: 0.5rem;
//   }
//   @media (max-width: 380px) {
//     display: none;
//   }
// `;

const SecondLayer = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .brand-logo {
    max-width: 220px;
    width: auto;
    height: 100%;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    padding: 1rem;
    width: 100%;
  }
`;

const CompanyLogo = styled.img`
  max-width: 220px;
  width: auto;
  height: 100%;
  cursor: pointer;
`;

const MenuBars = styled.i`
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-image: url(${Menubar});
    background-size: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    right: 7%;
    color: #fff;
    z-index: 1000;
  }
`;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  outline: none;
  margin: 0 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
.route-link{
  color:#fff;
}
  .route-link-active{
    color:#f4d03f;
    }
    

  

  &:hover {
    color: #ffffff;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #ffffff;
    transition: 0 0.3s;
  }

  &:hover:after {
    width: 100%;
    transition: width 0.4s;
  }
`;

// const NavBtn = styled.div`
//   display: flex;
//   align-items: center;
//   margin-right: 1rem;
// `;

const Navbar = ({ toggle }) => {
  const { t } = useTranslation();
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 130) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <NavbarContainer>
      <div className={navbar ? "active" : "cusnav"}>
        <SecondLayer>
            <LangLocSection>
              <LanguageSection>
              <span className="uk-flag" onClick={()=>handleClick('en')}>
                <img src={UKFlag} />
              </span>
              <span className="finland-flag" onClick={()=>handleClick('fi')}>
                <img src={FinlandFlag} />
              </span>
            </LanguageSection> 
            <AddressSection>
              <div className="address-section">
                <span className="faslogo">
                  <i className="fas fa-phone-alt"></i>{" "}
                </span>
                <p className="fas-content">(358) 0503034503</p>
                <span className="faslogo">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <p>Länsituulentie 10, 02100 Espoo</p>
              </div>
            </AddressSection>
          </LangLocSection>
          <Link to="/" className="brand-logo">
            <CompanyLogo src={Brand} />
          </Link>
          <MenuBars onClick={toggle} />
          <NavMenu>
            {navbarItems.map((item, index) => {
              return (
                <NavMenuLinks>
                  <NavLink exact to={item.path} key={index} className="route-link" activeClassName="route-link-active" >
                  {t(`NavbarItems.${item.id}`).toUpperCase()}
                  </NavLink>
                </NavMenuLinks>
              );
            })}
          </NavMenu>

        </SecondLayer>

      </div>
    </NavbarContainer>
  );
};

export default Navbar;

/*
        <NavBtn>
          <Button to="/contact" primary='true' big='true'>
            Contact Us
          </Button>
        </NavBtn>
*/
