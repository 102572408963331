import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MenuItemContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .dish-price {
    border-top: 1px solid #b48c80;
    border-bottom: 1px solid #b48c80;
    padding: 1rem;
    font-family: "Montez", sans-serif;
    font-size: 2rem;
    color: #b48c80;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      font-size: 1rem;
      padding: 0.3rem 0;
    }
  }
`;

const DishImage = styled.img`
  width: 90px;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(55, 55, 55, 0.7);

  @media (max-width: 480px) {
    width: 65px;
  }
`;

const DishContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0.5rem;

  .dish-name {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  .dish-items {
    font-size: 14px;
    margin: 0;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;
const DummyHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
`;
const DummyData = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0.5rem;
`;

const MenuItem = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <MenuItemContainer key={props.id}>
        <DishImage src={props.image}></DishImage>
        <DishContent>
          <p className="dish-name">{props.dishname}</p>
          {props.reference === "AlacarteThali" ||
          props.reference === "AlacarteGroupMenu" ||
          props.reference === "AlacarteBeverages" ? (
            <p className="dish-items">{props.dishitems}</p>
          ) : (
            <p className="dish-items">{t(`${props.reference}.${props.id}`)}</p>
          )}
        </DishContent>
        {props.price ? <p className="dish-price">{props.price}</p> : null}
      </MenuItemContainer>
      <DummyHeader>
        <p className="dummy-header">{props.dummyheader}</p>
      </DummyHeader>
      <DummyData>
        <p className="dummy-content">{props.dummy}</p>
      </DummyData>
    </div>
  );
};

export default MenuItem;
