import React, { useEffect } from "react";
import "./visitUs.css";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import Divider from "../ReusableComponents/Divider";
import {useTranslation} from "react-i18next";

const VisitUsContainer = styled.div`
  overflow: hidden;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
`;

const VisitUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 100 });
  }, []);
  return (
    <VisitUsContainer data-aos="fade-up">
      <div>
        <MDBContainer>
          <MDBContainer className="header">
            <div className="heading">
              <div className="visit-us">
               {t('About.visitUs')}
                <div className="location">OUR LOCATIONS</div>
              </div>
              <Divider />
            </div>
          </MDBContainer>

          <MDBContainer>
            <MDBRow>
              <MDBCol className="first-col">
                ESPOO, FINLAND
                <div className="location-header">__________________</div>
                <div className="content">
                  Ravintola Mount Nepal,
                  <br />
                  Kaupinkalliontie,07 Espoo,
                  <br />
                  Finland
                  <br />
                </div>
                <div className="sub-header">
                  OPENING HOURS:
                  <br />
                </div>
                <div className="content">
                  Mon-Fri: 10:30 to 22:00,
                  <br />
                  Sat-Sun: 12:00 to 22:00,
                  <br />
                </div>
                <div className="sub-header">
                  CONTACT:
                  <br />
                </div>
                <div className="content">
                  09 463400,
                  <br />
                  info@mountnepal.fi
                  <br />
                </div>
                <a
                  href="https://www.google.com/maps/place/Ravintola+Mount+Nepal/@60.1705665,24.8013664,13.5z/data=!4m5!3m4!1s0x0:0xfdc282678ae5ccb5!8m2!3d60.1779121!4d24.8022727?hl=en-US"
                  target="_blank"
                >
                  <div className="direction">
                    <span>GET DIRECTION</span>{" "}
                    <DoubleArrowIcon fontSize="small" />{" "}
                  </div>
                </a>
              </MDBCol>
              <MDBCol className="second-col">
                ESPOO, FINLAND
                <br />
                <div className="location-header">__________________</div>
                <div className="content">
                  Ravintola Mount Nepal, <br />
                  Länsituulentie 10, 02100 Espoo, <br />
                  Finland
                  <br />
                </div>
                <div className="sub-header">
                  OPENING HOURS:
                  <br />
                </div>
                <div className="content">
                  Mon-Fri: 10:30 to 22:00,
                  <br />
                  Sat-Sun: 12:00 to 22:00,
                  <br />
                </div>
                <div className="sub-header">
                  CONTACT:
                  <br />
                </div>
                <div className="content">
                  (358) 0503034503,
                  <br />
                  info@mountnepal.fi
                  <br />
                </div>
                <a
                  href="https://www.google.com/maps/place/Ravintola+Mount+Nepal/@60.1705665,24.8013664,13.5z/data=!4m5!3m4!1s0x0:0xfdc282678ae5ccb5!8m2!3d60.1779121!4d24.8022727?hl=en-US"
                  target="_blank"
                >
                  <div className="direction">
                    <span>GET DIRECTION</span>{" "}
                    <DoubleArrowIcon fontSize="small" />{" "}
                  </div>
                </a>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBContainer>
      </div>
    </VisitUsContainer>
  );
};
export default VisitUs;
