const Beverages = [
  {
    dummyheader: "LASSI",
    reference: "AlacarteBeverages",
    dummy: [
      "Suola - Salt Lassi",
      "Makea - Sweet Lassi",
      "Mango Lassi ",
      "Banaani - Banana Lassi",
      "Mansikka- Strawberry Lassi",
      "Mix- Lassi ",
      
    ],
    price: ["€ 4.00", "€ 4.00", "€ 4.00", "€ 4.00", "€ 4.00", "€ 5.00", ""],
    detail: [
      "",
      "",
      "(Laktositon Jogurtti ja mango sekoitus)",
      "",
      "",
      "(Laktositon Jogurtti Banani ja Mansikka sekoitus)",
    ],
  },
  {
    dummyheader: "JUOMAT BEVERAGES 0,33/ 0,50 CL",
    reference: "AlacarteBeverages",
    dummy:[
      "Coke, Zero-coke, Jaffa, 7-up",
      "Mehu- Juice",
      "Kahvi, Musta Tee, Vihreä Tee",
      "Nepalilainen Kahvi,Tee ",
      "Maito- Milk",
      "Vichy 0,50 L"
    ],
    price: ["2,90€/3,90€", "2,90€/3,90€",  "2,50€", "3,50€", "2,00€","3,50€"],
    detail: [
      "",
      "(Appelsiini-Orange, Omena-Apple)",
      "(Coffee, Black tea, Green tea)",
      "(Nepali Coffee, Tea)",
      "",
    ],
  },
  {
    dummyheader: "SIIDERI - CIDER",
    reference: "AlacarteBeverages",
    dummy: [
      "Omean Siideri 33cl (4,7%) ",
      "Lonkero- Long Drink 33cl (5,5%)",
      "Bulmers Original 50cl (4,5%)",
      "Strongbow 25,7cl (5,3%)",
    ],
    price: ["5,90€", "5,50€", "7,00€", "5,50€"],
    detail: ["", "", "", ""],
  },
  {
    dummyheader: "OLUT - BEERS",
    reference: "AlacarteBeverages",
    dummy: [
      "Alkoholiton olut (Buckler/Heineken) 33cl ",
      "III Olut Aura 33cl (4,5%)",
      "III Olut Aura 50cl (4,5%)",
      "III Karhu (4,6%) 50cl",
      "IV Karhu (5,2%) 50cl",
      "Karhu/Lapinkulta IV 50cl",
    ],
    price: ["4,00€", "5,00€", "6,00€","6,00€", "6,50€", "6,50€"],
    detail: ["", "", "", "", "", ""],
  },
  {
    dummyheader: "ULKO OLUT - FOREIGN BEER Nepalilainen",
    reference: "AlacarteBeverages",
    dummy: [
      "Mt Everest Golden (5,0%) 33cl",
      "Khukuri Lager (4,7%) 33cl  ",
      "Kathmandu 50cl /bottle (4,8%) ",
      "Nepal Ice Lager (5,5%) 33 cl ",
"King Fisher (Indian) (4,8%) 33cl",
      "Sherpa Golden Ale 50cl (4,5%)",
    ],
    price: ["6,00€", "6,00€", "8,00€", "7,00€", "7,00€","8,00€"],
    detail: ["", "", "", "", ""],
  },
  {
    dummyheader: " TUMMA OLUT- DARK BEER ",
    reference: "AlacarteBeverages",
    dummy:["Krusovice Tumma (3,8%) 50cl ",],
    price: ["7,50€"],
    detail: [""],
  },
  {
    dummyheader: " MUU ",
    reference: "AlacarteBeverages",
    dummy: ["Krusovice Imperial Lager (5,0%) 50cl"],
    price: ["7,50€"],
    detail: [""],
  }
];
export default Beverages;
