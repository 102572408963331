import Item1 from "../../../assets/FoodImg/Picture1.png";
import Item2 from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"



const GroupMenu
     = [
          {
               image: Item1,
               dishname: "MENU KAHDELLE – MENU FOR TWO",
               dishitems: ["APPETIZER", <p></p>,
                    "PRAWN SOUP (G, L)", <br></br>,
                    "Creamy Shrimp Soup Shrimps soup with cream. OR", <p></p>,
                    "VEGETABLE PAKORA", <p></p>,
                    "MAIN COURSE - MAIN COURSE", <br></br>,
                    "SHAHI PANEER (G)", <br></br>,
                    "Bread cheese in tomato-butter-cream sauce.", <br></br>, "Finish cottage cheese in tomato -butter-cream sauce.", <p></p>,
                    "CHICKEN TIKKA MASALA (G) ",<img src={Chilly2} height="30" width="33"></img>, <br></br>,
                    "Herb-yogurt marinated and tandoori grilled chicken fillets in a hot and spicy tomato-onion-garlic-ginger-chili-masala sauce.", <br></br>, " Tandoori-grilled herb-yogurt marinated chicken fillet pieces in hot & spicy tomato-onion-garlic-Ginger-chilli-masala sauce.", <p></p>,
                    "DESSERT", <br></br>,
                    "Mango Lassi or Ice cream",],
               reference:"AlacarteGroupMenu",
               id:"1",
                    price: "49,50€",
          },
          {
               image: Item2,
               dishname: "MENU NELJÄLLE – MENU FOR FOUR",
               dishitems: ["APPETIZER - APPETIZER", <br></br>,
                    "VEGPAKODA", <br></br>,
                    "Deep Fried Vegetable Pies. ( Potatoes, cauliflower and onion)  Or", <p></p>,

                    "HOT & SOUR SOUP (G, L)",<img src={Chilly2} height="30" width="33"></img>, <br></br>,
                    "Hot and spicy lemon soup with chicken, bamboo, mushrooms and Papua     Spicy lemon-flavored soup with chicken , Bamboo shoots, mushrooms and bean", <p></p>,

                    "MAIN COURSE", <p></p>,


                    "SHAHI PANEER (G)", <br></br>,
                    "Bread cheese in tomato-butter-cream sauce.", <br></br>, "Cottage cheese cooked in tomato-butter-cream sauce.", <p></p>,

                    "PRAWN MASALA (G, L)",<img src={Chilly2} height="30" width="33"></img>, <br></br>,
                    "Giant prawns and peppers, tomatoes, onions, garlic and ginger in a well-seasoned coriander-garlic sauce.", <br></br>, "King prawns and Capsicum with tomato, onion, garlic and ginger in hot spiced coriander-garlic sauce.", <p></p>,

                    "LAMB CURRY (G) ", <br></br>,<img src={Chilly1} height="30" width="33"></img>,
                    "Braised Lamb Pieces, Garlic, In Ginger Sauce.  ", <br></br>, "   Stewed lamb fillet pieces in garlic, Ginger sauce.", <p></p>,

                    "CHICKEN TIKKA MASALA (G) ", ,<img src={Chilly2} height="30" width="33"></img>, <br></br>,
                    "Tandoori grilled chicken breast pieces with tomato, masala and tandori in masala sauce. Tandoori grilled chicken breast pieces in tomato, masala & tandoori masalasauce.", <p></p>,

                    "DESSERT - DESSERT", <br></br>,
                    "KULFI", <br></br>,
                    "Condensed milk with pistachio, almond, cashew, cardamom,    sugar and cream sauce     solidified recipe made of condensed milk, pistachio, almonds, cardamom, sugar and cream", <br></br>, "OR", <br></br>,
                    "MANGO LASSI",],
               reference:"AlacarteGroupMenu",
               id:"2",
                    price: "99,90€",
               dummy:["Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>],
          },
     ];
export default GroupMenu;
