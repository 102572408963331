
import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Somosa from "../../../assets/FoodImg/Samosa.png";
import PrawnPokora from "../../../assets/FoodImg/PrawnPakora.png";
import ChickenSalad from "../../../assets/FoodImg/ChickenSalad.png";
import Chilly1  from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"

export const Starters = [
  { 
    image: NoPicture,
    dishname: "1. VEGETABLE SOUP (G,L) (Vegan)",
    price: "5,80€",
    id:"1",
    reference:"SecondResturant.Alacarte.Starters"
  },
  {
    image: NoPicture,
    dishname: "2. TOMATO SOUP (G)",
    price: "5,80€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"2"
  },
  {
    image: NoPicture,
    dishname: "3. MOUNT SPECIAL SOUP ",
    price: "7,80€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"3"
  },
  {
    image: NoPicture,
    dishname: ["4. TOM YUM SOUP (G,L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    price: "8,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"4"
  },
  {
    image: NoPicture,
    dishname: "5. MIX VEG PAKORA (G,L) (Vegan) ",
    price: "7,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"5"
  },
  {
    image: NoPicture,
    dishname: "6. PANEER PAKORA (G) ",
    price: "7,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"6"
  },
  {
    image: Somosa,
    dishname: [" 7. VEGETABLE SAMOSA (L) (Vegan)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    price: "7,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"7"
  },
  {
    image: NoPicture,
    dishname: ["8. PANEER TIKKA (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    price: "8,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"8"
  },
  {
    image: NoPicture,
    dishname: " 9. Alu Chop (G,L) (Vegan)",
    price: "7,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"9"

  },

  {
    image: NoPicture,
    dishname: "10. CHICKEN FINGERS (L) ",
    price: "6,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"10"
  },
  {
    image: NoPicture,
    dishname: " 11. CHICKEN PAKORA (G,L) ",
    price: "7,80€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"11"
  },
  {
    image: PrawnPokora,
    dishname: " 12. PRAWN PAKORA (G,L)",
    price: "8,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"12"
  },
  {
    image: NoPicture,
    dishname: [" 13. PRAWNS SANDEKO (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    price: "9,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"13"
  },
  {
    image: NoPicture,
    dishname: [" 14. KUKHURA SANDEKO (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    price: "7,80€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"14"
  },
  {
    image: NoPicture,
    dishname: [" 15. Khasi Sadeko (G,L)", <img src={Chilly1} alt="mild" height="30" alt="mild" width="33"></img>, ],
    price: "10,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"15"
  },
  {
    image: NoPicture,
    dishname: [" 16. LAMB SHEIKH KABAB (G,L)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    price: "10,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"16"
  },
  {
    image: ChickenSalad,
    dishname: "17. TANDOORI CHICKEN SALAD (G) ",
    price: "7,50€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"17"
  },
  {
    image: NoPicture,
    dishname: "18. PRAWN SALAD (G,L)  ",
    price: "8,00€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"18"
  },
  {
    image: NoPicture,
    dishname: " 19. GREEN SALAD (G,L) (vegan)",
    price: "6,50€",
    reference:"SecondResturant.Alacarte.Starters",
    id:"19",
    dummy:[<p></p>,"Mild / Mild", <img src={Chilly1} alt="mild" alt ="medium" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="medium" alt ="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="spicy" height="30" width="33"></img>,],

  },
    
];
