import React from "react";
import styled from "styled-components";
const AccordionContainer = styled.div`
  width: 100%;
  height: 100%;

  .accordion {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
    transition: all -0.2s ease;
  }

  .accordion-flush {
    background: rgba(255, 255, 255, 0.2);
    font-weight: 700;
  }
  button.accordion-button {
    color: #630a69;
    background: rgba(255, 255, 255, 0.2);
    font-family: "Suranna";
    font-weight: 700;
  }
  button.accordion-button.collapsed {
    color: #630a69;
    font-size: 1rem;
    font-weight: 700;
  }
  .accordion-body li {
    color: #630a69;
  }

  .accordion-button:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }
`;

const IndItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .individual-item {
    display: flex;
    justify-content: space-between;
    line-height: 3;
  }

  ul {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;

  .detail {
    font-size: 0.8rem;
    line-height: 0.3;
    text-align: center;
    color: #0d0d0d;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ItemPrice = styled.div``;

const WineAccordion = (props) => {
  return (
    <AccordionContainer>
      {props.drinkItems.map((item, index) => {
        return (
          <div
            class="accordion accordion-flush"
            id="accordionFlushExample"
            key={index}
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id={item.accordionid}>
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={item.hashbodyid}
                  aria-expanded="false"
                  aria-controls={item.bodyid}
                >
                  {item.category}
                </button>
              </h2>
              <div
                id={item.bodyid}
                class="accordion-collapse collapse"
                aria-labelledby={item.accordionid}
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <IndItem>
                    <div className="item-name">
                      <ul>
                        <li className="individual-item">
                          <ItemDetail>
                            <div> {item.items[0]}</div>
                            <div className="detail">{item.detail[0]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[0]}</ItemPrice>
                        </li>
                        <li className="individual-item">
                          <ItemDetail>
                            <div> {item.items[1]}</div>
                            <div className="detail"> {item.detail[1]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[1]}</ItemPrice>{" "}
                        </li>
                        <li className="individual-item">
                          <ItemDetail>
                            <div> {item.items[2]}</div>
                            <div className="detail"> {item.detail[2]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[2]}</ItemPrice>{" "}
                        </li>
                        <li className="individual-item">
                          <ItemDetail>
                            <div> {item.items[3]}</div>
                            <div className="detail"> {item.detail[3]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[3]}</ItemPrice>{" "}
                        </li>
                        <li className="individual-item">
                          <ItemDetail>
                            <div> {item.items[4]}</div>
                            <div className="detail"> {item.detail[4]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[4]}</ItemPrice>{" "}
                        </li>
                        <li className="individual-item">
                          <ItemDetail>
                            <div>{item.items[5]}</div>
                            <div className="detail"> {item.detail[5]}</div>
                          </ItemDetail>
                          <ItemPrice>{item.price[5]}</ItemPrice>{" "}
                        </li>
                      </ul>
                    </div>
                  </IndItem>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </AccordionContainer>
  );
};

export default WineAccordion;
