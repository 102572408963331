import React from "react";
import "./formPage.css";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import Divider from "../Divider";
import Item1 from "../../assets/FoodImg/MangoLassi.png";
import food from "../../assets/FoodImg/food.jpg";
import emailjs from "emailjs-com";
import { Field, Form, Formik, useField, FormikProps } from "formik";
import * as Yup from "yup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import axios from "axios";

const FormContainer = styled.div`
padding:1.5rem;
 .form-control{
   margin-bottom: 1.3em;
 }`;
const AlertContainer = styled.div`
  min-height: 50vh;
  height: auto;
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 20vh;
    width: 90%;
  }
`;

const startDay = new Date().toLocaleDateString("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 100,
      border: "none",
      marginButton: "1.3em"
    },
  },
}));
const FormPage = () => {
  const {t}=useTranslation();
  const [sendStatus, setSendStatus] = React.useState(false);

  function sendEmail(values) {

  const url = "http://"+window.location.hostname+"/api/sendmail";

   

  
     axios.post(url, { name: values.name, email: values.email, date: values.date , time:values.time, people: values.people,duration:values.duration,phone:values.phone})
     .then((response) => {

      setSendStatus(true);
      
       
       
    
   
  });
}
  // RegEx for phone number validation
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const timeRegExp = /(0?[1-9]|1[0-2]):([0-5]?\d)\s([pa]m)-(0?[1-9]|1[0-2]):([0-5]?\d)\s([pa]m)/;

  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "*Name is Invalid")
      .max(100, "*Name is Invalid")
      .required("*Name is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email is Invalid")
      .required("*Email is required"),
    date: Yup.string(),
    // time: Yup.string().matches(
    //   timeRegExp,
    //   "doesn't match with restaurant openeing and closing time"
    // ),
    duration: Yup.string(),
    people: Yup.number()
      .min(1, "*Invalid")
      .max(10, "*For more than 10, please call on 09 463400"),
    phone: Yup.string()
      .min(5, "*Invalid")
      .max(15, "*Invalid")
      //.matches(phoneRegExp, "*Phone number is not valid")
      .required("*Phone number required"),
  });

  const endDay = new Date(Date.now() + 6.048e8 * 2).toLocaleDateString(
    "fr-CA",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );
  const classes = useStyles();

  return (
    <FormContainer>
      {sendStatus ? (
        <AlertContainer>
          <div className={classes.customAlert}>
            <Alert variant="outlined" severity="success">
              Thank you for submitting your request for reservation. Someone
              from Mount Nepal will confirm your request.
            </Alert>
          </div>
        </AlertContainer>
      ) : (
          <MDBContainer className="container">
            <MDBRow className="all-form">
              <MDBCol md="4">
                <div className="image1">
                  <img src={Item1} height="400px" />
                </div>
              </MDBCol>
              <MDBCol className="form">
                <MDBRow>
                  <MDBCol md="12">
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        date: "",
                        time: "",
                        duration: "",
                        people: "",
                        phone: "",
                      }}
                      // Hooks up our validationSchema to Formik
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        sendEmail(values)
                      }}
                    >
                      {(formik) => (
                        <form onSubmit={formik.handleSubmit} id="home-form">
                          <div className="form-head">
                            <p className="reservation">{t('Home.reservation')}</p>
                            <p className="book-table">
                              <b>{t('Home.byt')} </b>{" "}
                            </p>
                            <Divider />
                          </div>
                          <div className="grey-text">
                            <FormControl className="form-control" fullWidth>
                              {formik.touched.name && formik.errors.name ? (
                                <TextField
                                  error
                                  id="name"
                                  label={formik.errors.name}
                                  name="Your Name"
                                  {...formik.getFieldProps("name")}
                                  size="small"
                                  required
                                />
                              ) : (
                                  <TextField
                                    id="name"
                                    label="Your Name"
                                    name="name"

                                    {...formik.getFieldProps("name")}
                                    size="small"
                                    required
                                  />
                                )}
                            </FormControl>
                            <FormControl className="form-control" fullWidth>
                              {formik.touched.email && formik.errors.email ? (
                                <TextField
                                  error
                                  id="email"
                                  label={formik.errors.email}
                                  name="email"

                                  {...formik.getFieldProps("email")}
                                  size="small"
                                  required
                                />
                              ) : (
                                  <TextField
                                    id="email"
                                    label="Email"
                                    name="email"

                                    {...formik.getFieldProps("email")}
                                    size="small"
                                    required
                                  />
                                )}
                            </FormControl>
                            <FormControl className="form-control" fullWidth>
                              {formik.touched.date && formik.errors.date ? (
                                <TextField
                                  error
                                  InputProps={{ inputProps: { min: startDay, max: endDay } }}
                                  type="date"
                                  id="outlined-basic"

                                  name="date"
                                  label={formik.errors.date}
                                  size="small"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...formik.getFieldProps("date")}
                                  required
                                />
                              ) : (
                                  <TextField
                                    InputProps={{ inputProps: { min: startDay, max: endDay } }}
                                    type="date"
                                    id="outlined-basic"

                                    name="date"
                                    label="Date"
                                    size="small"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    {...formik.getFieldProps("date")}
                                    required
                                  />
                                )}
                            </FormControl>
                            <FormControl
                              className="form-control"
                              fullWidth
                            >
                              {formik.touched.time && formik.errors.time ? (
                                <TextField
                                  error
                                  type="time"
                                  

                                  name="time"
                                  label={formik.errors.time}
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  {...formik.getFieldProps("time")}
                                  required
                                />
                              ) : (
                                <TextField
                                select
                                
                                type="time"
                                id="outlined-basic"
                                
                                name="time"
                                label="Time"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                {...formik.getFieldProps("time")}
                                required
                              >
                                <MenuItem value={10.30}>10:30</MenuItem>
                                <MenuItem value={10.45}>10:45</MenuItem>
                                <MenuItem value={11.00}>11:00</MenuItem>
                                <MenuItem value={11.15}>11:15</MenuItem>
                                <MenuItem value={11.30}>11:30</MenuItem>
                                <MenuItem value={11.45}>11:45</MenuItem>
                                <MenuItem value={12.00}>12:00</MenuItem>
                                <MenuItem value={12.15}>12:15</MenuItem>
                                <MenuItem value={12.30}>12:30</MenuItem>
                                <MenuItem value={12.45}>12:45</MenuItem>
                                <MenuItem value={13.00}>13:00</MenuItem>
                                <MenuItem value={13.15}>13:15</MenuItem>
                                <MenuItem value={13.30}>13:30</MenuItem>
                                <MenuItem value={13.45}>13:45</MenuItem>
                                <MenuItem value={14.00}>14:00</MenuItem>
                                <MenuItem value={14.15}>14:15</MenuItem>
                                <MenuItem value={14.30}>14:30</MenuItem>
                                <MenuItem value={14.45}>14:45</MenuItem>
                                <MenuItem value={15.00}>15:00</MenuItem>
                                <MenuItem value={15.15}>15:15</MenuItem>
                                <MenuItem value={15.30}>15:30</MenuItem>
                                <MenuItem value={15.45}>15:45</MenuItem>
                                <MenuItem value={16.00}>16:00</MenuItem>
                                <MenuItem value={16.15}>16:15</MenuItem>
                                <MenuItem value={16.30}>16:30</MenuItem>
                                <MenuItem value={16.45}>16:45</MenuItem>
                                <MenuItem value={17.00}>17:00</MenuItem>
                                <MenuItem value={17.15}>17:15</MenuItem>
                                <MenuItem value={17.30}>17:30</MenuItem>
                                <MenuItem value={17.45}>17:45</MenuItem>
                                <MenuItem value={18.00}>18:00</MenuItem>
                                <MenuItem value={18.15}>18:15</MenuItem>
                                <MenuItem value={18.30}>18:30</MenuItem>
                                <MenuItem value={18.45}>18:45</MenuItem>
                                <MenuItem value={19.00}>19:00</MenuItem>
                                <MenuItem value={19.15}>19:15</MenuItem>
                                <MenuItem value={19.30}>19:30</MenuItem>
                                <MenuItem value={19.45}>19:45</MenuItem>
                                <MenuItem value={20.00}>20:00</MenuItem>
                                <MenuItem value={20.15}>20:15</MenuItem>
                                <MenuItem value={20.30}>20:30</MenuItem>
                                <MenuItem value={20.45}>20:45</MenuItem>
                                <MenuItem value={21.00}>21:00</MenuItem>
                                <MenuItem value={21.15}>21:15</MenuItem>
                                <MenuItem value={21.30}>21:30</MenuItem>
                                <MenuItem value={21.45}>21:45</MenuItem>
                                <MenuItem value={22.00}>22:00</MenuItem>
                              </TextField>   
                                )}
                            </FormControl>
                            <FormControl className="form-control" fullWidth>
                              {formik.touched.duration && formik.errors.duration ? (
                                <TextField
                                  error
                                  id="duration"
                                  label={formik.errors.duration}
                                  name="duration"

                                  {...formik.getFieldProps("duration")}
                                  size="small"
                                  />
                              ) : (
                                  <TextField
                                    id="duration"
                                    label="Duration in minutes/hr"
                                    name="duration"

                                    {...formik.getFieldProps("duration")}
                                    size="small"
                                  />
                                )}
                            </FormControl>

                            <FormControl className="form-control" fullWidth>
                              {formik.touched.people && formik.errors.people ? (
                                <TextField
                                  error
                                  id="people"
                                  label={formik.errors.people}
                                  name="people"

                                  {...formik.getFieldProps("people")}
                                  size="small"
                                  required
                                />
                              ) : (
                                  <TextField
                                    number
                                    id="people"
                                    label="Number of people"
                                    name="people"

                                    {...formik.getFieldProps("people")}
                                    size="small"
                                    required
                                  />
                                )}
                            </FormControl>
                            <FormControl className="form-control" fullWidth>
                              {formik.touched.phone && formik.errors.phone ? (
                                <TextField
                                  error
                                  id="phone"
                                  label={formik.errors.phone}
                                  name="phone"

                                  {...formik.getFieldProps("phone")}
                                  size="small"
                                  required
                                />
                              ) : (
                                  <TextField
                                    id="phone"
                                    label="Phone"
                                    name="phone"

                                    {...formik.getFieldProps("phone")}
                                    size="small"
                                    required
                                  />
                                )}
                            </FormControl>
                          </div>
                          <FormControl className="form-control" fullWidth>
                            <Button
                              type="submit"
                              variant="outlined"
                              color="secondary"
                              size="Large"
                            >
                              FIND A TABLE
                            </Button>
                          </FormControl>
                        </form>
                      )}
                    </Formik>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="3" className="image2">
                <img src={food} className="img-fluid" alt="" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
    </FormContainer>
  );
};

export default FormPage;
