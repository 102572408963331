import NoPicture from "../../../assets/FoodImg/Picture1.png";
import SagPaneer from "../../../assets/FoodImg/SagPaneer.png";
import Chilli1 from "../../../assets/FoodImg/C1.png";
import Chilli2 from "../../../assets/FoodImg/C2.png";
import Chilli3 from "../../../assets/FoodImg/C3.png";

const TueFood = [
  {
    image: NoPicture,
    dishname: ["1. AALU GOVI (G,L) (VEGAN)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Fried potatoes, ginger, cauliflower and cumin in masala sauce.",

    id:"1",
    reference:"LunchTueFood",
      price: "9,00€",
  },
  {
    image: SagPaneer,
    dishname: "2. SAG PANEER (G)",
    dishitems:
      "Spinach and cream cheese in cream-curry sauce.",
    id:"2",
    reference:"LunchTueFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "3. CHICKEN KORMA (G)",
    dishitems:
      "Fried chicken breast fillets in tomato-cashew-coconut cream sauce.",
    id:"3",
    reference:"LunchTueFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname:[ "4. LAMB CURRY (G, L)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Fried lamb pieces in spicy curry sauce.",
    id:"4",
    reference:"LunchTueFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "5. Nariwal Prawn (G, L)",
    dishitems:
      "Shrimp in coconut-flour-tomato cream sauce.",
    id:"5",
    reference:"LunchTueFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["6. Today's Special BHALE KARAI SPECIAL (G) ",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Chicken breast marinated in tandoor ovenmarinated in yoghurt, cream cheese, cashew nuts, onion-garlic-ginger-tomato-cream-butter-sauce.",
    id:"6",
    reference:"LunchTueFood",
      price: "12,50€",
      dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
      "Kids Portion - 7,50€"],
      dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
        "G = Gluten free L = Lactose free", <p></p>,
        "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
      ],
  },
];
export default TueFood;
