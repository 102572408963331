import React, { useEffect } from "react";
import styled from "styled-components";
import Divider from "./Divider";
import Aos from "aos";
import "aos/dist/aos.css";

const CuisineContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-family: "Montez", sans-serif;
    font-size: 4rem;
    color: #fcdb9b;
    text-align: center;
  }
  .feast {
    font-family: "Suranna", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  @media (max-width: 1024px) {
    height: 50vh;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 3rem;
    }
    .feast {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
`;

const ImageContainer = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
`;
const ExquisiteCuisine = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 100 });
  }, []);

  return (
    <CuisineContainer>
      <ImageContainer src={props.image} alt="cuisine" />
      <p className="title" data-aos="fade-up">
        {props.title}
      </p>
      <p className="feast" data-aos="fade-up">
        {props.head.toUpperCase()}
      </p>
      {props.divider ? <Divider /> : null}
    </CuisineContainer>
  );
};

export default ExquisiteCuisine;
