import Item1 from "../../../assets/FoodImg/Picture1.png";
import Item2 from "../../../assets/FoodImg/Picture1.png";
import Item3 from "../../../assets/FoodImg/Picture1.png";
import Item4 from "../../../assets/FoodImg/Picture1.png";
import Item5 from "../../../assets/FoodImg/Picture1.png";
import Item6 from "../../../assets/FoodImg/Picture1.png";
import Item7 from "../../../assets/FoodImg/Picture1.png";
import Item8 from "../../../assets/FoodImg/Samosa.png";
import Item9 from "../../../assets/FoodImg/Picture1.png";
import Item10 from "../../../assets/FoodImg/Picture1.png";
import Item11 from "../../../assets/FoodImg/PrawnPakora.png";
import Item12 from "../../../assets/FoodImg/Picture1.png";
import Item13 from "../../../assets/FoodImg/Picture1.png";
import Item14 from "../../../assets/FoodImg/Picture1.png";
import Item15 from "../../../assets/FoodImg/ChickenSalad.png";
import Item16 from "../../../assets/FoodImg/Picture1.png";
import Item17 from "../../../assets/FoodImg/Salad.png";
import Item18 from "../../../assets/FoodImg/Picture1.png";
import Item19 from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"

const Starter
 = [
  { 
    image: Item1,
    dishname: "1. VEGETABLE SOUP (G,L) (Vegan)",
    dishitems:
      "Mixed vegetable soup.",
    price: "5,80€",
    id:"1",
    reference:"AlacarteStarter"
  },
  {
    image: Item2,
    dishname: "2. TOMATO SOUP (G)",
    dishitems:
    "Thick creamy tomato soup.",
    price: "5,80€",
    reference:"AlacarteStarter",
    id:"2"
  },
  {
    image: Item3,
    dishname: "3. MOUNT SPECIAL SOUP ",
    dishitems:
      "Shrimps, small pieces of chicken fillet, lamb, mushrooms and noodles in creamy base.",
    price: "7,80€",
    reference:"AlacarteStarter",
    id:"3"
  },
  {
    image: Item4,
    reference:"AlacarteStarter",
    dishname: ["4. TOM YUM SOUP (G,L) ",<img src={Chilly1} height="30" width="33"></img>],
    dishitems:
      "Tom yum spicy soup of king prawn and mushrooms with Coconut milk.",
    price: "8,00€",
    reference:"AlacarteStarter",
    id:"4"
  },
  {
    image: Item5,
    dishname: "5. MIX VEG PAKORA (G,L) (Vegan) ",
    dishitems:
      "Deep-fried gram-batter with onions, potato, cauliflower, bracauli pieces. Served with mint chutney.",
    price: "7,00€",
    reference:"AlacarteStarter",
    id:"5"
  },
  {
    image: Item6,
    dishname: "6. PANEER PAKORA (G) ",
    dishitems:
      "Deep-fried gram-batter coated cottage cheese pieces. Served with mint chutney.",
    price: "7,00€",
    reference:"AlacarteStarter",
    id:"6"
  },
  {
    image: Item8,
    reference:"AlacarteStarter",
    dishname: [" 7. VEGETABLE SAMOSA (L) (Vegan)",<img src={Chilly1} height="30" width="33"></img>],
    dishitems:
      "Deep fried crispy pastry stuffed with vegetables, potatoes and green Peas seasoned with spices (2 pcs.). Served with tomato chutney.",
    price: "7,00€",
    reference:"AlacarteStarter",
    id:"7"
  },
  {
    image: Item7,
    reference:"AlacarteStarter",
    dishname: ["8. PANEER TIKKA (G) ",<img src={Chilly1} height="30" width="33"></img>],
    dishitems:
      "Fried cottage cheese saute with onions, Capsicum, tomato and yogurt sauce. Served with mint chutney.",
    price: "8,00€",
    reference:"AlacarteStarter",
    id:"8"
  },
  {
    image: Item18,
    dishname: " 9. Alu Chop (G,L) (Vegan)",
    dishitems:"Deep-fried potato patty. Served with tomato chutney.",
    price: "7,00€",
    reference:"AlacarteStarter",
    id:"18"

  },

  {
    image: Item9,
    dishname: "10. CHICKEN FINGERS (L) ",
    dishitems:
      "Chicken strips glazed and fried with breadcrumbs.       Deep fried Breadcrumbs coated chicken fillet strips.      ",
    price: "6,00€",
    reference:"AlacarteStarter",
    id:"9"
  },
  {
    image: Item10,
    dishname: " 11. CHICKEN PAKORA (G,L) ",
    dishitems:
      "Chicken fillet pieces coated and fried with lentil flour. Served with tomato chutney.  Deep fried gram batter coated chicken fillet pieces. Served with tomato chutney.",
    price: "7,80€",
    reference:"AlacarteStarter",
    id:"10"
  },
  {
    image: Item11,
    dishname: " 12. PRAWN PAKORA (G,L)",
    dishitems:
      "Deep fried prawns. Deep fried king prawns in gram-batter.      ",
    price: "8,00€",
    reference:"AlacarteStarter",
    id:"11"
  },
  {
    image: Item12,
    reference:"AlacarteStarter",
    dishname: [" 13. PRAWNS SANDEKO (G,L)",<img src={Chilly1} height="30" width="33"></img>],
    dishitems:
      "Spicy shrimps seasoned with lemon with chopped cucumbers, tomato and onion.   Lemon flavored spicy shrimps with chopped cucumber, tomato and onion.",
    price: "9,00€",
    reference:"AlacarteStarter",
    id:"12"
  },
  {
    image: Item13,
    reference:"AlacarteStarter",
    dishname: [" 14. KUKHURA SANDEKO (G)",<img src={Chilly1} height="30" width="33"></img>],
    dishitems:
      "Herb-yoghurt marinated, tandoori grilled and slightly     lemon-flavored chicken fillet strips with chopped cucumber, tomato and onion. Slightly lemon flavored tandoori-grilled, yoghurt-herb marinated chicken  Tenderloin strips with chopped cucumber, tomato and onion.",
    price: "7,80€",
    reference:"AlacarteStarter",
    id:"13"
  },
  {
    image: Item19,
    dishname: [" 15. Khasi Sadeko (G,L)", <img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
    dishitems:
      "Slightly lemon-flavoured tandoori-grilled, yoghurt-herb marinated lamb tenderloin pieces with chopped cucumber,tomato,and onion.",
    price: "10,00€",
    reference:"AlacarteStarter",
    id:"19"
  },
  {
    image: Item14,
    reference:"AlacarteStarter",
    dishname: [" 16. LAMB SHEIKH KABAB (G,L)",<img src={Chilly2} height="30" width="33"></img>],
    dishitems:
      "Tandoor-fried lamb chop in pepper and onion  chili sauce. Served with mint chutney.  Tandoor baked minced lamb meat, paprika and onion slices in chilli sauce.   Served with mint chutney",
    price: "10,00€",
    reference:"AlacarteStarter",
    id:"14"
  },
  {
    image: Item15,
    dishname: "17. TANDOORI CHICKEN SALAD (G) ",
    dishitems:
      "Salad with small chicken fillets,  lettuce, cucumber, tomato and paprika , marinated in herb-yoghurt and grilled in a tandoor oven .  Small pieces of tandoori-grilled yogurt-herb marinated chicken fillet, lettuce, cucumber, tomato and Capsicum.",
    price: "7,50€",
    reference:"AlacarteStarter",
    id:"15"
  },
  {
    image: Item16,
    dishname: "18. PRAWN SALAD (G,L)  ",
    dishitems:
      "spicy shrimp flavored with lemon digested cucumber, tomato, and onion   Lemon flavored with spicy shrimps chopped cucumber, tomato and onion",
    price: "8,00€",
    reference:"AlacarteStarter",
    id:"16"
  },
  {
    image: Item17,
    dishname: " 19. GREEN SALAD (G,L) (vegan)",
    dishitems:
      "Lettuce, cucumber, tomato, paprika. Lettuce, cucumber, tomato, Capsicum",
    price: "6,50€",
    reference:"AlacarteStarter",
    id:"17",
    dummy:[<p></p>,"Mild / Mild", <img src={Chilly1} alt ="medium" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt ="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,],

  },


  
];
export default Starter;
