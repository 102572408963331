import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Chilli1 from "../../../assets/FoodImg/C1.png"
import Chilli2 from "../../../assets/FoodImg/C2.png"

export const Monday = [
  {
    image: NoPicture,
    dishname:[ "1. Bhindi Masala (G,L) (VEGAN)",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
    id:"1",
    reference:"SecondResturant.Lunch.Monday",
      price: "9,00€",
  },
  {
    image: NoPicture,
    dishname: "2. SAG KOFTA (G,L)",
    reference:"SecondResturant.Lunch.Monday",
    id:"2",
    price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["3 . CHICKEN KARAHI(G,L)  ",<img src={Chilli2} alt="medium" height="30" width="33"></img>],
    reference:"SecondResturant.Lunch.Monday",
    id:"3",
    price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "4. BUTTER LAMB (G) ",
    reference:"SecondResturant.Lunch.Monday",
    id:"4",
    price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["5. PRAWN CHILLI (G, L) ",<img src={Chilli2} alt="medium" height="30" width="33"></img>],
    reference:"SecondResturant.Lunch.Monday",
    id:"5",
    price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["6. TODAY'S SPECIAL CHICKEN TIKKA (G)"],
    reference:"SecondResturant.Lunch.Monday",
    id:"6",
    price: "12,50€",
    dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
    "Kids Portion - 7,50€"],
    dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
      "G = Gluten free L = Lactose free", <p></p>,
      "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
    ],
  },
];

export const Tuesday = [
    {
        image: NoPicture,
        dishname: ["1. Sag Aalu (G,L) (VEGAN)"],
        price: "9,00€",
        id: "1",
        reference: "SecondResturant.Lunch.Tuesday"
    },
    {
        image: NoPicture,
        dishname: ["2. Paneer Masala (G)",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "10,00€",
        id: "2",
        reference: "SecondResturant.Lunch.Tuesday"
    },
    {
        image: NoPicture,
        dishname: ["3. Butter Chicken (G)"],
        price: "10,00€",
        id: "3",
        reference: "SecondResturant.Lunch.Tuesday"
    },
    {
        image: NoPicture,
        dishname: ["4. Lamb Chilly (G,L)",<img src={Chilli2} alt="medium" height="30" width="33"></img>],
        price: "10,00€",
        id: "4",
        reference: "SecondResturant.Lunch.Tuesday"
    },
    {
        image: NoPicture,
        dishname: ["5. Fish Korma (G)"],
        price: "10,00€",
        id: "5",
        reference: "SecondResturant.Lunch.Tuesday"
    },
    {
        image: NoPicture,
        dishname: ["6. Today's Special Kukhura Choila (G,L) ",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "12,50€",
        id: "6",
        reference: "SecondResturant.Lunch.Tuesday",
        dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
        "Kids Portion - 7,50€"],
        dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
          "G = Gluten free L = Lactose free", <p></p>,
          "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
        ],
    },

];
export const Wednesday = [
    {
        image: NoPicture,
        dishname: ["1. Sag Tofu (G,L) (Vegan)"],
        price: "9,00€",
        id: "1",
        reference: "SecondResturant.Lunch.Wednesday"
    },
    {
        image: NoPicture,
        dishname: ["2. Malai Kofta (G) "],
        price: "10,00€",
        id: "2",
        reference: "SecondResturant.Lunch.Wednesday"
    },
    {
        image: NoPicture,
        dishname: ["3. Chicken Tikka Masala (G) ",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "10,00€",
        id: "3",
        reference: "SecondResturant.Lunch.Wednesday"
    },
    {
        image: NoPicture,
        dishname: ["4. Lamb Curry (G,L)"],
        price: "10,00€",
        id: "4",
        reference: "SecondResturant.Lunch.Wednesday"
    },
    {
        image: NoPicture,
        dishname: ["5. Prawn Masala (G,L) ", <img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "10,00€",
        id: "5",
        reference: "SecondResturant.Lunch.Wednesday"
    },
    {
        image: NoPicture,
        dishname: ["6. Today's Sepecial Bhale Karai Special (G) ",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "12,50€",
        id: "6",
        reference: "SecondResturant.Lunch.Wednesday",
        dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
        "Kids Portion - 7,50€"],
        dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
          "G = Gluten free L = Lactose free", <p></p>,
          "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
        ],
    },

];
export const Thursday = [
    {
        image: NoPicture,
        dishname: ["1. Alu Govi (G,L) ",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "9,00€",
        id: "1",
        reference: "SecondResturant.Lunch.Thursday"
    },
    {
        image: NoPicture,
        dishname: ["2. Sag Paneer (G) "],
        price: "10,00€",
        id: "2",
        reference: "SecondResturant.Lunch.Thursday"
    },
    {
        image: NoPicture,
        dishname: ["3. Chicken Paneer (G) "],
        price: "10,00€",
        id: "3",
        reference: "SecondResturant.Lunch.Thursday"
    },
    {
        image: NoPicture,
        dishname: ["4. Lamb Kofta Masala (G,L)",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "10,00€",
        id: "4",
        reference: "SecondResturant.Lunch.Thursday"
    },
    {
        image: NoPicture,
        dishname: ["5. Shahi Nariwal Prawn (G)"],
        price: "10,00€",
        id: "5",
        reference: "SecondResturant.Lunch.Thursday"
    },
    {
        image: NoPicture,
        dishname: ["6. Today's Special Khasi Koseli (G)"],
        price: "14,00€",
        id: "6",
        reference: "SecondResturant.Lunch.Thursday",
        dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
        "Kids Portion - 7,50€"],
        dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
          "G = Gluten free L = Lactose free", <p></p>,
          "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
        ],
    },

];
export const Friday = [
    {
        image: NoPicture,
        dishname: ["1. Tofu Chilli (G,L) ",<img src={Chilli2} alt="medium" height="30" width="33"></img>],
        price: "9,00€",
        id: "1",
        reference: "SecondResturant.Lunch.Friday"
    },
    {
        image: NoPicture,
        dishname: ["2. Shahi Paneer (G)"],
        price: "10,00€",
        id: "2",
        reference: "SecondResturant.Lunch.Friday"
    },
    {
        image: NoPicture,
        dishname: ["3. Butter Chicken (G)"],
        price: "10,00€",
        id: "3",
        reference: "SecondResturant.Lunch.Friday"
    },
    {
        image: NoPicture,
        dishname: ["4. Garlic Khasi Karahi (G)",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "10,00€",
        id: "4",
        reference: "SecondResturant.Lunch.Friday"
    },
    {
        image: NoPicture,
        dishname: ["5. Machha Sag (G) "],
        price: "10,00€",
        id: "5",
        reference: "SecondResturant.Lunch.Friday"
    },
    {
        image: NoPicture,
        dishname: ["6. Today's Special Tandoori Mix Masala (G)",<img src={Chilli1} alt="mild" height="30" width="33"></img>],
        price: "14,00€",
        id: "6",
        reference: "SecondResturant.Lunch.Friday",
        dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
        "Kids Portion - 7,50€"],
        dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
          "G = Gluten free L = Lactose free", <p></p>,
          "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
        ],
    },

];
