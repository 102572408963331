import Chowmein from "../../../assets/FoodImg/Chowmein.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"



const Noodles
= [
  {
    image: Chowmein,
    dishname:[ "VEG CHOWMEIN (L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteNoodles",
    id:"1",
      price: "14,90€",
  },
  {
    image: Chowmein,
    dishname: ["MIX CHOWMEIN (L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteNoodles",
    id:"2",
    price: "16,90€",
        dummy:["Does not include rice and naan bread / Rice and naan bread is not included.",<br></br>,
        "Mild / Mild", <img src={Chilly1} alt="mild" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="spicy" height="30" width="33"></img>,],
  },
  
];
export default Noodles;
