import React from "react";
import styled from "styled-components";
import { Container } from "@material-ui/core";

const DrinkMenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const DrinkHeader = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
`;
const DrinkDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;
const IndItem = styled.div`
width:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .individual-item {
    display: flex;
    justify-content: space-between;
    line-height: 3;
  }

  ul {
      padding:0;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;

  .detail {
    font-size: 0.8rem;
    line-height: 0.3;
    text-align: start;
    color: #0d0d0d;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ItemPrice = styled.div``;
const DrinkMenuItem = (props) => {
  return (
    <Container maxWidth="lg">
      <DrinkMenuItemContainer key={props.id}>
        <DrinkHeader>{props.dummyheader}</DrinkHeader>
        <DrinkDetail>
          <IndItem>
            <div className="item-name">
              <ul>
                <li className="individual-item">
                  <ItemDetail>
                    <div> {props.dummy[0]}</div>
                    <div className="detail">{props.detail[0]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[0]}</ItemPrice>
                </li>
                <li className="individual-item">
                  <ItemDetail>
                    <div> {props.dummy[1]}</div>
                    <div className="detail">{props.detail[1]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[1]}</ItemPrice>{" "}
                </li>
                <li className="individual-item">
                  <ItemDetail>
                    <div> {props.dummy[2]}</div>
                    <div className="detail">{props.detail[2]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[2]}</ItemPrice>{" "}
                </li>
                <li className="individual-item">
                  <ItemDetail>
                    <div> {props.dummy[3]}</div>
                    <div className="detail">{props.detail[3]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[3]}</ItemPrice>{" "}
                </li>
                <li className="individual-item">
                  <ItemDetail>
                    <div> {props.dummy[4]}</div>
                    <div className="detail">{props.detail[4]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[4]}</ItemPrice>{" "}
                </li>
                <li className="individual-item">
                  <ItemDetail>
                    <div>{props.dummy[5]}</div>
                    <div className="detail">{props.detail[5]}</div>
                  </ItemDetail>
                  <ItemPrice>{props.price[5]}</ItemPrice>{" "}
                </li>
              </ul>
            </div>
          </IndItem>
        </DrinkDetail>
      </DrinkMenuItemContainer>
    </Container>
  );
};

export default DrinkMenuItem;

/**
 * 
 * 
 const DummyHeader = styled.div`
   font-size: 1.2rem;
   font-weight: 700;
   margin: 0;
 `;
 const DummyData = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
   padding: 0 0.5rem;
   `;


  <div className="container">
      <DummyHeader>
        <p className="dummy-header">{props.dummyheader}</p>
      </DummyHeader>
      <DummyData>
        <p className="dummy-content">
        {props.dummy}

        {props.price ? <p className="dummy-price">{props.price}</p> : null}
        </p>
      </DummyData>
    </div>
 */
