import NoPicture from "../../../assets/FoodImg/Picture1.png";
import ButterChicken from "../../../assets/FoodImg/ButterChicken.png";
import ChickenTikkaMasala from "../../../assets/FoodImg/ChickenTikkaMasala.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


const TandooriDishesChicken =
[
  {
    image: NoPicture,
    dishname: "38. CHICKEN SAAG KARAHI (G)",
    reference:"AlacarteTandooriChicken",
    id:"1",
    price: "17,50€",
  },
  {
    image: NoPicture,
    dishname: ["39. ​​CHICKEN CHILLI (G, L)", <img src={Chilly2} height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"2",
      price: "16,50€",
  },
  {
    image: NoPicture,
    dishname: ["40. KUKHURA CHHOILA (G, L)" , <img src={Chilly2} height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"3",
      price: "17,00€",
  },
  {
    image: NoPicture,
    dishname: "41. CHICKEN TIKKA (G)  ",
    reference:"AlacarteTandooriChicken",
    id:"4",
      price: "16,80€",
  },
  {
    image: NoPicture,
    dishname: ["42. CHICKEN SASLIK (G) ",<img src={Chilly1} alt = "medium" height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"5",
      price: "17,80€",
  },
  {
    image: ChickenTikkaMasala,
    dishname: ["43. CHICKEN TIKKA MASALA (G) ", <img src={Chilly2} alt = "medium" height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"6",
      price: "16,50€",
  },
  {
    image: NoPicture,
    dishname: ["44. CHICKEN BUTTER MASALA (G) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"7",
      price: "19,00€",
  },
  {
    image: NoPicture,
    dishname: ["45. BHALE KARAI SPECIAL (G)", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteTandooriChicken",
    id:"8",
      price: "18,50€",
  },
  {
    image: ButterChicken,
    dishname: "46. BUTTER CHICKEN (G)",
    reference:"AlacarteTandooriChicken",
    id:"9",
      price: "16,80€",
  },
  {
    image: NoPicture,
    dishname: "47. MOUNT SPECIAL CHICKEN (G) ",
    reference:"AlacarteTandooriChicken",
    id:"10",
      price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "48. BUTTER GARLIC TIKKA (G) ",
      reference:"AlacarteTandooriChicken",
      id:"11",
        price: "19,00€",
    },
  {
    image: NoPicture,
    dishname: "49. BHALE MALAI TIKKA (G)",
    reference:"AlacarteTandooriChicken",
    id:"12",
      price: "18,90€",
  },
  {
    image: NoPicture,
    dishname: ["50.CHICKEN SHIRAZ (G)"],
    reference:"AlacarteTandooriChicken",
    id:"13",
      price: "18,90€",
      dummy:[
      
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
  
  },
];

export default TandooriDishesChicken;
