import React, { useEffect } from "react";
import OurStory from "../components/OurStory";
import Mountnepal1 from "../assets/images/Mountnepal1.jpg";
import FooterBkg from "../assets/images/footerbkg.jpg";
import HomeMenu from "../components/HomeMenu";
import LandingPage from "../components/LandingPage";
import FormPage from "../components/ReservationPage/FormPage";
import SpecialOffer from "../components/Offer/SpecialOffer"
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ExquisiteCuisineMin from "../components/ExquisiteCuisineMin";
import ExquisiteCuisineBtn from "../components/ExquisiteCuisineBtn";
import RestaurantClosed from "../components/RestaurantClosedPopup/RestaurantClosed";
import axios from "axios";
import { useTranslation } from 'react-i18next';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
  },
}));

const HomePage = () => {
  const { t } = useTranslation();
  const [fix, setfix] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [close, setClose] = React.useState(false);
  const [offerdetail,setOfferDetails] =React.useState(null);

 const url = "http://"+window.location.hostname+"/api/offer";
 const status_url = "http://"+window.location.hostname+"/api/status/1";
// const status_url = "http://localhost:4000/status/1";
  
// const url = "http://localhost:4000/offer";

  const fetchData = () => {
   

    axios.get(status_url).then((response) => {
     
      if (response.data[0].status === "close") {
        setOpen(false);
        setClose(true);
        setfix(true);
      } else {
        

        setClose(false);
        setfix(false);
        axios.get(url).then((response) => {
         
        
          let i;
        for(i=0; i<(response.data).length; i++){
          if (response.data[i].status === "true") {
            setOfferDetails(response.data[i]);
            setOpen(true);
            setfix(true);
          } else {
            setOpen(false);
            setfix(false);
          }
          

        }
         
          
        });
      }
    });

  };

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const handleClose = () => {
    setOpen(false);
    setClose(false);
  };

  useEffect(() => {
    fetchData();
  }, [2]);

  return (
    <>
      <Modal open={fix} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <SpecialOffer open={open} setOpen={setOpen} setfix={setfix} offerdetail={offerdetail} />
          {/* <ClosedPopup open={close} setOpen={setClose} setfix={setfix} />
           */}
           <RestaurantClosed open={close} setOpen={setClose} setfix={setfix} />
        </div>
      </Modal>

      <LandingPage />
      <OurStory />
      <ExquisiteCuisineBtn
        title="Namaste"
        head={t('Home.welcomenote')}
        image={Mountnepal1}
        divider={true}
        button="true"
      />
      <HomeMenu />
      <ExquisiteCuisineMin
        title={t('Home.visitus')}
        head={t('Home.enc')}
        image={FooterBkg}
        divider={true}
      />
      <FormPage />
    </>
  );
};

export default HomePage;
