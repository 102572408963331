import React from "react";
import "./About.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import RemoveIcon from "@material-ui/icons/Remove";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FruitsImage from "./img/foods.jpg";
import VisitUs from "../VisitUsPage/VisitUs";
import ourstory from "../../assets/images/ourstory.png";
import FormPageFlat from "../ReservationPage/FlatReservePage";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
    <div className="About">
      <Container fixed>
        <Grid container className="my-4">
          <Grid item md={6} className="p-4" style={{ margin: "auto" }}>
            <img
              src={ourstory}
              alt="about us"
              className="imagee"
            />
          </Grid>
          <Grid item md={6} sm={12} className="p-4">
            <div className="customHeaderContainer">
              <h3>
                <span className="customMainHeader">
                  {t("AboutOurStory.title")}
                </span>
              </h3>
              <h3 className="customSubHeader">{t("AboutOurStory.title")}</h3>
            </div>
            <div className="aboutDivider text-center">
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />{" "}
              <FiberManualRecordIcon
                style={{
                  color: "#b48c80",
                  fontSize: "12px",
                }}
              />{" "}
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />
            </div>
            {/*<div className="blockquote aboutquote my-3">
              <p>"There is no sincerer love than the love of food"</p>
              <figcaption className="blockquote-footer">
                <cite title="Source Title author">George Bernard Shaw</cite>
              </figcaption>
            </div>
              */}
            <p className="blockquoteText text-center mt-4 p-3">
              {t("AboutOurStory.descriptionFirstParagraph")}
            </p>
            <p className="blockquoteText p-3 nmt">
              {t("AboutOurStory.descriptionSecondParagraph")}
            </p>
            <p className="blockquoteText text-center p-3 nmt">
              {t("AboutOurStory.descriptionThirdParagraph")}
            </p>
          </Grid>
        </Grid>
      </Container>
      <Container fixed>
        <Grid container className="my-4">
          <Grid item md={6} sm={12} className="p-4">
            <div className="customHeaderContainer">
              <h3>
                <span className="customMainHeader">{t('About.ourApproach')}</span>
              </h3>
              <h3 className="customSubHeader">{t('About.afi')}</h3>
            </div>
            <div className="aboutDivider text-center">
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />{" "}
              <FiberManualRecordIcon
                style={{
                  color: "#b48c80",
                  fontSize: "12px",
                }}
              />{" "}
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />
            </div>
            <p className="blockquoteText text-center my-4 p-3">
              {t("AboutOurApproach.description")}
            </p>
          </Grid>
          <Grid item md={6} sm={12} className="p-4">
            <img
              src={FruitsImage}
              alt="about us"
              className="imgAbout"
              style={{ float: "right" }}
            />
          </Grid>
        </Grid>
      </Container>
      {/*<ExquisiteCuisine
        image={AboutBkg2}
        title="Indoor & Outdoor dining area"
        head="ENJOYABLE & COMFORTABLE"
        divider="true"
      />*/}
      {/*
      <Container fixed>
        <Grid container className="my-4">
          <Grid item xs={12} md={6} className="p-4">
            <img
              src="https://images.unsplash.com/photo-1512765233674-5a197d66dd74?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80"
              alt="about us"
              className="imgAbout"
            />
          </Grid>
        
          <Grid item xs={12} md={6} className="p-4">
            <div className="customHeaderContainer">
              <h3>
                <span className="customMainHeader">Indoor</span>
              </h3>
              <h3 className="customSubHeader">Warm Atmosphere</h3>
            </div>
            <div className="aboutDivider text-center">
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />{" "}
              <FiberManualRecordIcon
                style={{
                  color: "#b48c80",
                  fontSize: "12px",
                }}
              />{" "}
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />
            </div>

            <p className="blockquoteText text-center my-4 p-3">
            Ravintola mount Nepal is open seven days a week for lunch and dinner, and is an ideal location 
            for casual dining, as well as social gatherings. We are also available for every size party and occasion.
             Reservation can be made via website or phone. 
            A la carte is available on weekdays from 15:00 to 21:00 and on weekend from 12:00 to 21:00 . 
            </p>
            <div className="text-center">
              <p className="findTable">
                Find a table{" "}
                <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
                <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container fixed>
        <Grid container className="my-4">
          <Grid item md={6} xs={12} className="p-4">
            <div className="customHeaderContainer">
              <h3>
                <span className="customMainHeader">Outdoor</span>
              </h3>
              <h3 className="customSubHeader">Natural Surrounding</h3>
            </div>
            <div className="aboutDivider text-center">
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />{" "}
              <FiberManualRecordIcon
                style={{
                  color: "#b48c80",
                  fontSize: "12px",
                }}
              />{" "}
              <RemoveIcon
                style={{
                  color: "#b48c80",
                  fontSize: "35px",
                }}
              />
            </div>
            <p className="blockquoteText text-center my-2 p-3">
              Enjoy your dining at Royal plate terrace, surrounded by natural
              wooden fence and plenty of green. Royal plate terrace offers 72
              seating places which can be rearranged for large groups and
              special events like weddings, corporate parties or baby showers.
              Royal view of the Palma bay in the sunset will take your breath
              away and make your stay truly royal. You can also visit our
              terrace bar where our professional staff will offer you our best
              vines or cocktails, according to your wishes.
            </p>
            <div className="text-center">
              <p className="findTable">
                Find a table{" "}
                <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
                <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
              </p>
            </div>
          </Grid>
          <Grid item md={6} xs={12} className="p-4">
            <img
              src={outdoorImage}
              alt="about us"
              className="imgAbout"
              style={{ float: "right" }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container fixed className="my-4">
        <Grid container>
          <Grid item md={4} sm={12}>
            <div className="memberTeam my-2">
              <figure>
                <img src={Person1} alt="person-1" />
                <figcaption>
                  <h4 className="memberName my-2">Keith Simons</h4>
                  <p className="memberPosition my-2">Chef de cousine</p>
                  <p className="memberAbout px-4">
                    Meet Keith Simmons, chef de cousine in our restaurant. Keith
                    has 10 years of experience in restaurant business. His
                    favorite food is beaf steak.
                  </p>
                </figcaption>
              </figure>
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className="memberTeam my-2">
              <figure>
                <img src={Person2} alt="person-2" />
                <figcaption>
                  <h4 className="memberName my-2">ASHLEY COOPER</h4>
                  <p className="memberPosition my-2">Head waiter</p>
                  <p className="memberAbout px-4">
                    Ashley Cooper is head waiter in Royal Plate and an
                    experienced vine taster. Her favorite vine is Chardonnay.
                  </p>
                </figcaption>
              </figure>
            </div>
          </Grid>
          <Grid item md={4} sm={12}>
            <div className="memberTeam my-2">
              <figure>
                <img src={Person3} alt="person-3" />
                <figcaption>
                  <h4 className="memberName my-2">PHILIP SALAZAR</h4>
                  <p className="memberPosition my-2">Deserts expert</p>
                  <p className="memberAbout px-4">
                    Philip Salazar is our expert for deserts. Philip has 12
                    years of experience and enjoy in creating new deserts.
                    Favorite food: Sacher cake
                  </p>
                </figcaption>
              </figure>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="aboutPageContent">
        <div className="fancyTestimonial">
          <div className="caurosalContainer">
            <FancyCarousal />
          </div>
        </div>
      </div>
  */}
      <VisitUs />
      <FormPageFlat />
    </div>
  );
}
export default About;
