import React, { useEffect } from "react";
import styled from "styled-components";
import Divider from "./Divider";
import Aos from "aos";
import "aos/dist/aos.css";
import Button from "./button";
import { useTranslation } from 'react-i18next';

const CuisineContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-family: "Montez", sans-serif;
    font-size: 4rem;
    color: #fcdb9b;
    text-align: center;
  }
  .feast {
    font-family: "Suranna", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  @media (max-width: 1024px) {
    height: 50vh;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 3rem;
    }
    .feast {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
`;

const ImageContainer = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
`;

const ButtonSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .exbutton1 {
    padding-right: 3rem;
  }
  .exbutton2 {
    padding-left: 3rem;
  }

  .cusbtn {
    text-decoration: none;
    color: #393939;
  }

  @media (max-width: 480px) {
    .exbutton1,
    .exbutton2 {
      padding-right: 0.1rem;
      padding-left: 0.1rem;
    }
  }
`;
const ExquisiteCuisineBtn = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 100 });
  }, []);

  return (
    <CuisineContainer>
      <ImageContainer src={props.image} alt="cuisine" />
      <p className="title" data-aos="fade-up">
        {props.title}
      </p>
      <p className="feast" data-aos="fade-up">
        {props.head.toUpperCase()}
      </p>
      {props.divider ? <Divider /> : null}
      <>
        {props.button ? (
          <ButtonSectionWrapper>
            <div className="exbutton1">
              <Button to="reservation" className="cusbtn" borderradius="true">
                {t('Home.Book a table')}
              </Button>
            </div>
            <div className="exbutton2">
              <Button to="menu" className="cusbtn" borderradius="true">
                {t('Home.gtm')}
              </Button>
            </div>
          </ButtonSectionWrapper>
        ) : null}
      </>
    </CuisineContainer>
  );
};

export default ExquisiteCuisineBtn;
