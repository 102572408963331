import React from "react";
import styled from "styled-components";
import FooterBkg from "../assets/images/footerbkg.jpg";
import Logo from "../assets/images/Picture1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import foodora from "../assets/logo/foodora.png";
import eat from "../assets/logo/eat.png";
import tripadvisor from "../assets/logo/tripadvisor.png";
import wolt from "../assets/logo/wolt.png";
import { useTranslation } from "react-i18next";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const FooterContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
      10deg,
      rgba(29, 29, 29, 0.95) 15%,
      rgba(243, 147, 16, 0.8)
    ),
    url(${FooterBkg}) center/cover no-repeat border-box;
  position: relative;

  hr {
    color: #d3d3d3;
  }
`;

const FooterContentSection = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 1.8;

  .footer-logo{
    @media (max-width: 1024px){
      height: auto;
      width: 37%;
    }
  }
  .address {
    color: #cbcbcb;
    @media (max-width: 480px) {
      margin: 0;
    }
  }
  .number {
    font-size: 1.2rem;
    @media (max-width: 480px) {
      margin: 0;
    }
  }

  .weekday,
  .weekend {
    color: #cbcbcb;
    font-size: 0.8rem;

    @media (max-width: 480px) {
      margin: 0;
    }
  }

  .working-hour {
    color: #fccb13;
    font-size: 0.9rem;
    @media (max-width: 480px) {
      margin: 0;
    }
  }
`;

const FooterCopyrightSection = styled.div`
  position: absolute;
  bottom: 2%;
  left: 5%;
  color: #cbcbcb;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 0.75rem;
    text-align: center;
    left: 0;
  }
`;
const FoodLogo = styled.div`
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%, -8%);

  img {
    height: 70px;
  }

  .wolt img {
    height: 140px;
    margin-left: -5rem;
  }
  .eat img {
    margin-left: -5rem;
  }
  .tripadvisor img {
    margin-left: -5rem;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    left: 5%;
    bottom: 25%;
    .wolt img,
    .eat img,
    .tripadvisor img {
      margin: 0;
    }
    .wolt img {
      height: 150px;
      width: 150px;
      margin: -3rem -10px;
    }
  }
  @media (max-height: 600px) {
    height: 125px;
    bottom: 0%;
  }
  @media (max-width: 480px) {
    top: 10%;
    left: 5%;

    img {
      height: 50px;
    }
    .wolt img {
      height: 95px;
      width: 95px;
      margin: -1.7rem 0;
    }
  }
`;
const SocialIcons = styled.div`
position:absolute;
bottom:15%;
left:5%;
  a {
    color: #fff;
    margin: 0 0.5rem;
    font-size: 0.7rem;
    transition: transform .2s;
  }
  a:hover {
    transform: translateY(-3px);
  }

  .facebook {
    color: #0c88ef;
  }

  @media (max-width:768px){
    position:relative;
    bottom:0;
    left:0;
    padding-top:2rem;
  }
`;
const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <FooterContainer>
        <FooterContentSection>
          <img src={Logo} className = "footer-logo" height="40%" width="auto" gitalt="company_logo" />
          <p className="address">Kaupinkalliontie 7, 02100 Espoo</p>
          <p className="number">
            <span style={{ color: "#FCCB13", fontSize: "1rem" }}>
              {t('Footer.ReservationPhoneNumber')}:
            </span>{" "}
            09 463400
          </p>
          <p className="working-hour">{t('Footer.openingHours')}:</p>
          <p className="weekday">{t('Footer.mTf')}: 10:30 AM - 22:00 PM</p>
          <p className="weekend">{t('Footer.weekends')}: 12:00 AM - 22:00 PM</p>
          <SocialIcons>
            <a
              href="https://www.facebook.com/Ravintola.MountNepal/"
              target="_blank"
              className="facebook social"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>

           {/* <a href="#" className="instagram social">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
  </a>*/}
          </SocialIcons>
        </FooterContentSection>
        <FoodLogo>
          <a
            href="https://www.foodora.fi/restaurant/s0fh/mount-nepal?r=1"
            target="_blank"
            className="foodora"
          >
            <img src={foodora} height="100px" />
          </a>
          <a
            href="https://wolt.com/fi/fin/espoo/restaurant/mount-nepal"
            target="_blank"
            className="wolt"
          >
            <img src={wolt} height="200px" />
          </a>
          <a
            href="https://eat.fi/fi/espoo/ravintola-mount-nepal"
            target="_blank"
            className="eat"
          >
            <img src={eat} height="100px" />
          </a>
          <a
            href="https://www.tripadvisor.fi/Restaurant_Review-g189932-d8134009-Reviews-Mount_Nepal-Espoo_Uusimaa.html"
            target="_blank"
            className="tripadvisor"
          >
            <img src={tripadvisor} height="100px" />
          </a>
        </FoodLogo>
        <hr />
        <FooterCopyrightSection>
          © Ravintola Mount Nepal 2020. All rights reserved.
        </FooterCopyrightSection>
      </FooterContainer>
    </>
  );
};

export default Footer;
