import { MDBContainer, MDBBtn } from "mdbreact";
import React, { useState } from "react";
import "./contactUs.css";
import styled from "styled-components";
import Divider from "../ReusableComponents/Divider";
import emailjs from "emailjs-com";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  customAlert: {
    width: "175px",

    [theme.breakpoints.down("sm")]: {
      width: "175px",
    },
  },
}));

const ContactUsComponent = styled.div`
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AlertButtonContainer = styled.div`
  position: relative;
  .btn-black {
    margin-top: 4rem;
  }

  @media (max-width: 768px) {
    .btn-black {
      margin-top: 4rem;
    }
  }
  .contact-alert {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);

    @media (max-width: 768px) {
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
`;
const ContactUs = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sendStatus, setSendStatus] = useState(false);
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_b16ju7r",
        "template_yyyo12b",
        e.target,
        "user_YeuN6T3Tdb15Cfp7QkGII"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSendStatus(true);
        },
        (error) => {
          console.log(error.text);
          setSendStatus(false);
        }
      );
    e.target.reset();
  }

  return (
    <ContactUsComponent>
      <MDBContainer className="contact-all">
        <form className="heading" onSubmit={sendEmail}>
          <HeadContent>
            <div className="question-us">{t('Contact.question')}</div>
            <div className="contact">{t('Contact.contactUs')}</div>
            <Divider />
          </HeadContent>
          <div className="phone">
            {" "}
            Phone :<span className="number">(358) 0503034503</span>
          </div>
          <FormContent>
            <div class="form-outline col-lg-6 col-sm-12">
              <input
                type="text"
                id="typeText"
                class="form-control"
                placeholder="Your Name"
                name="name"
                required
              />
              <label class="form-label" for="typeText"></label>

              <div class="form-outline ">
                <input
                  type="email"
                  id="typeEmail"
                  class="form-control"
                  placeholder="Your Email"
                  name="email"
                  required
                />
                <label class="form-label" for="typePhone"></label>
              </div>

              <div class="form-outline">
                <input
                  type="phone"
                  id="typePhone"
                  class="form-control"
                  placeholder="Phone No."
                  name="phone"
                  required
                />
                <label class="form-label" for="typePhone"></label>
              </div>
            </div>
            <div class="form-outline col-lg-6 col-sm-12">
              <textarea
                class="form-control"
                id="textAreaExample"
                rows="4"
                placeholder="Message"
                name="message"
                required
              ></textarea>
              <label class="form-label" for="textAreaExample"></label>
            </div>
            <AlertButtonContainer>
              {sendStatus ? (
                <div className="contact-alert">
                  <div className={classes.customAlert}>
                    <Alert variant="outlined" severity="success">
                      Message Sent
                    </Alert>
                  </div>
                </div>
              ) : null}
              <MDBBtn color="black" type="submit" value="Send">
                SEND
              </MDBBtn>
            </AlertButtonContainer>
          </FormContent>
        </form>
      </MDBContainer>
    </ContactUsComponent>
  );
};
export default ContactUs;
