import React, { useState } from "react";
import { MDBContainer, MDBInput, MDBBtn } from "mdbreact";
import Divider from "../Divider";
import emailjs from "emailjs-com";
import { Formik } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import styled, { css } from "styled-components";
import {
  FormControl,
  Button,
  TextField,
  Container,
  Grid,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import axios from "axios";

const FormContainer = styled.div`
  background: ${({ sendStatus }) => {
    if (sendStatus) return "linear-gradient(45deg,#dddddd ,#fff 70%)";
    else return "none";
  }};
`;
const AlertContainer = styled.div`
  min-height: 55vh;
  height: auto;
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 20vh;
    width: 90%;
  }
`;
const FlatReserveContainer = styled.div`
  .custom_cont {
    padding: 0 0 2rem ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }
  .form-control {
    margin-bottom: 1em;
  }
  label {
    display: inline-block;
    margin: 3px 0;
    font-size: 1em;
  }
`;

const HeadContentSection = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom:1rem;

  .book-table{
    text-transform: uppercase;
  }
`;

const startDay = new Date().toLocaleDateString("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const endDay = new Date(Date.now() + 6.048e8 * 2).toLocaleDateString("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
console.log(startDay);

// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const timeRegExp = /(([01]?[0-9]):([0-5][0-9]) ([AaPp][Mm]))/;

// Schema for yup
const validationSchema = Yup.object().shape({
  customerName: Yup.string()
    .min(3, "*Name is Invalid")
    .max(100, "*Name is Invalid")
    .required("*Name is required"),
  customerEmail: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Invalid")
    .required("*Email is required"),
  customerDate: Yup.string(),
  // time: Yup.string().matches(
  //   timeRegExp,
  //   "doesn't match with restaurant opening and closing time"
  // ),
  customerDuration: Yup.string(),
  customerPeople: Yup.number()
    .min(1, "*Invalid")
    .max(10, "*For more than 10, please call on 09 463400"),
  customerPhone: Yup.string()
    .min(5, "*Invalid")
    .max(15, "*Invalid")
    .matches(phoneRegExp, "*Phone number is not valid")
    .required("*Phone number required"),
});
const FormPageFlat = () => {
  const { t } = useTranslation();
  const [sendStatus, setSendStatus] = React.useState(false);

  function sendEmail(values) {

    const url = "http://"+window.location.hostname+"/api/sendmail";
  
     
  
    
       axios.post(url, { name: values.name, email: values.email, date: values.date , time:values.time, people: values.people,duration:values.duration,phone:values.phone})
       .then((response) => {
  
        setSendStatus(true);
        
         
         
      
     
    });
  } return (
    <FormContainer sendStatus={sendStatus}>
      {sendStatus ? (
        <AlertContainer>
          <div className="customAlert">
            <Alert variant="outlined" severity="success">
              Thank you for submitting your request for reservation. Someone
              from Mount Nepal will confirm your request.
            </Alert>
          </div>
        </AlertContainer>
      ) : (
        <div className="formFlatContainer">
          <FlatReserveContainer>
            <MDBContainer className="container custom_cont">
              <HeadContentSection>
                <p className="reservation">{t('Home.reservation')}</p>
                <p className="book-table">
                  <b>{t('Home.byt')} </b>{" "}
                </p>
                <Divider />
              </HeadContentSection>

              <Formik
                initialValues={{
                  customerName: "",
                  customerEmail: "",
                  customerDate: "",
                  customerTime: "",
                  customerDuration: "",
                  customerPeople: "",
                  customerPhone: "",
                }}
                // Hooks up our validationSchema to Formik
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  sendEmail(values)
                }}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit} id="reservation-form">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control">
                          {formik.touched.customerName &&
                          formik.errors.customerName ? (
                            <TextField
                              error
                              id="customerName"
                              label={formik.errors.customerName}
                              name="customerName"
                              variant="outlined"
                              {...formik.getFieldProps("customerName")}
                              size="small"
                              required
                            />
                          ) : (
                            <TextField
                              id="customerName"
                              label="Your Name"
                              name="customerName"
                              variant="outlined"
                              {...formik.getFieldProps("customerName")}
                              size="small"
                              required
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          {formik.touched.customerEmail &&
                          formik.errors.customerEmail ? (
                            <TextField
                              error
                              id="customerEmail"
                              label={formik.errors.customerEmail}
                              name="customerEmail"
                              variant="outlined"
                              {...formik.getFieldProps("customerEmail")}
                              size="small"
                              required
                            />
                          ) : (
                            <TextField
                              id="customerEmail"
                              label="Email"
                              name="customerEmail"
                              variant="outlined"
                              {...formik.getFieldProps("customerEmail")}
                              size="small"
                              required
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          {formik.touched.customerDate &&
                          formik.errors.customerDate ? (
                            <TextField
                              variant="outlined"
                              error
                              InputProps={{
                                inputProps: { min: startDay, max: endDay },
                              }}
                              type="date"
                              id="outlined-basic"
                              name="customerDate"
                              label={formik.errors.customerDate}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...formik.getFieldProps("customerDate")}
                              required
                            />
                          ) : (
                            <TextField
                              variant="outlined"
                              InputProps={{
                                inputProps: { min: startDay, max: endDay },
                              }}
                              type="date"
                              id="outlined-basic"
                              name="customerDate"
                              label="Date"
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              {...formik.getFieldProps("customerDate")}
                              required
                            />
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control">
                          {formik.touched.customerTime &&
                          formik.errors.customerTime ? (
                            <TextField
                              variant="outlined"
                              error
                              type="time"
                              id="outlined-basic"
                              name="customerTime"
                              label={formik.errors.customerTime}
                              InputLabelProps={{ shrink: true }}
                              size="small"
                              {...formik.getFieldProps("customerTime")}
                              required
                            />
                          ) : (
                            <TextField
                              select
                              variant="outlined"
                              type="time"
                              id="outlined-basic"
                              onkeypress="return isNumber(event)"
                              name="customerTime"
                              label="Time"
                              InputLabelProps={{ shrink: true }}
                              size="small" 
                              {...formik.getFieldProps("customerTime")}
                              required
                            >
                              
                              <MenuItem value={10.3}>10:30</MenuItem>
                              <MenuItem value={10.45}>10:45</MenuItem>
                              <MenuItem value={11.0}>11:00</MenuItem>
                              <MenuItem value={11.15}>11:15</MenuItem>
                              <MenuItem value={11.3}>11:30</MenuItem>
                              <MenuItem value={11.45}>11:45</MenuItem>
                              <MenuItem value={12.0}>12:00</MenuItem>
                              <MenuItem value={12.15}>12:15</MenuItem>
                              <MenuItem value={12.3}>12:30</MenuItem>
                              <MenuItem value={12.45}>12:45</MenuItem>
                              <MenuItem value={13.0}>13:00</MenuItem>
                              <MenuItem value={13.15}>13:15</MenuItem>
                              <MenuItem value={13.3}>13:30</MenuItem>
                              <MenuItem value={13.45}>13:45</MenuItem>
                              <MenuItem value={14.0}>14:00</MenuItem>
                              <MenuItem value={14.15}>14:15</MenuItem>
                              <MenuItem value={14.3}>14:30</MenuItem>
                              <MenuItem value={14.45}>14:45</MenuItem>
                              <MenuItem value={15.0}>15:00</MenuItem>
                              <MenuItem value={15.15}>15:15</MenuItem>
                              <MenuItem value={15.3}>15:30</MenuItem>
                              <MenuItem value={15.45}>15:45</MenuItem>
                              <MenuItem value={16.0}>16:00</MenuItem>
                              <MenuItem value={16.15}>16:15</MenuItem>
                              <MenuItem value={16.3}>16:30</MenuItem>
                              <MenuItem value={16.45}>16:45</MenuItem>
                              <MenuItem value={17.0}>17:00</MenuItem>
                              <MenuItem value={17.15}>17:15</MenuItem>
                              <MenuItem value={17.3}>17:30</MenuItem>
                              <MenuItem value={17.45}>17:45</MenuItem>
                              <MenuItem value={18.0}>18:00</MenuItem>
                              <MenuItem value={18.15}>18:15</MenuItem>
                              <MenuItem value={18.3}>18:30</MenuItem>
                              <MenuItem value={18.45}>18:45</MenuItem>
                              <MenuItem value={19.0}>19:00</MenuItem>
                              <MenuItem value={19.15}>19:15</MenuItem>
                              <MenuItem value={19.3}>19:30</MenuItem>
                              <MenuItem value={19.45}>19:45</MenuItem>
                              <MenuItem value={20.0}>20:00</MenuItem>
                              <MenuItem value={20.15}>20:15</MenuItem>
                              <MenuItem value={20.3}>20:30</MenuItem>
                              <MenuItem value={20.45}>20:45</MenuItem>
                              <MenuItem value={21.0}>21:00</MenuItem>
                              <MenuItem value={21.15}>21:15</MenuItem>
                              <MenuItem value={21.3}>21:30</MenuItem>
                              <MenuItem value={21.45}>21:45</MenuItem>
                              <MenuItem value={22.0}>22:00</MenuItem>
                            </TextField>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          {formik.touched.customerDuration &&
                          formik.errors.customerDuration ? (
                            <TextField
                              variant="outlined"
                              error
                              id="customerDuration"
                              label={formik.errors.customerDuration}
                              name="customerDuration"
                              {...formik.getFieldProps("customerDuration")}
                              size="small"
                            />
                          ) : (
                            <TextField
                              variant="outlined"
                              id="customerDuration"
                              label="Duration in minutes"
                              name="customerDuration"
                              {...formik.getFieldProps("customerDuration")}
                              size="small"
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          {formik.touched.customerPeople &&
                          formik.errors.customerPeople ? (
                            <TextField
                              variant="outlined"
                              error
                              id="customerPeople"
                              label={formik.errors.customerPeople}
                              name="customerPeople"
                              {...formik.getFieldProps("customerPeople")}
                              size="small"
                              required
                            />
                          ) : (
                            <TextField
                              variant="outlined"
                              number
                              id="customerPeople"
                              label="Number of people"
                              name="customerPeople"
                              {...formik.getFieldProps("customerPeople")}
                              size="small"
                              required
                            />
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          {formik.touched.customerPhone &&
                          formik.errors.customerPhone ? (
                            <TextField
                              variant="outlined"
                              error
                              id="customerPhone"
                              label={formik.errors.customerPhone}
                              name="customerPhone"
                              keyboardType={"number-pad"}
                              {...formik.getFieldProps("customerPhone")}
                              size="small"
                              required
                            />
                          ) : (
                            <TextField
                              variant="outlined"
                              id="customerPhone"
                              label="Phone"
                              name="customerPhone"
                              {...formik.getFieldProps("customerPhone")}
                              size="small"
                              required
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className="form-control" fullWidth>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            FIND A TABLE
                          </Button>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </MDBContainer>
          </FlatReserveContainer>
        </div>
      )}
    </FormContainer>
  );
};

export default FormPageFlat;
