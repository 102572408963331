import NoPicture from "../../../assets/images/Picture1.png";
import Chilli1 from "../../../assets/FoodImg/C1.png"
import Chilli2 from "../../../assets/FoodImg/C2.png"
import Chilli3 from "../../../assets/FoodImg/C3.png"


const FriFood = [
  {
    image: NoPicture,
    dishname: "1. Sag Tofu (G,L) (VEGAN)",
    dishitems: "Tofu, ginger-garlic tomato in chilli spinach sauce.",

    reference:"LunchFriFood",
    id:"1",
    price: "9,00€",
  },
  {
    image: NoPicture,
    dishname: "2. Malai Kofta (G)",
    dishitems:
      "Cashew-potato-cream cheese-dumplings in tomato-butter cream sauce.",

    reference:"LunchFriFood",
    id:"2",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "3. Chicken Paneer (G)",
    dishitems:
      "Fried chicken breast pieces and fresh cheese in tomato- coconut cream sauce.",
    reference:"LunchFriFood",
    id:"3",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["4. LAMB KOFTA MASALA (G, L)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Lamb minced meat dumplings seasoned with cumin and coriander in a slightly hot tomato-onion-ginger sauce",
    reference:"LunchFriFood",
    id:"4",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "5. Prawn Korma (G)",
    dishitems:
      "Shrimp in coconut-curry-cashew-tomato- white pepper-cream sauce.",
    reference:"LunchFriFood",
    id:"5",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname:[ "6. Today's Special KHASI KHUKURA MASALA (G)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Herb-yoghurt marinated and tandoori grilled chicken fillets and lamb grinder in a hot tomato-onion-ginger-masala sauce.",
    reference:"LunchFriFood",
    id:"6",
      price: "12,00€",
      dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
      "Kids Portion - 7,50€"],
      dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
        "G = Gluten free L = Lactose free", <p></p>,
        "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
      ],
  }, 
];
export default FriFood;
