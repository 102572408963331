import React from "react";
import styled from "styled-components";
import { CustomersFavouriteMenu } from "../data/CustomersFavourite";
import MenuItem from "./MenuItem";
import { Container } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from '@material-ui/core/Grid';

const BackgroundContainer = styled.div`
  width: 100%;
  background: linear-gradient(290deg, #fbc2eb, #a6c1ee);

`

const SpecialOfferContainer = styled.div`
  overflow: hidden;
  min-height: 65vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
  .sticker {
    font-family: "Suranna";
    font-size: 2.5rem;
    color: #c70039;
    padding-bottom: 2rem;
  }
`;

const FoodContainer = styled.div`
`;

const SpecialOffer = () => {
  const {t}=useTranslation();
  return (
    <BackgroundContainer>
    <Container maxWidth="xl">
      <SpecialOfferContainer>
        <h2 className="sticker">
          {t('Menu.cf')}
          <hr />
        </h2>
        <FoodContainer>
          <Grid container spacing={2}>
            {CustomersFavouriteMenu.map((item, index) => {
              return (
                <Grid item xs={12} md={6}>
                <MenuItem
                  key={index}
                  id={item.id}
                  reference={item.reference}
                  image={item.image}
                  dishname={item.dishname}
                  dishitems={item.dishitems}
                  price={item.price}
                />
                </Grid>
              );
            })}
          </Grid>
        </FoodContainer>
      </SpecialOfferContainer>
    </Container>
    </BackgroundContainer>
  );
};

export default SpecialOffer;
