import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";

const HomeMenuContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 968px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
const Container = styled.div`
  .footer-content-big {
    font-size: 18px;
    font-weight: 600;
  }
  .footer-content-small {
    font-size: 15px;
    font-weight: 500;
  }
`;
const MenuItems = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;

  @media (max-width: 968px) {
    padding: 0.4rem;
    width: 100%;
    height: 100%;
  }
`;

const HomeMenu = (props) => {
  return (
    <Container>
      <HomeMenuContainer>
        <MenuItems>
          {props.Food.map((item, index) => {
            return (
              <MenuItem
                key={index}
                id={item.id}
                image={item.image}
                dishname={item.dishname}
                dishitems={item.dishitems}
                price={item.price}
                dummy={item.dummy}
                dummyheader={item.dummyheader}
                reference={item.reference}
              />
            );
          })}
        </MenuItems>
      </HomeMenuContainer>
    </Container>
  );
};

export default HomeMenu;
