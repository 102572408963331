import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Fragment } from "react";
import HomePage from "./containers/HomePage";
import Navbar from "./components/Navbar";
import Dropdown from "./components/Dropdown";
import ToTop from "./components/ToTop";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import LocationSelect from "./components/LocationSelect";
import Reservation from "./containers/Reservation";
import Contact from "./containers/Contact";
import Drinks from "./containers/Drinks"; 
import AboutUs from "./containers/About";
import Menu from "./containers/Menu";
import Alacarte from "./containers/Alacarte"; 

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <Fragment>
      <Router>
        <ScrollToTop />
        <Navbar toggle={toggle} />
        <Dropdown isOpen={isOpen} toggle={toggle} />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/locationSelect" component={LocationSelect} />
          <Route path="/about" component={AboutUs} />
          <Route path="/menu" component={Menu} />
          <Route path="/alacarte" component={Alacarte} />
          <Route path="/drinks" component={Drinks} />
          <Route path="/reservation" component={Reservation} />
          <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />

        <ToTop />
      </Router>
    </Fragment>
  );
}

export default App;
