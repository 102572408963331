import React from "react";
import styled from "styled-components";
import LocationBkg from "../assets/images/locationbkg.jpg";
import RestaurantLogo from "../assets/images/Picture1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
const LocationSelectContainer = styled.div`
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${LocationBkg}) center/cover no-repeat border-box #2a3d4d;
`;

const FormContainer = styled.div`
  width: 30%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ContentSection = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;

  img {
    min-width: 230px;
    width: 230px;
    height: auto;
  }

  .select-location {
    font-family: "Montez";
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    img {
      min-width: 164px;
      width: 180px;
      height: auto;
    }
  }
`;
const OptionSection = styled.div`
  display: flex;
  margin: 2rem 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  .loc1 {
    border: 1px solid #900c3f;
    padding: 0.5rem 2rem;
    border-radius: 16px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 6px 3px 14px 0px #131212;
    background: #900c3f;
    text-decoration: none;
    color: #fff;
    outline: none;
    max-width: 300px;
    width: 250px;

    &:hover {
      color: #900c3f;
      background: #fff;
      transition: 0.2s ease-in;
      transform: translateY(-2px);
    }
  }
  .loc2 {
    margin-left: 1rem;
    border: 1px solid #900c3f;
    padding: 0.5rem 2rem;
    border-radius: 16px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 6px 3px 17px 5px #131212;
    background: #900c3f;
    color: #fff;
    outline: none;
    max-width: 300px;
    width: 250px;

    &:hover {
      color: #900c3f;
      background: #fff;
      transition: 0.2s ease-in;
      transform: translateY(-2px);
    }

    @media (max-width: 480px) {
      margin: 0;
      margin-top: 1rem;
    }
  }
`;
const SocialSection = styled.div`
  position: absolute;
  bottom: 3%;
  a {
    display: inline-block;
    color: #fff;
    margin: 0 0.5rem;
    font-size: 0.5rem;
    transition: transform 250ms;
  }
  a:hover {
    transform: translateY(-2px);
  }
`;

const LocationSelect = (props) => {
  function handleClick1() {
    props.click1();
  }
  function handleClick2() {
    props.click2();
  }
  return (
    <LocationSelectContainer>
      <FormContainer>
        <ContentSection>
          <img src={RestaurantLogo}></img>
          <p className="select-location">Select Your Location</p>
          <OptionSection>
            <button className="loc1" onClick={handleClick1}>
              <i
                style={{ marginRight: "0.7rem", textAlign: "start" }}
                className="fas fa-map-marker-alt"
              ></i>
              Kaupinkalliontie, <br />
              <span style={{ fontSize: "0.7rem" }}> 07 ESPOO</span>
            </button>
            <button className="loc2" onClick={handleClick2}>
              <i
                style={{ marginRight: "0.7rem", textAlign: "start" }}
                className="fas fa-map-marker-alt"
              ></i>{" "}
              Länsituulentie, <br />
              <span style={{ fontSize: "0.7rem" }}> 10 ESPOO</span>
            </button>
          </OptionSection>
        </ContentSection>
        <SocialSection>
          <a
            href="https://www.facebook.com/Ravintola.MountNepal/"
            target="_blank"
            className="facebook social"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>

         {/* <a href="#" className="instagram social">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
  </a>*/}
        </SocialSection>
      </FormContainer>
    </LocationSelectContainer>
  );
};

export default LocationSelect;
