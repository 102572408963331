import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./i18n";

ReactDOM.render(
  <React.Suspense fallback={<div>Loading</div>}>
    <I18nextProvider i18n={i18next}>
      <Main />
    </I18nextProvider>
  </React.Suspense>,
  document.getElementById("root")
);
