import NoPicture from "../../../assets/FoodImg/Picture1.png";
import PrawnMasala from "../../../assets/FoodImg/PrawnMasala.png"
import ChickenChoila from "../../../assets/FoodImg/ChickenChoila.png"
import Chilli1 from "../../../assets/FoodImg/C1.png"
import Chilli2 from "../../../assets/FoodImg/C2.png"
import Chilli3 from "../../../assets/FoodImg/C3.png"

const MonFood = [
  {
    image: NoPicture,
    dishname:[ "1.SAG AALU (G,L) (VEGAN)",] ,
    dishitems:[
      "Spinach and boiled potatoes in a spicy tomato-garlic- ginger onion sauce.",
    ],

    reference:"LunchMonFood",
    id:"1",
    price: "9,00€",
  },
  {
    image: NoPicture,
    dishname:[ "2.PANEER MASALA(G)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Cream cheese in a spicy tomato-onion-garlic sauce.",

    reference:"LunchMonFood",
    id:"2",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "3.BUTTER CHICKEN(G,L)",
    dishitems:
      "Herb yoghurt marinated and tandoori grilled chicken in tomato-butter-cream sauce.",
    reference:"LunchMonFood",
    id:"3",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: ["4.LAMB CHILLI (G, L)  ",<img src={Chilli2} height="30" width="33"></img>],
    dishitems:
      "Stewed lamb tenderloin pieces with green pepper tomato onion chili in soy sauce.",
    reference:"LunchMonFood",
    id:"4",
      price: "10,00€",
  },
  {
    image: PrawnMasala,
    dishname: ["5.PRAWN MASALA (G, L) ", <img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Shrimp and paprika in tomato-onion gun-garlic- chilli sauce.",
    reference:"LunchMonFood",
    id:"5",
      price: "10,00€",
  },
  {
    image: ChickenChoila,
    dishname: [" 6.TODAY'S SPECIAL-- KHUKURA CHOOILA (G)", <img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Herb yoghurt marinated and tandoori grilled chicken in tomato-butter-cream sauce.",
    reference:"LunchMonFood",
    id:"6",
      price: "12,50€",
      dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
      "Kids Portion - 7,50€"],
      dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
        "G = Gluten free L = Lactose free", <p></p>,
        "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
      ],
  },
 
];
export default MonFood;
