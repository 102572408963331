import Item1 from "../assets/FoodImg/ButterChicken.png";
import Item2 from "../assets/FoodImg/ChickenTikkaMasala.png";
import Item3 from "../assets/FoodImg/SahiPaneer.png";

const lunchMenu = [
  {
    image: Item1,
    dishname: "BUTTER CHICKEN",
    dishitems: [
      "Herb-yoghurt marinated and oven-grilled chicken breast fillets in coconut-tomato-butter-cream sauce Herb and yogurt marinated Tandoori roasted chicken fillet pieces in Coconut tomato- butter cream sauce.",
    ],
    reference:"AlacarteTandooriChicken",
    id:"5",
    price: "16,80€",
  },
  {
    image: Item2,
    dishname: "CHICKEN TIKKA MASALA",
    dishitems: [
      "Herb-yogurt marinated and tandoori grilled chicken fillets in  tomato-onion-garlic-ginger-chili-masala sauce.        Tandoori-grilled herb-yoghurt marinated chicken fillet pieces in tomato-onion-garlic-ginger-chilli-masala sauce.",
    ],
    reference:"AlacarteTandooriChicken",
    id:"7",
    price: "15,80€",
  },
  {
    image: Item3,
    dishname: "SAHI PANEER",
    dishitems: [
      "Bread cheese in tomato-butter-cream sauce. Cottage cheese cooked in tomato-butter-cream sauce.",
    ],
    reference:"AlacarteVegetable",
    id:"15",
    price: "16,00€",
  },
];
export default lunchMenu;
