import React, { useState } from "react";
import styled from "styled-components";

const ToTopContainer = styled.div`
  position: fixed;
  bottom: 5%;
  right: 3%;
  border-radius: 60%;
  background-color: #1d2026;
  padding: 0.8rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  opacity: 0.5;
  width: 50px;
  height: 50px;

  i {
    font-size: 1.5rem;
    color: #b48c80;
    transition: 0.4s ease-in-out;
  }

  i:hover {
    transform: translateY(-3px);
  }
`;

const ToTop = () => {
  const [toTop, setToTop] = useState(false);

  const changePosition = () => {
    if (window.scrollY >= 968) {
      setToTop(true);
    } else {
      setToTop(false);
    }
  };
  window.addEventListener("scroll", changePosition);

  return (
    <div>
      {toTop ? (
        <a href="#">
        <ToTopContainer>
            {" "}
            <i className="fas fa-angle-double-up"></i>
            </ToTopContainer>
          </a>
      ) : null}
    </div>
  );
};

export default ToTop;
