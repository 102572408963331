import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"

const TandooriDishesLamb =
[
  {
    image: NoPicture,
    dishname: ["66. LAMB SEKUWA (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteTandooriLamb",
    id:"1",
      price: "20,00€",
  },
  {
    image: NoPicture,
    dishname: ["67. LAMB TIKKA MASALA (G)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteTandooriLamb",
    id:"2",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: "68. KHASI MASALA KARAHI (G) ",
    reference:"AlacarteTandooriLamb",
    id:"3",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: ["69. SHEIKH KABAB MASALA ",<img src={Chilly2} height="30" width="33"></img>],
    reference:"AlacarteTandooriLamb",
    id:"4",
      price: "19,00€",
  },
  {
    image: NoPicture,
    dishname: "70. KHASI KOSELI (G) ",
    reference:"AlacarteTandooriLamb",
    id:"5",
    price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: ["71. CHEF'S TANDOOR MIX SIZZLER (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteTandooriLamb",
    id:"6",
      price: "24,50€",
    dummy:[
      
      "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
      "Main courses include basmati rice and naan bread.",<br></br>,
      "Each main course includes naan bread and Basmati rice.",<p></p>,
      "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
  },
];
export default TandooriDishesLamb;
