import MountKeraDesert from "../../../assets/FoodImg/MountKeraDesert.png";
import MountKeraDesert2 from "../../../assets/FoodImg/MountKeraDesert2.png";
import NoPicture from "../../../assets/FoodImg/Picture1.png";

const Desserts
= [
  {
    image: MountKeraDesert,
    dishname: "MOUNT KERA",
    reference:"AlacarteDesserts",
    id:"1",
      price: "5,90€",
  },
  {
    image: NoPicture,
    dishname: "ICE CREAMS",
    reference:"AlacarteDesserts",
    id:"2",
      price: "5,90€",
  },
  {
    image: NoPicture,
    dishname: "MIXED ICE CREAM",
    reference:"AlacarteDesserts",
    id:"3",
      price: "6,00€",
  },
  {
    image: MountKeraDesert2,
    dishname: " MITHO KERA (G)",
    reference:"AlacarteDesserts",
    id:"4",
      price: "5,50€",
  },
  {
    image: NoPicture,
    dishname: "KULFI ",
    reference:"AlacarteDesserts",
    id:"5",    
    price: "5,50€",
  },
  {
    image: NoPicture,
    dishname: "GULAB JAMUN (G) (3 balls) ",
    reference:"AlacarteDesserts",
    id:"6",    
    price: "4,90€",
  },
  {
    image: NoPicture,
    dishname: "GAJAR KA HALWA (G) 	 ",
    dishitems:      "Carrot pudding and vanilla ice cream. Contains whole milk.",
    reference:"AlacarteDesserts",
    id:"7",    
    price: "6,00€",
  },
];
export default Desserts;
