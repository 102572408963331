import NoPicture from "../../../assets/FoodImg/Picture1.png";
import PrawnMasala from "../../../assets/FoodImg/PrawnMasala.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


const SeaFood = 
[
  {
    image: NoPicture,
    dishname: [" 72. PRAWN TANDOOR (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"1",
      price: "22,50€",
  },

  {
    image: NoPicture,
    dishname: ["73. PRAWN SIZZLER (G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"2",
      price: "22,00€",
  },
  {
    image: NoPicture,
    dishname: ["74. PRAWN CHILLI (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"3",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: "75. GARLIC PRAWN KARAHI (G, L)  ",
    reference:"AlacarteSeaFood",
    id:"4",
      price: "19,50€",
  },
  {
    image: PrawnMasala,
    dishname: ["76. PRAWN MASALA (G, L)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"5",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: "77. SHAHI NARIWAL PRAWN (G) ",
    reference:"AlacarteSeaFood",
    id:"6",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: "78. KING PRAWN SAG (G) ",
    reference:"AlacarteSeaFood",
    id:"7",
      price: "19,50€",
  },
  {
    image: NoPicture,
    dishname: "79. SPECIAL BUTTER GARLIC PRAWN (G) ",
    reference:"AlacarteSeaFood",
    id:"8",
      price: "21,00€",
  },
  {
    image: NoPicture,
    dishname: "80. PRAWN PANEER (G) ",
    reference:"AlacarteSeaFood",
    id:"9",
      price: "16,00€",
  },
  {
    image: NoPicture,
    dishname: "81. MACHHA SAG (G)",
    reference:"AlacarteSeaFood",
    id:"10",
      price: "17,00€",
  },
  {
    image: NoPicture,
    dishname: ["82. FISH CHILLI (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"11",
      price: "16,50€",
  },

  {
    image: NoPicture,
    dishname: "83. FISH KORMA (G)",
    reference:"AlacarteSeaFood",
    id:"12",
    price: "17,50€",
  },
  {
    image: NoPicture,
    dishname: ["84. FISH MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    reference:"AlacarteSeaFood",
    id:"13",
      price: "16,50€",
    dummy:[
      
      "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
      "Main courses include basmati rice and naan bread.",<br></br>,
      "Each main course includes naan bread and Basmati rice.",<p></p>,
      "Mild / Mild (s)    Medium / medium spicy(ss)   Real Strong / very spicy(sss)",
    ],
  },
];
export default SeaFood;
