import styled from "styled-components";
import { Link } from "react-router-dom";

const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#000d1a" : "#FCDB9B")};
  border-radius: ${({ borderradius }) => (borderradius ? "20px" : "none")};
  white-space: nowrap;
  outline: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ big }) => (big ? "1rem 1rem" : "14px 24px")};
  color: ${({ primary }) => (primary ? "#fff" : "#393939")};
  font-size: ${({ big }) => (big ? "1.2rem" : "0.9rem")};
  font-weight: 500;

  &:hover {
    transform: translateY(-2px);
    color: #000;
    font-weight: 700;
  }
`;

export default Button;
