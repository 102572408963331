import NoPicture from "../../../assets/FoodImg/Picture1.png";
import ChickenTikkaMasala from "../../../assets/FoodImg/ChickenTikkaMasala.png";
import Chilli1 from "../../../assets/FoodImg/C1.png"
import Chilli2 from "../../../assets/FoodImg/C2.png"
import Chilli3 from "../../../assets/FoodImg/C3.png"
const ThuFood = [
  {
    image: NoPicture,
    dishname: ["1. TOFU CHILLI (G,L) (VEGAN) ",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Tofu and paprika in onion-ginger-tomato-chilli sauce.",

    id:"1",
    reference:"LunchThuFood",
      price: "9,00€",
  },
  {
    image: NoPicture,
    dishname: ["2. VEGETABLE KOFTA (G)",],
    dishitems:
      "Deep-fried cauliflower-potato- fresh cheese dumplings in spinach-curry cream sauce",
    id:"2",
    reference:"LunchThuFood",
      price: "10,00€",
  },
  {
    image: ChickenTikkaMasala,
    dishname: ["3. CHICKEN TIKKA MASALA (G)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Herb-yogurt marinatedandtandoori grilled chicken fillets in tomato-onion-garlic-ginger-chili-masala sauce.",
    id:"3",
    reference:"LunchThuFood",
      price: "10,00€",
  },
  
  {
    image: NoPicture,
    dishname:[ "4. GARLIC KHASI KARAHI (G)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Braised lamb pieces in a spicy tomato-yogurt-garlic-ginger-curry sauce.",
    id:"4",
    reference:"LunchThuFood",
      price: "10,00€",
  },
  
  {
    image: NoPicture,
    dishname: "5. MACHHA SAG (G) ",
    dishitems:
      "Fried pike fillet,(White fish) in spinach curry cream sauce.",
    id:"5",
    reference:"LunchThuFood",
      price: "10,00€",
  },
  
  {
    image: NoPicture,
    dishname:[ "6. Today's Special TANDOOR MIX MASALA (G)",<img src={Chilli2} height="30" width="33"></img>],
    dishitems:
      "Herb-yogurt marinatedandtandoori grilled chicken fillets in tomato-onion-garlic-ginger-chili-masala sauce.",
    id:"6",
    reference:"LunchThuFood",
      price: "14,00€",
      dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
      "Kids Portion - 7,50€"],
      dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
        "G = Gluten free L = Lactose free", <p></p>,
        "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
    ],
  },
];
export default ThuFood;
