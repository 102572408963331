import React from "react";
import MenuBkg from "../assets/images/aboutbkg.jpg";
import styled from "styled-components";
import ExquisiteCuisine from "../components/ExquisiteCuisine";
import FormPageFlat from "../components/ReservationPage/FlatReservePage";
import SpecialOffer from "../components/SpecialOffer";
import OurMenu from "../components/nished/OurMenu";
import {useTranslation} from "react-i18next";

const MenuContainer = styled.div`
  overflow:hidden;
  .jjklve{
    height:auto;
  }
`;

const Menu = () => {
  const { t } = useTranslation();
  return (
    <MenuContainer>
      <ExquisiteCuisine
        title={t('Menu.dc')}
        head={t('Menu.dtm')}
        image={MenuBkg}
      />
      <OurMenu />
      <SpecialOffer />
      <FormPageFlat />
    </MenuContainer>
  );
};

export default Menu;
