import React,{useState} from 'react';
import LocationSelect from './components/LocationSelect';
import SecondLocation from './SecondLocation';
import App from './App.js';

function Main(){

    const [Location,setLocation] = useState({showLocation:true,restaurant:false});
    
    function click1(){
        setLocation({showLocation:false,restaurant:false});
    }
    function click2(){
        setLocation({showLocation:false,restaurant:true});
    }
    return(
        <React.Fragment>
            { (Location.showLocation === true )  ? <LocationSelect click1={click1} click2={click2}/>: null}
            { (Location.showLocation=== false && Location.restaurant===false) ? <App/>:null}
            { (Location.showLocation=== false && Location.restaurant===true) ? <SecondLocation/>:null}
        </React.Fragment>
    )
}
export default Main;
