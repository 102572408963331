import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"
const ChickenDishes = [
  {
    image: NoPicture,
    dishname: "26. CHICKEN CURRY (G, L) ",  
    reference:"AlacarteChicken",
    id:"1",
      price: "14,80€",
  },
  {
    image: NoPicture,
    dishname: ["27. GARLIC CHICKEN KARAHI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteChicken",
    id:"2",
      price: "15,90€",
  },
  {
    image: NoPicture,
    dishname: ["28 . CHICKEN KADAI(G,L)  ",<img src={Chilly2} height="30" width="33"></img>],
      reference:"AlacarteChicken",
    id:"3",
      price: "16,90€",
  },
  {
    image: NoPicture,
    dishname: ["29. CHICKEN TAMA (G, L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteChicken",
    id:"4",
      price: "16,00€",
  },
  {
    image: NoPicture,
    dishname: ["30. CHICKEN MASALA(G,L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"AlacarteChicken",
    id:"5",
      price: "15,90€",
  },
  {
    image: NoPicture,
    dishname: ["31.CHICKEN ROGAN JOSH(G) ", <img src={Chilly2} height="30" width="33"></img>],
      reference:"AlacarteChicken",
    id:"6",
      price: "16,50€",
  },
  {
    image: NoPicture,
    dishname: ["32. CHICKEN VINDALOO (G, L) ", <img src={Chilly3} height="30" width="33"></img>],
      reference:"AlacarteChicken",
    id:"7",
      price: "15,90€",
  },
  {
    image: NoPicture,
    dishname: "33.CHICKEN KORMA (G) ",
    reference:"AlacarteChicken",
    id:"8",
      price: "16,50€",
  },
  {
    image: NoPicture,
    dishname: "34. CHICKEN PANEER (G)  ",
      reference:"AlacarteChicken",
    id:"9",
      price: "16,30€",
  },
  {
    image: NoPicture,
    dishname: "35. MANGO CHICKEN (L, G) ",
      reference:"AlacarteChicken",
    id:"10",
      price: "16,50€",
   },
   {
    image: NoPicture,
    dishname: "36. CHEF SPECIAL CHICKEN(G)",
      reference:"AlacarteChicken",
    id:"11",
      price: "17,50€",
  },

  {
    image: NoPicture,
    dishname: "37. Spinach chicken (G.L) ",
      reference:"AlacarteChicken",
    id:"12",
      price: "15,90€",
    dummy: [

      "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free", <p></p>,
      "Main courses include basmati rice and naan bread.", <br></br>,
      "Each main course includes naan bread and Basmati rice.", <p></p>,
      "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>, " Medium / medium spicy", <img src={Chilly2} height="30" width="33"></img>, " Real Strong / very spicy", <img src={Chilly3} height="30" width="33"></img>],
  
  },
];
export default ChickenDishes;
