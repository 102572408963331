import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { MDBBtn, MDBIcon } from "mdbreact";
import { Fragment } from "react";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import TabMenu from "../../components/nished/TabMenu";
import FriFood from "../../components/nished/data/FriFood";
import MonFood from "../../components/nished/data/MonFood";
import TueFood from "../../components/nished/data/TueFood";
import WedFood from "../../components/nished/data/WedFood";
import ThuFood from "../../components/nished/data/ThuFood";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./ourMenu.css";
import styled from "styled-components";
import AsianFood from "../../components/nished/data/AsianFood";
import SideDishes from "../../components/nished/data/SideDishes";
import ChickenDishes from "../../components/nished/data/ChickenDishes";
import GroupMenu from "../../components/nished/data/GroupMenu";
import Starter from "../../components/nished/data/Starter";
import LambDishes from "../../components/nished/data/LambDishes";
import Noodles from "../../components/nished/data/Noodles";
import SeaFood from "../../components/nished/data/SeaFood";
import TandooriDishesChicken from "../../components/nished/data/TandooriDishesChicken";
import ThaliDishes from "../../components/nished/data/ThaliDishes";
import VegetableDishes from "../../components/nished/data/VegetableDishes";
import Desserts from "../../components/nished/data/Desserts";
import TandooriDishesLamb from "../../components/nished/data/TandooriDishesLamb";
import Beverages from "../../components/nished/data/Beverages";
import ChildrenMenu from "../../components/nished/data/ChildrenMenu";
import DrinkTabMenu from "../../components/nished/DrinkTabMenu";
import { useTranslation } from "react-i18next";

const MenuPopupContainer = styled.div`
  min-height: 40vh;
  height: auto;

  .menu-heading{
    text-transform: uppercase;
  }
`;

function OurMenu() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const handleClose = () => setShow(false);

  // for alacarte
  const [alacarteShow, setAlacarteShow] = useState(false);
  const handleAlacarteShow = () => setAlacarteShow(!alacarteShow);
  const handleAlacarteClose = () => setAlacarteShow(false);

  return (
    <div className="ourMenuContainer">
      <MenuPopupContainer className="containerMenu">
        <div className="menu-heading">{t('Menu.ourMenu')}</div>
        <div className="design">
          _______________
          <LocalDiningIcon />
          _______________
        </div>
        <Fragment>
          <MDBBtn className ="button1" gradient="blue" onClick={handleAlacarteShow} >
            <MDBIcon icon="utensil-spoon" className="mr-1" /> A-LA-CARTE
          </MDBBtn>
          <MDBBtn className ="button1" gradient="aqua" onClick={handleShow}>
            LUNCH <MDBIcon icon="utensils" className="ml-1" />
          </MDBBtn>
        </Fragment>
        <div>
          {show ? (
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              animation={false}
              scrollable={true}
              dialogClassName="modal-150w"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  LUNCH{" "}
                  <div className="lunchPopupHeading">MON-FRI: 11:00-15:00</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs>
                  <TabList className="tabList">
                    <Tab>MON</Tab>
                    <Tab>TUE</Tab>
                    <Tab>WED</Tab>
                    <Tab>THU</Tab>
                    <Tab>FRI</Tab>
                  </TabList>

                  <TabPanel className="tabPanel">
                    <TabMenu className="tabMenu" Food={MonFood} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={TueFood} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={WedFood} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={ThuFood} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={FriFood} />
                  </TabPanel>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="dark" onClick={handleClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </div>

        <div>
          {alacarteShow ? (
            <Modal
              size="lg"
              show={alacarteShow}
              onHide={handleAlacarteClose}
              animation={false}
              scrollable={true}
              dialogClassName="modal-20w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title>A-LA-CARTE</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Tabs className="tabs">
                  <TabList className="tabList">
                    <Tab>Starter</Tab>
                    <Tab>Vegetable Dishes</Tab>
                    <Tab>Chicken Dishes</Tab>
                    <Tab>Tandoori Chicken Dishes</Tab>
                    <Tab>Lamb Dishes</Tab>
                    <Tab>Tandoori Lamb Dishes</Tab>
                    <Tab>Sea Food</Tab>
                    <Tab>Noodles</Tab>
                    <Tab>Asian Food</Tab>
                    <Tab>Thali Dishes</Tab>
                    <Tab>Group Menu</Tab>
                    <Tab>Children Menu</Tab>
                    <Tab>Side Dishes</Tab>
                    <Tab>Desserts</Tab>
                    <Tab>Beverages</Tab>
                  </TabList>

                  <TabPanel>
                    <TabMenu Food={Starter} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={VegetableDishes} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={ChickenDishes} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={TandooriDishesChicken} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={LambDishes} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={TandooriDishesLamb} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={SeaFood} />
                  </TabPanel>                  
                  <TabPanel>
                    <TabMenu Food={Noodles} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={AsianFood} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={ThaliDishes} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={GroupMenu} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={ChildrenMenu} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={SideDishes} />
                  </TabPanel>
                  <TabPanel>
                    <TabMenu Food={Desserts} />
                  </TabPanel>
                  <TabPanel>
                    <DrinkTabMenu Food={Beverages} />
                  </TabPanel>
                </Tabs>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="dark" onClick={handleAlacarteClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </div>
      </MenuPopupContainer>
    </div>
  );
}
export default OurMenu;

/*

*/
