import ThaiRedCurry from "../../../assets/FoodImg/ThaiRedCurry.png";
import NoPicture from "../../../assets/FoodImg/Picture1.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"
const AsianFood = 
[
  {
    image: ThaiRedCurry,
    dishname: ["1. THAI RED CURRY ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    id:"1",
    reference:"AlacarteAsianFood",
      price: "17,50€",
  },
  {
    image: NoPicture,
    dishname: ["2. GREEN CURRY ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    id:"2",
    reference:"AlacarteAsianFood",
    price: "17,50€",
  },

  {
    dishname:[<h3 style={{
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: '12px',
      borderBottom: '1px solid',
  }}>Briyani</h3>],
    id:"",
    reference:""
  },
  
  {
    image:NoPicture,
    dishname: ["1. VEG BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"3",
    reference:"AlacarteAsianFood",
      price: "15,90€",
  },
  {
    image: NoPicture,
    dishname: ["2. CHICKEN BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"4",
    reference:"AlacarteAsianFood",
      price: "16,90€",
  },
  {
    image: NoPicture,
    dishname: ["3. LAMB BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"5",
    reference:"AlacarteAsianFood",
      price: "17,90€",
      dummy:["Mild / Mild", <img src={Chilly1} alt="" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="" height="30" width="33"></img>],
  }
]
export default AsianFood;
