import React from "react";
import styled from "styled-components";
import Main from "../data/Alacarte/Main";
import Starter from "../data/Alacarte/Starters";
import MenuHeader from "./MenuHeader";
import AlacarteMenuComponent from "./AlacarteMenuComponent";
import Grid from '@material-ui/core/Grid';


const AlacarteMenuContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;
const StarterSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const ConcreteSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const MenuItems = styled.div`
  .offer-title {
    overflow: hidden;
    font-family: "Suranna", sans-serif;
    font-size: 2rem;
    color: #000;
    margin: 0;
    line-height: 1.2;
    @media (max-width: 968px) {
      font-size: 2rem;
    }
  }

  @media (max-width: 968px) {
    padding: 0.4rem;
    width: 100%;
    height: 100%;
  }
`;
const ItemHead = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
`;

const AlacarteMenu = () => {
  return (
    <AlacarteMenuContainer>
      <StarterSection>
        <MenuItems>
          <ItemHead></ItemHead>
          <Grid container spacing={1}>
          {Starter.map((item, index) => {
            return (
              <AlacarteMenuComponent
                key={index}
                id={item.id}
                reference={item.reference}
                dishname={item.dishname}
                dishitems={item.dishitems}
                price={item.price}
              />
            );
          })}
          </Grid>
        </MenuItems>
      </StarterSection>
      <MenuHeader title="More concrete" name="MAIN DISHES" desc="" />
      <ConcreteSection>
        <MenuItems>
        <Grid container spacing={1}>
          {Main.map((item, index) => {
            return (
              <AlacarteMenuComponent
                key={index}
                dishname={item.dishname}
                dishitems={item.dishitems}
                id={item.id}
                reference={item.reference}
                price={item.price}
              />
            );
          })}
          </Grid>
        </MenuItems>
      </ConcreteSection>
      
    </AlacarteMenuContainer>
  );
};

export default AlacarteMenu;
//       <Popout image={Desert} />
