import NoPicture from "../../../assets/FoodImg/Picture1.png";
import SagPaneer from "../../../assets/FoodImg/SagPaneer.png";
import PaneerChilly from "../../../assets/FoodImg/PaneerChilly.png";
import SahiPaneer from "../../../assets/FoodImg/SahiPaneer.png";
import ButterChicken from "../../../assets/FoodImg/ButterChicken.png";
import ChickenTikkaMasala from "../../../assets/FoodImg/ChickenTikkaMasala.png";
import PrawnMasala from "../../../assets/FoodImg/PrawnMasala.png";
import Chowmein from "../../../assets/FoodImg/Chowmein.png";
import MountKeraDesert from "../../../assets/FoodImg/MountKeraDesert.png";
import MountKeraDesert2 from "../../../assets/FoodImg/MountKeraDesert2.png";
import Chilly1 from "../../../assets/FoodImg/C1.png"
import Chilly2 from "../../../assets/FoodImg/C2.png"
import Chilly3 from "../../../assets/FoodImg/C3.png"


export const VegetarianDishes = [
    {
      image: NoPicture,
      dishname: "1. SAG TOFU (G, L) (VEGAN)",   
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"1",
        price: "13,50€",
    },
    {
      image: NoPicture,
      dishname: ["2. TOFU CHILLI (G, L) (VEGAN) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"2",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["3. TOFU MASALA(G, L) (VEGAN)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>,],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"3",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: "4. SAG ALOO (G,L) (VEGAN) ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"4",
      price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: [" 5. ALU CHANA (G,L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"5",
    price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["6. ALU GOVI (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"6",
        price: "14,00€",
    },
    {
      image: NoPicture,
      dishname: ["7. MIX VEGETABLES (G,L) (VEGAN) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"7",
        price: "14,80€",
    },
    {
      image: NoPicture,
      dishname: ["8. BHINDI MASALA (G, L) (VEGAN)",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"8",
      price: "14,50€",
    },
   
    {
      image: NoPicture,
      dishname: ["9. BAIGAN MASALA (G,L) (VEGAN)  ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"9",
        price: "14,50€",
    },
    {
      image: NoPicture,
      dishname: [" 10. MUSHROOM MASALA (G,L) (VEGAN)",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"10",
        price: "14,20€",
    },
    {
      image: NoPicture,
      dishname: "11. MALAI KOFTA (G) ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"11",
        price: "15,50€",
    },
    {
      image: NoPicture,
      dishname: "12. VEGETABLE KOFTA (G) ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"12",
        price: "15,80€",
    },
    
  
    {
      image: NoPicture,
      dishname: "13. SAG KOFTA (G,L)	  ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"13",
        price: "15,50€",
    },
    {
      image: NoPicture,
      dishname: "14. CHEF SPECIAL KOFTA  (G)		  ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"14",
        price: "16,80€",
      
    },
    {
      image: NoPicture,
      dishname: ["15. PANEER BUTTER MASALA (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"15",
        price: "17,80€",
    },
    {
      image: SahiPaneer,
      dishname: "16. SHAHI PANEER (G)  ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"16",
        price: "16,00€",
    },
    {
      image: NoPicture,
      dishname: ["17. KARAI PANEER (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"17",
        price: "15,50€",
    },
    {
      image:NoPicture,
      dishname: [" 18. PANEER TIKKA MASALA (G) ",,<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"18",
      price: "16,40€",
    },
  
    {
      image: PaneerChilly,
      dishname: ["19. PANEER CHILLI (G) ",<img src={Chilly3} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"19",
        price: "16,00€",
    },
  
    {
      image: NoPicture,
      dishname: "20.HARIYALI PANEER (G)	  ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"20",
        price: "15,90€",
    },
    {
      image: SagPaneer,
      dishname: "21. SAG PANEER (G)  ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"21",
        price: "16,20€",
    },
    {
      image: NoPicture,
      dishname: "22. MOUNT SPECIAL PANEER (G)   ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"22",
        price: "17,00€",
      },
  
      {
        image: NoPicture,
        dishname: ["23. VEGETABLES KARAI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
        reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
        id:"23",
          price: "16,80€",
      },
  
  
    {
      image: NoPicture,
      dishname: "24. DAL MAKHANI (G) ",
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"24",
        price: "14,50€",
    },
    {
      image: NoPicture,
      dishname: ["25. JHANEKO DAL (G)  ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
      reference:"SecondResturant.Alacarte.Main.VegetarianDishes",
      id:"25",
        price: "14,00€",
        dummy:[
          "Dishes number 1-10 are suitable for vegans.",
        <p></p>,
          "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
          "Main courses include basmati rice and naan bread.",<br></br>,
          "Each main course includes naan bread and Basmati rice.",<p></p>,
          "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
      
    },
  ];
export const ChickenDishes = [
    {
      image: NoPicture,
      dishname: "26. CHICKEN CURRY (G, L) ",  
      reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"1",
        price: "14,80€",
    },
    {
      image: NoPicture,
      dishname: ["27. GARLIC CHICKEN KARAHI (G) ",<img src={Chilly1} height="30" alt="mild" width="33"></img>, ],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"2",
        price: "15,90€",
    },
    {
      image: NoPicture,
      dishname: ["28 . CHICKEN KADAI(G,L)  ",<img src={Chilly2} height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"3",
        price: "16,90€",
    },
    {
      image: NoPicture,
      dishname: ["29. CHICKEN TAMA (G, L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"4",
        price: "16,00€",
    },
    {
      image: NoPicture,
      dishname: ["30. CHICKEN MASALA(G,L) ",<img src={Chilly2} height="30" alt="mild" width="33"></img>, ],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"5",
        price: "15,90€",
    },
    {
      image: NoPicture,
      dishname: ["31.CHICKEN ROGAN JOSH(G) ", <img src={Chilly2} height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"6",
        price: "16,50€",
    },
    {
      image: NoPicture,
      dishname: ["32. CHICKEN VINDALOO (G, L) ", <img src={Chilly3} height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"7",
        price: "15,90€",
    },
    {
      image: NoPicture,
      dishname: "33.CHICKEN KORMA (G) ",
      reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"8",
        price: "16,50€",
    },
    {
      image: NoPicture,
      dishname: "34. CHICKEN PANEER (G)  ",
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"9",
        price: "16,30€",
    },
    {
      image: NoPicture,
      dishname: "35. MANGO CHICKEN (L, G) ",
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"10",
        price: "16,50€",
     },
     {
      image: NoPicture,
      dishname: "36. CHEF SPECIAL CHICKEN(G)",
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"11",
        price: "17,50€",
    },

    {
      image: NoPicture,
      dishname: "37. Spinach chicken (G.L) ",
        reference:"SecondResturant.Alacarte.Main.ChickenDishes",
      id:"12",
        price: "15,90€",
      dummy: [

        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free", <p></p>,
        "Main courses include basmati rice and naan bread.", <br></br>,
        "Each main course includes naan bread and Basmati rice.", <p></p>,
        "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>, " Medium / medium spicy", <img src={Chilly2} height="30" width="33"></img>, " Real Strong / very spicy", <img src={Chilly3} height="30" width="33"></img>],
    
    },
  ];

export const TandooriChickenDishes = [
        {
          image: NoPicture,
          dishname: "38. CHICKEN SAAG KARAHI (G)",
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"1",
          price: "17,50€",
        },
        {
          image: NoPicture,
          dishname: ["39. ​​CHICKEN CHILLI (G, L)", <img src={Chilly2} height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"2",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: [" 40. KUKHURA CHHOILA (G, L)" , <img src={Chilly2} height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"3",
            price: "17,00€",
        },
        {
          image: NoPicture,
          dishname: "41. CHICKEN TIKKA (G)  ",
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"4",
            price: "16,80€",
        },
        {
          image: NoPicture,
          dishname: ["42. CHICKEN SASLIK (G) ",<img src={Chilly1} alt = "medium" height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"5",
            price: "17,80€",
        },
        {
          image: ChickenTikkaMasala,
          dishname: ["43. CHICKEN TIKKA MASALA (G) ", <img src={Chilly2} alt = "medium" height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"6",
            price: "16,50€",
        },
        {
          image: NoPicture,
          dishname: ["44. CHICKEN BUTTER MASALA (G) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"7",
            price: "19,00€",
        },
        {
          image: NoPicture,
          dishname: [" 45. BHALE KARAI SPECIAL (G)", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"8",
            price: "18,50€",
        },
        {
          image: ButterChicken,
          dishname: " 46. BUTTER CHICKEN (G)",
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"9",
            price: "16,80€",
        },
        {
          image: NoPicture,
          dishname: "47. MOUNT SPECIAL CHICKEN (G) ",
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"10",
            price: "19,50€",
          },
          {
            image: NoPicture,
            dishname: "48. BUTTER GARLIC TIKKA (G) ",
            reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
            id:"11",
              price: "19,00€",
          },
        {
          image: NoPicture,
          dishname: "49. BHALE MALAI TIKKA (G)",
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"12",
            price: "18,90€",
        },
        {
          image: NoPicture,
          dishname: ["50.CHICKEN SHIRAZ (G)"],
          reference:"SecondResturant.Alacarte.Main.TandooriChickenDishes",
          id:"13",
            price: "18,90€",
            dummy:[
            
              "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
              "Main courses include basmati rice and naan bread.",<br></br>,
              "Each main course includes naan bread and Basmati rice.",<p></p>,
              "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
        
        },
];

export const LambDishes = [
    {
      image: NoPicture,
      dishname: "51. LAMB CURRY (G, L)", 
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"1",
        price: "15,80€",
    },
    {
      image: NoPicture,
      dishname: ["52. LAMB MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"2",
        price: "17,90€",
    },
    {
      image: NoPicture,
      dishname: ["53. LAMB Rogan josh (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"3",
        price: "18,90€",
    },
    {
      image: NoPicture,
      dishname: ["54. LAMB VINDALOO (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"4",
        price: "16,90€",
      },
      {
        image: NoPicture,
        dishname: [" 55. PIRO BHEDO (G, L)" ,<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"5",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["56. GINGER LAMB (G, L) ", <img src={Chilly1} alt="mild" height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"6",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["57. GARLIC KHASI KARAHI (G)  ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"7",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: ["58. LAMB KOFTA MASALA(G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"8",
          price: "15,80€",
      },
      {
        image: NoPicture,
        dishname: "59. Lamb Kofta Cream (G)",
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"9",
          price: "16,50€",
      },
      {
        image: NoPicture,
        dishname: "60. BUTTER LAMB (G) ",
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"10",
          price: "17,50€",
      },
      {
        image: NoPicture,
        dishname: "61. LAMB KORMA (G) ",
        reference:"SecondResturant.Alacarte.Main.LambDishes",
        id:"11",
          price: "16,80€",
      },
    {
      image: NoPicture,
      dishname: "62. LAMB SAAG (G, L) ",
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"12",
      price: "16,50€",
    },
    {
      image: NoPicture,
      dishname: [" 63. SPECIAL MIX KARAHI (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"13",
        price: "18,50€",
    },
  
    { 
      image: NoPicture,
      dishname: [" 64. LAMB BUTTER MASALA (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"14",
        price: "18,50€",
    },
    {
      image: NoPicture,
      dishname: " 65.Chef special lamb(G)",
      reference:"SecondResturant.Alacarte.Main.LambDishes",
      id:"15",
        price: "19,50€",
      dummy:[
        
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
    
    },
  ];

export const TandooriLambDishes = [
    {
      image: NoPicture,
      dishname: ["66. LAMB SEKUWA (G) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"1",
        price: "20,00€",
    },
    {
      image: NoPicture,
      dishname: ["67. LAMB TIKKA MASALA (G)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"2",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "68. KHASI MASALA KARAHI (G) ",
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"3",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: ["69. SHEIKH KABAB MASALA ",<img src={Chilly2} height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"4",
        price: "19,00€",
    },
    {
      image: NoPicture,
      dishname: "70. KHASI KOSELI (G) ",
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"5",
      price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: ["71. CHEF'S TANDOOR MIX SIZZLER (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.TandooriLambDishes",
      id:"6",
        price: "24,50€",
      dummy:[
        
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild", <img src={Chilly1} height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} height="30" width="33"></img>,    ],
    },
  ];

export const SeaFood = [
    {
      image: NoPicture,
      dishname: [" 72. PRAWN TANDOOR (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"1",
        price: "22,50€",
    },
  
    {
      image: NoPicture,
      dishname: ["73. PRAWN SIZZLER (G, L) ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"2",
        price: "22,00€",
    },
    {
      image: NoPicture,
      dishname: ["74. PRAWN CHILLI (G, L) ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"3",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "75. GARLIC PRAWN KARAHI (G, L)  ",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"4",
        price: "19,50€",
    },
    {
      image: PrawnMasala,
      dishname: ["76. PRAWN MASALA (G, L)  ",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"5",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "77. SHAHI NARIWAL PRAWN (G) ",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"6",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "78. KING PRAWN SAG (G) ",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"7",
        price: "19,50€",
    },
    {
      image: NoPicture,
      dishname: "79. SPECIAL BUTTER GARLIC PRAWN (G) ",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"8",
        price: "21,00€",
    },
    {
      image: NoPicture,
      dishname: "80. PRAWN PANEER (G) ",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"9",
        price: "16,00€",
    },
    {
      image: NoPicture,
      dishname: "81. MACHHA SAG (G)",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"10",
        price: "17,00€",
    },
    {
      image: NoPicture,
      dishname: ["82. FISH CHILLI (G)",<img src={Chilly2} alt="medium" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"11",
        price: "16,50€",
    },
  
    {
      image: NoPicture,
      dishname: "83. FISH KORMA (G)",
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"12",
      price: "17,50€",
    },
    {
      image: NoPicture,
      dishname: ["84. FISH MASALA (G,L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Main.SeaFood",
      id:"13",
        price: "16,50€",
      dummy:[
        
        "G = Gluten-free L = Lactose-free / G = Gluten-free L = Lactose-free",<p></p>,
        "Main courses include basmati rice and naan bread.",<br></br>,
        "Each main course includes naan bread and Basmati rice.",<p></p>,
        "Mild / Mild (s)    Medium / medium spicy(ss)   Real Strong / very spicy(sss)",
      ],
    },
  ];

 export const Noodles
  = [
   {
     image: Chowmein,
     dishname:[ "VEG CHOWMEIN (L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
     reference:"SecondResturant.Alacarte.Noodles",
     id:"1",
       price: "14,90€",
   },
   {
     image: Chowmein,
     dishname: ["MIX CHOWMEIN (L)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
     reference:"SecondResturant.Alacarte.Noodles",
     id:"2",
     price: "16,90€",
         dummy:["Does not include rice and naan bread / Rice and naan bread is not included.",<br></br>,
         "Mild / Mild", <img src={Chilly1} alt="mild" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="medium" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="spicy" height="30" width="33"></img>,],
   },
   
 ];

 export const AsianFood=
 [
  {
    image: NoPicture,
    dishname: ["1. THAI RED CURRY ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    id:"1",
    reference:"SecondResturant.Alacarte.AsianFood",
      price: "17,50€",
  },
  {
    image: NoPicture,
    dishname: ["2. GREEN CURRY ",<img src={Chilly3} alt="spicy" height="30" width="33"></img>],
    id:"2",
    reference:"SecondResturant.Alacarte.AsianFood",
    price: "17,50€",
  },

  {
    dishname:[<h3 style={{
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: '12px',
      borderBottom: '1px solid',
  }}>Briyani</h3>],
    id:"",
    reference:""
  },
  
  {
    image:NoPicture,
    dishname: ["1. VEG BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"1",
    reference:"SecondResturant.Alacarte.Briyani",
      price: "15,90€",
  },
  {
    image: NoPicture,
    dishname: ["2. CHICKEN BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"2",
    reference:"SecondResturant.Alacarte.Briyani",
      price: "16,90€",
  },
  {
    image: NoPicture,
    dishname: ["3. LAMB BIRYANI (L, G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
    id:"3",
    reference:"SecondResturant.Alacarte.Briyani",
      price: "17,90€",
  },

  {
    dishname:[<h3 style={{
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: '12px',
      borderBottom: '1px solid',
  }}>MOMO</h3>],
    id:"",
    reference:""
  },
  {
    image: NoPicture,
    dishname: ["1. VEG MOMO (L) (Vegan) 10pcs*"],
    price: "13,00€",
    reference:"SecondResturant.Alacarte.MoMo",
    id:"1"
  },
  {
    image: NoPicture,
    dishname: ["2. Chicken MOMO 10pcs (L)"],
    price: "14,00€",
    reference:"SecondResturant.Alacarte.MoMo",
    id:"2"
  },
  {
    image: NoPicture,
    dishname: ["3. LAMB MOMO 10pcs (L)"],
    price: "15,00€",
    reference:"SecondResturant.Alacarte.MoMo",
    id:"3",
    dummy:["Mild / Mild", <img src={Chilly1} alt="" height="30" width="33"></img>,   " Medium / medium spicy",<img src={Chilly2} alt="" height="30" width="33"></img>,  " Real Strong / very spicy",<img src={Chilly3} alt="" height="30" width="33"></img>],
  },
];

export const ChildrenMenu
 = [
  {
    image:NoPicture,
    dishname: "1. CHICKEN KORMA (G)",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"1",
      price: "9,00€",
  },
  {
    image:NoPicture,
    dishname: "2. CHICKEN TIKKA (G)",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"2",
    price: "9,00€",
  },
  {
    image: SahiPaneer,
    dishname: "3. SHAHI PANEER (G)",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"3",
      price: "9,00€",
  },
  {
    image: SagPaneer,
    dishname: "4. SAG PANEER (G)  ",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"4",
      price: "9,00€",
  },
  {
    image: ButterChicken,
    dishname: "5. BUTTER CHICKEN (G) ",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"5",
      price: "9,00€",
  },
  {
    image:NoPicture,
    dishname: " 6. SHAHI PRAWN (G) ",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"6",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "7. LAMB CURRY (G, L) ",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"7",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "8. CHICKEN FINGERS AND FRENCH FRIES (G, L)",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"8",
      price: "10,00€",
  },
  {
    image:NoPicture,
    dishname: "9. CHICKEN FINGERS (L) ",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"9",
      price: "6,00€",
  },
  {
    image:NoPicture,
    dishname: " 10. FRENCH FRIES",
    
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"10",
    price: "5,00€",
  },
  {
    image:NoPicture,
    dishname: " 11. FRIED RICE (G, L)",
    reference:"SecondResturant.Alacarte.ChildrenMenu",
    id:"11",
      price: "9,00€",
      dummy:[
        "Served only to under 12 years old.",<p></p>,
        "The children’s menu includes rice or French fries.",<br></br>,
        "Childrens menu includes Basmati rice or french fries.",<p></p>,
      ],
  },
];

export const ThaliDishes 
= [
  
    {
      image: NoPicture,
      dishname:[<h3 style={{
        fontSize: '22px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    }}>1. Kasvis-Thali</h3>],
    price:"18,50€",
      id:"",
      reference:""
    },
    {

      dishname: ["Dal Fry (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
      reference:"SecondResturant.Alacarte.Thali.KasvisThali",
      id:"1",
        price: "",
    },
    {
  
      dishname: ["Sag Aalu (G, L)"],
      reference:"SecondResturant.Alacarte.Thali.KasvisThali",
      id:"2",
        price: "",
    },
    {

    dishname: ["Shahi Paneer (G)"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"3",
      price: "",
  },
  {

    dishname: ["Raita"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"4",
  },
  {

    dishname: ["RIISI JA NAAN LEIPA"],
    reference:"SecondResturant.Alacarte.Thali.KasvisThali",
    id:"5",
      price: "",
  },

  {
    dishname:[<h3 style={{
      fontSize: '22px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
  }}>2. Liha-Thali</h3>],
  price:"20,50€",
    id:"",
    reference:""
  },
  {
    dishname: ["BHENDAKO CHILLY (G, L) ",<img src={Chilly3} alt="medium" height="30" width="33"></img>],
    reference:"SecondResturant.Alacarte.Thali.LihaThali",
    id:"1",
  },
  {

    dishname: ["Butter Chicken (G) "],
    reference:"SecondResturant.Alacarte.Thali.LihaThali",
    id:"2",
      price: "",
  },
  {
  dishname: ["DAL (G)",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"3",
    price: "",
},
{
  dishname: ["Raita"],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"4",
},
{
  dishname: ["RIISI JA NAAN LEIPA"],
  reference:"SecondResturant.Alacarte.Thali.LihaThali",
  id:"5",
    price: "",
},

{
  dishname:[<h3 style={{
    fontSize: '22px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
}}>3. MIX Thali</h3>],
price:"21,90€",
  id:"",
  reference:""
},
{

  dishname: ["Dal Fry (G) ",<img src={Chilly1} alt="medium" height="30" width="33"></img>],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"1",
},
{

  dishname: ["KUKHURA TIKKA (G) "],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"2",
},
{
dishname: ["khasiko masu (G, L) ",<img src={Chilly1} alt="mild" height="30" width="33"></img>],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"3",
  price: "",
},
{
dishname: ["Sahi Paneer (G) "],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"4",
},
{
  dishname: ["Raita "],
  reference:"SecondResturant.Alacarte.Thali.MixThali",
  id:"5",
  },
{
dishname: ["RIISI JA NAAN LEIPA"],
reference:"SecondResturant.Alacarte.Thali.MixThali",
id:"6",
  price: "",
},
];


export const Desserts
 = [
  {
    image: MountKeraDesert,
    dishname: "MOUNT KERA",
    reference:"AlacarteDesserts",
    id:"1",
      price: "5,90€",
  },
  {
    image: NoPicture,
    dishname: "ICE CREAMS",
    reference:"AlacarteDesserts",
    id:"2",
      price: "5,90€",
  },
  {
    image: NoPicture,
    dishname: "MIXED ICE CREAM",
    reference:"AlacarteDesserts",
    id:"3",
      price: "6,00€",
  },
  {
    image: MountKeraDesert2,
    dishname: " MITHO KERA (G)",
    reference:"AlacarteDesserts",
    id:"4",
      price: "5,50€",
  },
  {
    image: NoPicture,
    dishname: "KULFI ",
    reference:"AlacarteDesserts",
    id:"5",    
    price: "5,50€",
  },
  {
    image: NoPicture,
    dishname: "GULAB JAMUN (G) (3 balls) ",
    reference:"AlacarteDesserts",
    id:"6",    
    price: "4,90€",
  },
  {
    image: NoPicture,
    dishname: "GAJAR KA HALWA (G) 	 ",
    dishitems:      "Carrot pudding and vanilla ice cream. Contains whole milk.",
    reference:"AlacarteDesserts",
    id:"7",    
    price: "6,00€",
  },
];