import NoPicture from "../../../assets/FoodImg/Picture1.png";
import ShahiPaneer from "../../../assets/FoodImg/SahiPaneer.png";
import ChickenTikkaMasala from "../../../assets/FoodImg/ChickenTikkaMasala.png";
import Chilli1 from "../../../assets/FoodImg/C1.png"
import Chilli2 from "../../../assets/FoodImg/C2.png"
import Chilli3 from "../../../assets/FoodImg/C3.png"
const WedFood = [
  {
    image: NoPicture,
    dishname:[ "1. Bhindi Masala (G,L) (VEGAN)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Fried okra, potatoes, mushrooms and peppers in a medium-flavored tomato-onion-garlic-ginger sauce.",

    id:"1",
    reference:"LunchWedFood",
      price: "9,00€",
  },
  {
    image: ShahiPaneer,
    dishname: "2. Shahi Paneer (G)",
    dishitems:
      "Cream cheese in tomato-butter-cream sauce Cottage cheese cooked in tomato-butter-cream sauce.",
    id:"2",
    reference:"LunchWedFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "3. Chicken Sag (G, L) ",
    dishitems:
      "Tandoori grilled chicken breast fillet, spinach-herb-ginger-garlic-onion-tomato-cream sauce.",
    id:"3",
    reference:"LunchWedFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname: "4. Lamb Korma (G, L)",
    dishitems:
      "Tandoori grilled chicken breast fillet, spinach-herb-ginger-garlic-onion-tomato-cream sauce.",
    id:"4",
    reference:"LunchWedFood",
      price: "10,00€",
  },
  {
    image: NoPicture,
    dishname:[ "5. Prawn Chilli (G, L) ", <img src={Chilli2} height="30" width="33"></img>],
    dishitems:
      "Tandoori grilled chicken breast fillet, spinach-herb-ginger-garlic-onion-tomato-cream sauce.",
    id:"5",
    reference:"LunchWedFood",
      price: "10,00€",
  },
  {
    image: ChickenTikkaMasala,
    dishname: ["6. Today's Special KUKHURA TIKKA (G)",<img src={Chilli1} height="30" width="33"></img>],
    dishitems:
      "Herb-yoghurt marinated and grilled chicken in a tandoor oven.",
    id:"6",
    reference:"LunchWedFood",
      price: "12,50€",
      dummyheader: ["Lunch Mix(Mix Dish) 11,50€ - You can select two lunch dishes(no. 1 - 5) to make combined portion.",<br/>,
      "Kids Portion - 7,50€"],
      dummy: ["Each dish includes Basmati rice, Naan Bread, mint chutney, Raita Buffet-Salad & Coffee or Tea.", <p></p>,
        "G = Gluten free L = Lactose free", <p></p>,
        "Medium spicy ", <img src={Chilli1} alt = "mild" height="30" width="33"></img>,   " More spicy", <img src={Chilli2} alt = "mild" height="30" width="33"></img>
      ],
  },
];
export default WedFood;
